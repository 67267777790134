import { BackendLoading } from 'redux/redux.shared'
import { ProjectFlowsExecDetails } from 'models/flow'
import { Creator, FrontendFile } from 'models/files'

export const loadingTypes = ['fetchProjectExecutableFlows', 'execFlow', 'getReviewers'] as const
export type LoadingTypes = typeof loadingTypes[number]

export interface FlowsState {
  loading: Record<LoadingTypes, BackendLoading>
  projectFlows: ProjectFlowsExecDetails[]
  modalData: FlowModalData
  reviewers: Creator[]
  selectedReviewer?: number
}

export interface FlowModalData {
  isVisible: boolean
  flow?: ProjectFlowsExecDetails
  frontendFile?: FrontendFile
}
