import { findFieldValueByName } from 'helpers/Collection.helpers'
import { SuitabilityCode } from 'models/administration'
import { CdeStatuses } from 'models/files'
import { ConsequencesType, FlowConsequencesConfig, FlowSettingsFields } from 'models/flow'
import { BackendLoading } from 'redux/redux.shared'
import { RootState } from 'redux/store'
import {
  selectFlowConditionsInitialCdeStatuses,
  selectGettingFlowConditionsLoading,
} from '../conditions/FlowConditionsConfig.selectors'
import { selectGettingFlowDecisionsLoading } from '../decisions/FlowDecisionsConfig.selectors'
import { FlowConsequencesConfigState } from './FlowConsequencesConfig.types'

const selectFlowConsequencesConfigState = (state: RootState): FlowConsequencesConfigState =>
  state.flowConfig.consequences

export const selectConsequencesFlowConfig = (state: RootState): FlowConsequencesConfig =>
  selectFlowConsequencesConfigState(state).flowConsequencesConfig

export const selectConsequencesListFlowConfig = (state: RootState): FlowSettingsFields[] =>
  selectFlowConsequencesConfigState(state).flowConsequencesConfig.onFinished

export const selectConsequenceFilteredByType: (
  type: ConsequencesType
) => (state: RootState) => FlowSettingsFields | undefined = (type) => (state) => {
  return selectFlowConsequencesConfigState(state).flowConsequencesConfig.onFinished.find(
    (c) => findFieldValueByName(c, 'consequenceType') === type
  )
}

export const selectSuitabilityCodesConfig = (state: RootState): SuitabilityCode[] =>
  selectFlowConsequencesConfigState(state).codes

export const selectFlowConsequencesConfigValidity = (state: RootState): boolean => {
  const consequences = selectConsequencesListFlowConfig(state)
  const initialCdeStatuses = selectFlowConditionsInitialCdeStatuses(state)
  return consequences.reduce((prviousValues: boolean, consequence) => {
    const cdeStatus = findFieldValueByName(consequence, 'followingCdeStatus')
    if (
      initialCdeStatuses !== undefined &&
      initialCdeStatuses.length !== 1 &&
      cdeStatus !== -1 &&
      cdeStatus !== CdeStatuses.ARCHIVED
    ) {
      return false
    }
    return prviousValues
  }, true)
}

export const selectUpdatingFlowConsequencesLoading = (state: RootState): BackendLoading =>
  selectFlowConsequencesConfigState(state).loading.updateFlowConsequences

export const selectGettingFlowConsequencesLoading = (state: RootState): BackendLoading =>
  selectFlowConsequencesConfigState(state).loading.getFlowConsequences

export const selectGettingSuitabilityCodesLoading = (state: RootState): BackendLoading =>
  selectFlowConsequencesConfigState(state).loading.fetchSuitabilityOverview

export const selectIsConsequencesConfigContentLoading = (state: RootState): boolean =>
  selectUpdatingFlowConsequencesLoading(state).isLoading ||
  selectGettingFlowConsequencesLoading(state).isLoading ||
  selectGettingSuitabilityCodesLoading(state).isLoading ||
  selectGettingFlowDecisionsLoading(state).isLoading ||
  selectGettingFlowConditionsLoading(state).isLoading
