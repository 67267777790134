import { FrontendFile } from 'models/files'
import backendAxios from 'axios/axios'
import { VersionInfoDto } from 'redux/files/Files.dto'
import { refreshEntries } from 'redux/files/files-documentation'
import { AppDispatch } from 'redux/store'
import { successText } from 'data/messages/controls'
import { defineMessage, t } from '@lingui/macro'
import { openDocumentationErrorNotification, openDocumentationNotification } from './Notifications.helpers'
import trans from './i18n.helpers'

const ACCEPTANCE_ERROR_CODES = {
  'acceptance_request.already_exists': defineMessage({
    id: 'project.files.flow.errors.acceptance_request_exists',
    message: 'Acceptance request already exists',
  }),
  'auditor.already_in_review': defineMessage({
    id: 'project.files.flow.errors.entry_in_review',
    message: 'File is already in review',
  }),
  'acceptance_request.cannot_accept_old_file': defineMessage({
    id: 'project.files.flow.errors.acceptance_request_for_old_revision',
    message: 'Only current revision may be accepted',
  }),
  'acceptance.wrong_status': defineMessage({
    id: 'project.files.flow.errors.wrong_status',
    message: 'Wrong directory',
  }),
  'entry.acceptance_request_does_not_exist': defineMessage({
    id: 'project.files.flow.errors.entry_acceptance_request_exists',
    message: 'Acceptance request already exists',
  }),
  'file.deleted': defineMessage({
    id: 'project.files.flow.errors.file_deleted',
    message: 'This file is already deleted',
  }),
  'file.not_newest': defineMessage({
    id: 'project.files.flow.errors.delete_old_revision',
    message: 'Only newest revision may be deleted',
  }),
  'file.in_review': defineMessage({
    id: 'project.files.flow.errors.file_in_review',
    message: 'File is already in review',
  }),
  'file.not_in_progress': defineMessage({
    id: 'project.files.flow.errors.file_not_in_progress',
    message: 'Files outside WIP directory cannot be deleted',
  }),
  'entry.deleted': defineMessage({
    id: 'project.files.flow.errors.entry_deleted',
    message: 'File is already deleted',
  }),
  'entry.not_in_progress': defineMessage({
    id: 'project.files.flow.errors.entry_not_in_progress',
    message: 'This file is not in WIP directory',
  }),
}

export const requestAcceptance = async (file: FrontendFile, dispatch: AppDispatch): Promise<void> => {
  const url = file.type === 'DIR' ? `entries/${file.entryId}/acceptance/` : `files/${file.fileId}/acceptance/`
  try {
    await backendAxios.post(url)
    dispatch(refreshEntries({}))
    openDocumentationNotification('success', {
      message: trans(successText),
      description: t({
        id: 'project.files.flow.acceptance_request_created',
        message: 'Acceptance request has been created',
      }),
    })
  } catch (err) {
    openDocumentationErrorNotification(ACCEPTANCE_ERROR_CODES, err)
  }
}

export const copyFileToInProgress = async (frontendFile: FrontendFile, dispatch: AppDispatch): Promise<void> => {
  try {
    const response = await backendAxios.post(`files/${frontendFile.fileId}/reedit/`)
    const { id } = response.data as VersionInfoDto
    dispatch(refreshEntries({ addedFileId: id }))
    openDocumentationNotification('success', {
      message: t({
        id: 'project.files.flow.file_copied_to_wip',
        message: 'An editable copy of file has been created',
      }),
      description: frontendFile.filename,
    })
  } catch (err) {
    openDocumentationErrorNotification(ACCEPTANCE_ERROR_CODES, err)
  }
}

export const moveToNextStatus = async (file: FrontendFile, dispatch: AppDispatch): Promise<void> => {
  const url =
    file.type === 'DIR' ? `entries/${file.entryId}/acceptance/accept/` : `files/${file.fileId}/acceptance/accept/`
  try {
    const response = await backendAxios.post(url, { status: file.cdeStatus })
    const { id } = response.data as VersionInfoDto
    dispatch(refreshEntries({ addedFileId: id }))
    openDocumentationNotification('success', {
      message: trans(successText),
      description: t({
        id: 'project.files.flow.file_moved_to_next_directory',
        message: 'File has been moved to the next directory',
      }),
    })
  } catch (err) {
    dispatch(refreshEntries({}))
    openDocumentationErrorNotification(ACCEPTANCE_ERROR_CODES, err)
  }
}

export const rejectFile = async (file: FrontendFile, dispatch: AppDispatch): Promise<void> => {
  const url =
    file.type === 'DIR' ? `entries/${file.entryId}/acceptance/reject/` : `files/${file.fileId}/acceptance/reject/`
  try {
    await backendAxios.post(url)
    dispatch(refreshEntries({}))
    openDocumentationNotification('success', {
      message: trans(successText),
      description: t({
        id: 'project.files.flow.file_rejected',
        message: 'File has been rejected',
      }),
    })
  } catch (err) {
    openDocumentationErrorNotification(ACCEPTANCE_ERROR_CODES, err)
  }
}

export const deleteFile = async (file: FrontendFile, dispatch: AppDispatch): Promise<void> => {
  const url = `files/${file.fileId}/`
  try {
    await backendAxios.delete(url)
    dispatch(refreshEntries({}))
    openDocumentationNotification('success', {
      message: trans(successText),
      description: t({
        id: 'project.files.flow.revision_deleted',
        message: 'Revision has been deleted',
      }),
    })
  } catch (err) {
    openDocumentationErrorNotification(ACCEPTANCE_ERROR_CODES, err)
  }
}

export const deleteEntry = async (file: FrontendFile, dispatch: AppDispatch): Promise<void> => {
  const url = `entries/${file.entryId}/`
  try {
    await backendAxios.delete(url)
    dispatch(refreshEntries({}))
    openDocumentationNotification('success', {
      message: trans(successText),
      description: t({
        id: 'project.files.flow.file_deleted',
        message: 'File has been deleted',
      }),
    })
  } catch (err) {
    openDocumentationErrorNotification(ACCEPTANCE_ERROR_CODES, err)
  }
}
