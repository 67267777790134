import { MessageDescriptor } from '@lingui/core'
import { defineMessage, t } from '@lingui/macro'
import ErrorAlert from 'components/atoms/error-alert'
import { handleApiErrors } from 'helpers/HttpError.helpers'
import trans from 'helpers/i18n.helpers'
import { useSelector } from 'react-redux'
import {
  selectGettingFlowConsequencesLoading,
  selectUpdatingFlowConsequencesLoading,
} from 'redux/flow-conifg/consequences/FlowConsequencesConfig.selectors'
import {
  selectGettingFlowDecisionsLoading,
  selectGettingSuitabilityCodesLoading,
} from 'redux/flow-conifg/decisions/FlowDecisionsConfig.selectors'

const flowErrorDict: Record<string, MessageDescriptor | string> = {
  'flow.consequence_wrong_cde_status_selection': defineMessage({
    id: 'flow_config.alert.consequence_wrong_cde_status_selection',
    message:
      'The cde directory cannot be other than ARCHIVED or No Changes if no initial directories or more than one directory was selected.',
  }),
}

const fetchingErrorMessage = defineMessage({
  id: 'flow_config.consequences.alert.fetching_flows_failed',
  message: 'Could not retrieve flows.',
})

const FlowConsequencesConfigContentAlerts: React.FC = () => {
  const { error: fetchingDecisionError } = useSelector(selectGettingFlowDecisionsLoading)
  const { error: updatingConsequencesError } = useSelector(selectUpdatingFlowConsequencesLoading)
  const { error: getSuitabilityCodesError } = useSelector(selectGettingSuitabilityCodesLoading)
  const { error: fetchingConsequencesError } = useSelector(selectGettingFlowConsequencesLoading)

  const alertMessageDict = [
    {
      error: fetchingDecisionError,
      message: trans(fetchingErrorMessage),
    },
    {
      error: fetchingConsequencesError,
      message: trans(fetchingErrorMessage),
    },
    {
      error: getSuitabilityCodesError,
      message: t({
        id: 'flow_config.consequences.alert.fetching_suitability_codes_failed',
        message: 'Could not retrieve suitability codes.',
      }),
    },
    {
      error: updatingConsequencesError,
      message: handleApiErrors(
        flowErrorDict,
        updatingConsequencesError?.errors,
        t({
          id: 'flow_config.alert.updating_flow_failed',
          message: 'Could not update flow.',
        })
      ),
    },
  ]
  return <ErrorAlert alertMessageDict={alertMessageDict} />
}

export default FlowConsequencesConfigContentAlerts
