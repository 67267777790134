import { CloseOutlined } from '@ant-design/icons'
import Icon from 'components/atoms/icon'
import styled from 'styled-components'

interface FullScreenModalHeaderProps {
  handleClose?: () => void
  text?: React.ReactNode
}

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`

const FullScreenModalHeader: React.FC<FullScreenModalHeaderProps> = ({ handleClose, text }) => {
  return (
    <StyledDiv>
      {text}
      <Icon style={{ marginLeft: 'auto' }} onClick={handleClose}>
        <CloseOutlined />
      </Icon>
    </StyledDiv>
  )
}

export default FullScreenModalHeader
