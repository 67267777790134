import { Form } from 'antd'
import styled from 'styled-components'
import SpaceElement from 'components/atoms/space'
import CodeItem from './CodeItem'
import ValueItem from './ValueItem'
import DeleteValueButton from './DeleteValueButton'
import AddValueButton from './AddValueButton'

interface SetProps {
  formListName: string
  isDisabled: boolean
  valueFieldName: string
  deletable?: boolean[]
  buttonText?: string
  isAddingDisabled?: boolean
  valueCodeLength?: number
  nameLabel?: string
  allowValueEdit?: boolean
  onCodeChange?: () => void
}

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  overflow-y: auto;
  max-height: 380px;
  > :not(:nth-child(3n + 3)) {
    margin-right: ${({ theme }) => theme.sizes.xs};
    border-right: 1px solid ${(props) => props.theme.colors.grayG03};
  }
  .ant-form-item-control-input-content {
    display: flex;
    flex-direction: row;
  }
`

const StyledDiv = styled.div`
  margin-bottom: 0;
  padding-bottom: ${(props) => props.theme.sizes.xxxs};
`

const ManageValueAndNameFields: React.FC<SetProps> = ({
  formListName,
  isDisabled,
  valueFieldName,
  deletable,
  buttonText,
  isAddingDisabled,
  valueCodeLength,
  nameLabel,
  onCodeChange,
  allowValueEdit = true,
}) => {
  const areButtonsVisible = !isDisabled && allowValueEdit
  return (
    <Form.List name={formListName}>
      {(fields, { add, remove }) => (
        <SpaceElement size="s">
          <Grid>
            {fields.map((field, index) => {
              const commonProps = {
                isFirstInColumn: index < 3,
                field,
                showButtons: areButtonsVisible,
              }
              return (
                <StyledDiv key={field.fieldKey}>
                  <CodeItem
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...commonProps}
                    disabled={isDisabled}
                    valueCodeLength={valueCodeLength}
                    formListName={formListName}
                    onCodeChange={onCodeChange}
                  />
                  <ValueItem
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...commonProps}
                    nameLabel={nameLabel}
                    fieldName={valueFieldName}
                    disabled={isDisabled || !allowValueEdit}
                  />
                  <DeleteValueButton
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...commonProps}
                    isDeletable={!!(deletable && (field.key >= deletable.length || deletable[field.key]))}
                    removeValue={remove}
                  />
                </StyledDiv>
              )
            })}
          </Grid>
          <AddValueButton
            showButtons={areButtonsVisible}
            disabled={!!isAddingDisabled}
            text={buttonText}
            addValue={add}
          />
        </SpaceElement>
      )}
    </Form.List>
  )
}

export default ManageValueAndNameFields
