import { t, Trans } from '@lingui/macro'
import { ColumnsType } from 'antd/lib/table'
import Checkbox from 'components/molecules/checkbox'
import TooltipElement from 'components/molecules/tooltip-element'
import SchemeFieldValuesDisplay from 'components/organisms/administration/administration-content/naming-scheme/modals/form/details/columns/SchemeFieldValuesDisplay'
import isFieldInShortName from 'helpers/NamingScheme.helpers'
import { SchemeElementData } from 'redux/administration/administration-naming-scheme/AdministrationNamingScheme.types'

const namingSchemeColumns: ColumnsType<SchemeElementData> = [
  {
    title: <Trans id="project_config.naming_scheme.table.column_fields_label">Field</Trans>,
    dataIndex: 'field',
    key: 'field',
    width: 250,
    render: (value, { name }) => name,
  },
  {
    title: <Trans id="project_config.naming_scheme.table.column_code_label">Code</Trans>,
    dataIndex: 'code',
    key: 'code',
    width: 100,
    render: (value, { code }) => code,
  },
  {
    title: <Trans id="project_config.naming_scheme.table.column_values_label">Values</Trans>,
    dataIndex: 'values',
    key: 'values',
    width: 250,
    render: (value, { values }) => <SchemeFieldValuesDisplay values={values} />,
  },
  {
    title: (
      <Trans id="project_config.naming_scheme.table.column_field_in_short_name_label">Appears in short name</Trans>
    ),
    dataIndex: 'inShortName',
    key: 'inShortName',
    width: 100,
    render: (value, { args }) => <Checkbox checked={isFieldInShortName(args)} disabled />,
  },
  {
    title: <Trans id="project_config.naming_scheme.table.column_code_length_label">Field length</Trans>,
    dataIndex: 'length',
    key: 'length',
    width: 125,
    render: (value, { length, type }) => (
      <TooltipElement
        placement="bottom"
        disabled={type === 'revision' || type === 'date'}
        title={t({
          id: 'project_config.naming_scheme.table.length_change',
          message: 'System administrator can edit field length in attributes settings',
        })}
      >
        {length}
      </TooltipElement>
    ),
  },
]

export default namingSchemeColumns
