import { generateStyledTableWithType } from 'helpers/Styles.helpers'
import { useSelector } from 'react-redux'
import { Discipline } from 'models/administration'
import { selectAvailableDisciplines } from 'redux/user-profile/permissions/UserPermissions.selectors'
import { css } from 'styled-components'
import userPermissionsColumns, { DisciplinePermissionRowProps } from './userPermissionsColumns'

const tableMixin = css`
  width: 1100px;
  .ant-table-body {
    overflow-y: auto !important;
  }
  .ant-table-thead > tr > th {
    text-align: center;
    font-size: ${(props) => props.theme.sizes.s};
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
    border: 1px solid ${(props) => props.theme.colors.table};
  }
  td {
    border: 1px solid ${(props) => props.theme.colors.table};
  }
`

const Table = generateStyledTableWithType<DisciplinePermissionRowProps>(tableMixin)

const getTableData = (disciplines: Discipline[]) =>
  disciplines.map(({ id, name }) => ({ disciplineId: id, disciplineName: name, key: id }))

const UserPermissionsTable: React.FC = () => {
  const disciplines = useSelector(selectAvailableDisciplines)

  return (
    <Table
      columns={userPermissionsColumns}
      dataSource={getTableData(disciplines)}
      pagination={false}
      scroll={{ y: 'calc(100vh - 310px)' }}
    />
  )
}

export default UserPermissionsTable
