import { t, Trans } from '@lingui/macro'
import { ColumnsType } from 'antd/lib/table'
import useTableColumnFiltering, { onFilterStringColumn } from 'components/molecules/table/useTableColumnFiltering'
import { actionsTableColumnText } from 'data/messages/controls'
import trans from 'helpers/i18n.helpers'
import { Attribute } from 'models/administration'
import AttributeActions from './AttributeActions'
import AttributeNameDisplay from './AttributeNameDisplay'
import SelectAttributeValue from './AttributeValuesDisplay'

const useAdministrationAttributesColumns = (): ColumnsType<Attribute> => {
  return [
    {
      title: <Trans id="administration.attributes.table.column_name_label">Name</Trans>,
      dataIndex: 'attribute',
      key: 'attribute',
      width: 400,
      render: (value, { name, projectValuesAllowed }) => (
        <AttributeNameDisplay name={name} projectValuesAllowed={projectValuesAllowed} />
      ),
      ...useTableColumnFiltering(
        t({ id: 'administration.attributes.table.search_name_placeholder', message: 'Search for name' })
      ),
      onFilter: (value: string | number | boolean, record: Attribute) => onFilterStringColumn(value, record.name),
    },
    {
      title: <Trans id="administration.attributes.table.column_values_label">Values</Trans>,
      dataIndex: 'values',
      key: 'values',
      width: 400,
      render: (value, { values, id, valueCodeLength }) => (
        <SelectAttributeValue id={id} values={values} valueCodeLength={valueCodeLength} />
      ),
    },
    {
      title: <Trans id="administration.attributes.table.column_value_code_length_label">Code length</Trans>,
      dataIndex: 'valueCodeLength',
      key: 'valueCodeLength',
      width: 150,
      render: (value, { valueCodeLength }) => valueCodeLength,
      ...useTableColumnFiltering(
        t({ id: 'administration.attributes.table.search_code_length_placeholder', message: 'Search for code length' })
      ),
      onFilter: (value: string | number | boolean, record: Attribute) =>
        onFilterStringColumn(value, record.valueCodeLength.toString()),
    },
    {
      title: trans(actionsTableColumnText),
      dataIndex: 'actions',
      key: 'actions',
      render: (value, { id, isUsed, name }) => <AttributeActions id={id} name={name} canDelete={!isUsed} />,
    },
  ]
}

export default useAdministrationAttributesColumns
