import SpaceElement from 'components/atoms/space'
import ButtonElement from 'components/molecules/button-element'
import CheckboxElement from 'components/molecules/checkbox'
import { addText, cancelText } from 'data/messages/controls'
import trans from 'helpers/i18n.helpers'
import styled from 'styled-components'
import { Trans } from '@lingui/macro'
import { useEffect, useState } from 'react'
import { NamingSchemeElement } from 'models/administration'
import { SchemeElementWithName } from 'redux/administration/administration-naming-scheme/AdministrationNamingScheme.helpers'
import { Checkbox } from 'antd'
import Text from 'components/atoms/text'

interface NamingSchemeFieldsMenuProps {
  value?: NamingSchemeElement[]
  onChange?: (value: NamingSchemeElement[]) => void
  onClose: () => void
  availableElements: SchemeElementWithName[]
}

const StyledDiv = styled(SpaceElement)`
  width: 800px;
  padding: ${(props) => props.theme.sizes.l};
  background-color: ${(props) => props.theme.colors.baseWhiteish};
  .ant-checkbox-wrapper span {
    vertical-align: middle;
  }
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 33%);
  max-height: 350px;
  max-width: 100%;
  overflow-y: auto;
  > :not(:nth-child(3n + 3)) {
    margin-right: ${({ theme }) => theme.sizes.xs};
    border-right: 1px solid ${(props) => props.theme.colors.grayG03};
  }
  .ant-checkbox-wrapper {
    margin-left: ${({ theme }) => theme.sizes.xs};
  }
  .ant-typography {
    max-width: calc(100% - 40px);
  }
`

const NamingSchemeFieldsMenu: React.FC<NamingSchemeFieldsMenuProps> = ({
  value,
  availableElements,
  onClose,
  onChange,
}) => {
  const [selectedFields, setSelectedFields] = useState<NamingSchemeElement[]>([])
  useEffect(() => {
    setSelectedFields([])
  }, [availableElements])
  const toggleFieldSelected = (elem: NamingSchemeElement) => {
    if (selectedFields.find((f) => f.key === elem.key && f.args?.id === elem.args?.id)) {
      setSelectedFields(selectedFields.filter((f) => f.key !== elem.key || f.args?.id !== elem.args?.id))
    } else {
      setSelectedFields([...selectedFields, elem])
    }
  }
  const handleSave = () => {
    onClose()
    if (onChange && value !== undefined) {
      setTimeout(() => {
        const valueWithoutRevision = value.filter(({ key }) => key !== 'revision')
        const revisionPart = value.filter(({ key }) => key === 'revision')
        onChange([...valueWithoutRevision, ...selectedFields, ...revisionPart])
      })
    }
  }
  const isSelectAllCheckboxChecked = selectedFields.length === availableElements.length
  const isSelectAllCheckboxIndeterminate = !isSelectAllCheckboxChecked && selectedFields.length > 0

  const handleSelectAll = () => {
    if (isSelectAllCheckboxChecked) setSelectedFields([])
    else setSelectedFields(availableElements)
  }

  return (
    <StyledDiv>
      <div>
        <CheckboxElement
          checked={isSelectAllCheckboxChecked}
          indeterminate={isSelectAllCheckboxIndeterminate}
          onChange={handleSelectAll}
          textStyle="regularText-smallSemiBold"
          color="grayG08"
        >
          <Trans id="administration.naming_schemes.modal.field_add.select_all">Select all</Trans>
        </CheckboxElement>
      </div>
      <Grid>
        {availableElements.map(({ name, key, args }) => {
          const id = `${key}${args?.id || 0}`
          return (
            <Checkbox
              id={id}
              key={id}
              checked={
                !!selectedFields.find(
                  ({ key: elemKey, args: elemArgs }) => key === elemKey && args?.id === elemArgs?.id
                )
              }
              onChange={() => toggleFieldSelected({ key, args })}
            >
              <Text textStyle="regularText-small" color="grayG07" ellipsis>
                {name}
              </Text>
            </Checkbox>
          )
        })}
      </Grid>
      <SpaceElement direction="horizontal" size="s">
        <ButtonElement onClick={onClose} type="tertiary">
          {trans(cancelText)}
        </ButtonElement>
        <ButtonElement loading={false} onClick={handleSave}>
          {trans(addText)}
        </ButtonElement>
      </SpaceElement>
    </StyledDiv>
  )
}

export default NamingSchemeFieldsMenu
