import { t } from '@lingui/macro'
import { Rule } from 'antd/lib/form'
import { emptyFieldError, invalidCodeError, maxStringLengthError } from 'data/messages/controls'
import { itemArr } from './Collection.helpers'
import trans from './i18n.helpers'

interface RuleInterface {
  required?: boolean
  len?: number
  min?: number
  message: string
  whitespace?: boolean
  type?: 'string' | 'number' | 'boolean' | 'url' | 'email'
}

export const required = (message?: string): RuleInterface => {
  return {
    required: true,
    message: message || trans(emptyFieldError),
  }
}

export const requiredLength = (length: number, message?: string): RuleInterface => {
  return {
    len: length,
    message: message || t({ id: 'common.controls.invalid_length_error', message: `Required length: ${length}` }),
  }
}

export const requireMinLength = (length: number, message?: string): RuleInterface => {
  return {
    min: length,
    message: message || t({ id: 'common.controls.minimal_length_error', message: `Minimal length: ${length}` }),
  }
}

export const notWhitespace = (message?: string): RuleInterface => {
  return {
    whitespace: true,
    message:
      message ||
      t({
        id: 'common.controls.only_whitespace_error',
        message: `Field must contain at least 1 non-whitespace character`,
      }),
  }
}

export const requiredAndNotBlank = (emptyMessage?: string, blankMessage?: string): RuleInterface[] => {
  return [required(emptyMessage), notWhitespace(blankMessage)]
}

export const codeFormat = (message?: string): { validator: (rule: Rule, value: string) => Promise<void> } => {
  const regex = new RegExp('^[A-ZÀ-ž0-9_]+$')
  return {
    validator: (rule, value) => {
      if (!regex.test(value) || value.toUpperCase() !== value) {
        return Promise.reject(new Error(message || trans(invalidCodeError)))
      }
      return Promise.resolve()
    },
  }
}

export const maxStringLength = (max: number, message?: string): Rule => {
  return {
    max,
    message: message || trans(maxStringLengthError(max)),
    type: 'string',
  }
}

export const valueNotIn: (
  values: string[],
  message: string
) => { validator: (rule: Rule, value: string) => Promise<void> } = (values, message) => {
  return {
    validator: (rule, value) => {
      if (values.includes(value)) {
        return Promise.reject(new Error(message))
      }
      return Promise.resolve()
    },
  }
}

export const requiredTyped = (
  type: 'string' | 'number' | 'boolean' | 'url' | 'email',
  message: string
): RuleInterface => {
  return {
    type,
    message,
  }
}

export function codeInputRules(length?: number): Rule[] {
  return [required(), codeFormat(), ...itemArr(requiredLength(length || 0), !!length)]
}

export function notEmpty<T>(message: string): { validator: (rule: Rule, value: T[]) => Promise<void> } {
  return {
    validator: (rule, value) => {
      if (!value || value.length === 0) {
        return Promise.reject(new Error(message))
      }
      return Promise.resolve()
    },
  }
}
