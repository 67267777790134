import { Form, Input } from 'antd'
import Text from 'components/atoms/text'
import { t, Trans } from '@lingui/macro'
import { FormListFieldData } from 'antd/lib/form/FormList'
import { requiredAndNotBlank } from 'helpers/Validators.helpers'

interface CodeItemProps {
  isFirstInColumn: boolean
  field: FormListFieldData
  showButtons: boolean
  fieldName: string
  disabled: boolean
  nameLabel?: string
}

const ValueItem: React.FC<CodeItemProps> = ({
  isFirstInColumn,
  field,
  showButtons,
  fieldName,
  disabled = false,
  nameLabel,
}) => {
  return (
    <Form.Item
      name={[field.name, fieldName]}
      label={
        isFirstInColumn && (
          <Text textStyle="regularText-smallSemiBold">
            {nameLabel || <Trans id="administration.attributes.modal.value_column_label">Value</Trans>}
          </Text>
        )
      }
      colon={false}
      style={{
        display: 'inline-block',
        width: showButtons ? 'calc(58% - 8px)' : 'calc(60% - 8px)',
        margin: '0 8px',
      }}
      rules={requiredAndNotBlank()}
      fieldKey={[field.fieldKey, fieldName]}
    >
      <Input
        disabled={disabled || !fieldName}
        placeholder={t({ id: 'administration.attributes.modal.value_name_placeholder', message: 'Name' })}
      />
    </Form.Item>
  )
}

export default ValueItem
