import { InputNumber } from 'antd'
import styled from 'styled-components'
import MAX_CODE_LENGTH from 'data/codes'

interface SelectFileNumberCodeLengthProps {
  length?: number
  onChange?: (length: number) => void
}

const StyledInputNumber = styled(InputNumber)`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  &.ant-input-number-disabled {
    background-color: ${({ theme }) => theme.colors.grayG02};
  }
  input {
    text-align: center;
  }
`

const SelectFileNumberCodeLength: React.FC<SelectFileNumberCodeLengthProps> = ({ length, onChange }) => {
  const editable = !!onChange
  const handleChange = (val: string | number | undefined) => {
    if (onChange && val && val > 0 && val <= MAX_CODE_LENGTH) {
      onChange(Number(val))
    }
  }
  return <StyledInputNumber value={length} onChange={handleChange} disabled={!editable} />
}

export default SelectFileNumberCodeLength
