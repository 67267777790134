import Text from 'components/atoms/text'
import { t, Trans } from '@lingui/macro'
import { Checkbox, Form } from 'antd'
import FormInput from 'components/molecules/form-item/FormInput'
import { requiredAndNotBlank } from 'helpers/Validators.helpers'
import { FormInstance } from 'antd/lib/form'
import { Attribute } from 'models/administration'
import { useState } from 'react'
import DividerElement from 'components/atoms/divider'
import ManageValueAndNameFields from 'components/molecules/code-form-field/ManageValueAndNameFields'
import { CodeLengthEdit } from 'components/molecules/code-length'

function getFormInitialValues(editedAttribute: Attribute | undefined) {
  return {
    name: editedAttribute?.name,
    values: editedAttribute?.values,
    valueCodeLength: editedAttribute?.valueCodeLength || 1,
    projectValuesAllowed: editedAttribute?.projectValuesAllowed,
  }
}

interface AttributeModalFormProps {
  form: FormInstance
  attribute?: Attribute
}

const AttributeModalForm: React.FC<AttributeModalFormProps> = ({ form, attribute }) => {
  const [valueLength, setValueLength] = useState<number>(attribute?.valueCodeLength || 1)
  const onCodeLengthChange = (value: string | number | undefined) => {
    setValueLength(Number(value))
    form.validateFields()
  }
  const initialValues = getFormInitialValues(attribute)
  return (
    <Form form={form} initialValues={initialValues}>
      <div style={{ maxWidth: '500px' }}>
        <FormInput
          label={<Trans id="administration.attributes.modal.name_field_label">Name</Trans>}
          name="name"
          placeholder={t({ id: 'administration.attributes.modal.name_field_placeholder', message: 'Enter name' })}
          rules={requiredAndNotBlank()}
        />
      </div>
      <Form.Item name="projectValuesAllowed" valuePropName="checked">
        <Checkbox>
          <Text textStyle="regularText-small">
            <Trans id="administration.attributes.modal.project_values_allowed_checkbox_text">
              Project admins can add values available in projects
            </Trans>
          </Text>
        </Checkbox>
      </Form.Item>
      <DividerElement />
      <CodeLengthEdit
        currentLength={initialValues.valueCodeLength}
        valueName="valueCodeLength"
        bestValues={{ bestMin: 1, bestMax: 3 }}
        onChange={onCodeLengthChange}
        withWarning
      />
      <DividerElement />
      <ManageValueAndNameFields
        formListName="values"
        isDisabled={false}
        valueFieldName="value"
        deletable={attribute?.values.map((val) => !val.isUsed) || []}
        valueCodeLength={valueLength}
        onCodeChange={() => form.validateFields()}
      />
    </Form>
  )
}

export default AttributeModalForm
