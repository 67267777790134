import { t } from '@lingui/macro'
import { ColumnsType } from 'antd/lib/table'
import useTableColumnFiltering, { onFilterStringColumn } from 'components/molecules/table/useTableColumnFiltering'
import { actionsTableColumnText } from 'data/messages/controls'
import formatDate from 'helpers/Dates.helpers'
import { Backup, BackupStatuses } from 'models/administration'
import trans from 'helpers/i18n.helpers'
import backupStatusDict, { MIN_NUMBER_OF_BACKUPS } from 'data/backups'
import BackupReport from './BackupReport'
import BackupsActionsFields from './BackupsActionsFields'
import BackupsSizeDisplay from './BackupSizeDisplay'
import BackupsStatusDisplay from './BackupStatusDisplay'
import BackupsCreationDateDisplay from './BackupCreationDateDisplay'

const useAdministrationBackupsColumns = (backupsLength: number): ColumnsType<Backup> => {
  return [
    {
      title: t({ id: 'administration.backups.column_name_label', message: 'Name' }),
      dataIndex: 'name',
      key: 'id',
      width: 50,
      ...useTableColumnFiltering(
        t({ id: 'administration.backups.search_name_placeholder', message: 'Search for name' })
      ),
      onFilter: (value: string | number | boolean, record: Backup) => onFilterStringColumn(value, record.name),
      render: (value, { name }) => name,
    },
    {
      title: t({ id: 'administration.backups.column_creation_date_label', message: 'Creation date' }),
      dataIndex: 'creationDate',
      key: 'creationDate',
      width: 100,
      ...useTableColumnFiltering(
        t({ id: 'administration.backups.search_creation_date_placeholder', message: 'Search for creation date' })
      ),
      onFilter: (value: string | number | boolean, record: Backup) =>
        onFilterStringColumn(value, formatDate(record.timestamp || record.expectedStartDate || 0)),
      render: (value, { timestamp, expectedStartDate }) => (
        <BackupsCreationDateDisplay creationDate={timestamp} expectedCreationDate={expectedStartDate} />
      ),
    },
    {
      title: t({ id: 'administration.backups.column_report_label', message: 'Report' }),
      dataIndex: 'report',
      key: 'report',
      width: 100,
      render: (value, { id, report }) => <BackupReport report={report} backupId={id} />,
    },
    {
      title: t({ id: 'administration.backups.column_status_label', message: 'Status' }),
      dataIndex: 'status',
      key: 'status',
      width: 100,
      filters: [BackupStatuses.CREATED, BackupStatuses.IN_PROGRESS, BackupStatuses.FINISHED, BackupStatuses.ERROR].map(
        (status) => ({
          text: trans(backupStatusDict[status].text),
          value: status,
        })
      ),
      onFilter: (value: string | number | boolean, record: Backup) => onFilterStringColumn(value, record.status),
      render: (value, { status }) => <BackupsStatusDisplay status={status} />,
    },
    {
      title: t({ id: 'administration.backups.column_size_label', message: 'Size' }),
      dataIndex: 'size',
      key: 'size',
      width: 100,
      render: (value, { size }) => <BackupsSizeDisplay size={size} />,
    },
    {
      title: trans(actionsTableColumnText),
      dataIndex: 'actions',
      key: 'actions',
      width: 75,
      render: (value, { id, name, status }) => (
        <BackupsActionsFields
          isDeleteDisabled={backupsLength <= MIN_NUMBER_OF_BACKUPS}
          name={name}
          id={id}
          status={status}
        />
      ),
    },
  ]
}

export default useAdministrationBackupsColumns
