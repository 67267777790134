import { Theme } from 'styled-components'

const theme: Theme = {
  colors: {
    baseWhiteish: 'var(--color-base-whiteish)',
    baseWhiteishRgb: 'var(--color-base-whiteish-rgb)',
    baseBlackish: 'var(--color-base-blackish)',
    baseBackground: 'var(--color-base-background)',
    baseLight: 'var(--color-base-light)',
    primaryP01: 'var(--color-primary-P01)',
    primaryP01Rgba: 'var(--color-primary-P01-rgba)',
    primaryP02: 'var(--color-primary-P02)',
    primaryP02Rgba: 'var(--color-primary-P02-rgba)',
    primaryP03: 'var(--color-primary-P03)',
    primaryP04: 'var(--color-primary-P04)',
    primaryP05: 'var(--color-primary-P05)',
    accentA01: 'var(--color-accent-A01)',
    accentA02: 'var(--color-accent-A02)',
    accentA03: 'var(--color-accent-A03)',
    grayG01: 'var(--color-gray-G01)',
    grayG02: 'var(--color-gray-G02)',
    grayG02Rgb: 'var(--color-gray-G02-rgb)',
    grayG03: 'var(--color-gray-G03)',
    grayG04: 'var(--color-gray-G04)',
    grayG05: 'var(--color-gray-G05)',
    grayG06: 'var(--color-gray-G06)',
    grayG07: 'var(--color-gray-G07)',
    grayG08: 'var(--color-gray-G08)',
    actionHover: 'var(--color-action-hover)',
    actionSelect: 'var(--color-action-select)',
    orange6: 'var(--color-orange-6)',
    yellow6: 'var(--color-yellow-6)',
    blue6: 'var(--color-blue-6)',
    green8: 'var(--color-green-8)',
    gold6: 'var(--color-gold-6)',
    red6: 'var(--color-red-6)',
    cyan6: 'var(--color-cyan-6)',
    blue4: 'var(--color-blue-4)',
    transparent: 'transparent',
    success: 'var(--color-success)',
    successText: 'var(--color-success-text)',
    failureText: 'var(--color-failure-text)',
    disabled: 'var(--color-gray-G02)',
    volcano5: 'var(--color-volcano-5)',
    warning: 'var(--color-warning)',
    table: 'var(--color-table)',
    cdeOn: 'var(--color-cde-on)',
    cdeRequest: 'var(--color-cde-request)',
    tooltipIconGray: 'var(--color-tooltip-icon-gray)',
  },
  sizes: {
    xxxs: 'var(--size-xxxs)',
    xxs: 'var(--size-xxs)',
    xs: 'var(--size-xs)',
    s: 'var(--size-s)',
    defaultS: 'var(--size-default-s)',
    default: 'var(--size-default)',
    defaultM: 'var(--size-default-m)',
    m: 'var(--size-m)',
    l: 'var(--size-l)',
    xl: 'var(--size-xl)',
    xxl: 'var(--size-xxl)',
    mainNavbarHeight: 'var(--size-mainNavbar-height)',
    projectNamePanelHeight: 'var(--size-projectNamePanel-height)',
    projectAsideWidth: 'var(--size-projectAside-width)',
    buttonSmall: 'var(--size-button-small)',
    buttonMedium: 'var(--size-button-medium)',
    buttonLarge: 'var(--size-button-large)',
  },
  fontWeights: {
    default: 'normal',
    semiBold: '600',
  },
  effects: {
    mainShadow: 'var(--effects-main-shadow)',
    leftPanelShadow: 'var(--effects-leftPanel-shadow)',
    menuShadow: 'var(--effects-menu-shadow)',
    dropShadow: 'var(--effects-drop-shadow)',
    elevation1: 'var(--effects-elevation-1)',
  },
  mixins: {
    flexCenter: 'display: flex; justify-content: center; align-items: center;',
    absoluteOverlay: 'position: absolute; left: 0; top: 0; right:0; bottom: 0;',
  },
  transitions: {
    default: 'var(--transition-default)',
  },
}

export default theme
