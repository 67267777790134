import TooltipElement from 'components/molecules/tooltip-element'
import Icon from 'components/atoms/icon'
import styled, { Color } from 'styled-components'
import { WarningTwoTone } from '@ant-design/icons'
import theme from 'styles/Styles.theme'

interface WithValidityInfoProps {
  children?: React.ReactNode
  isValid: boolean
  invalidMessage: string
  warningColor?: Color
}

const StyledDiv = styled.div`
  display: flex;
  justify-content: center;
`

const WithValidityInfo: React.FC<WithValidityInfoProps> = ({
  children,
  isValid,
  invalidMessage,
  warningColor = 'red6',
}) => {
  return (
    <StyledDiv>
      {children}
      {!isValid && (
        <div style={{ paddingLeft: '5px' }}>
          <TooltipElement placement="top" title={invalidMessage}>
            <Icon size="s">
              <WarningTwoTone twoToneColor={[theme.colors[warningColor], 'transparent']} />
            </Icon>
          </TooltipElement>
        </div>
      )}
    </StyledDiv>
  )
}

export default WithValidityInfo
