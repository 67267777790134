import { Spin } from 'antd'
import StepsElement from 'components/molecules/steps'
import StepData from 'models/components'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { selectFlowId } from 'redux/flow-conifg/general/FlowGeneralConfig.selectors'
import { selectFlowConfigFinishedSteps } from 'redux/flow-conifg/steps'
import { FlowConfigStep } from 'redux/flow-conifg/steps/FlowStepsConfig.types'
import { selectProjectId } from 'redux/project/project-details/ProjectDetails.selectors'
import flowConfigSteps from '../flowConfigSteps'

interface FlowConfigStepsProps {
  currentStep: number
  isLoading?: boolean
  getSavingStepHandler: (onComplete: () => void, shouldCompleteConfig: boolean) => () => void
  stepName: FlowConfigStep
}

const FlowConfigSteps: React.FC<FlowConfigStepsProps> = ({ currentStep, isLoading, getSavingStepHandler }) => {
  const history = useHistory()
  const projectId = useSelector(selectProjectId)
  const flowId = useSelector(selectFlowId)
  const finishedSteps = useSelector(selectFlowConfigFinishedSteps)
  const stepsData: StepData[] = flowConfigSteps.map(({ description, stepName, title }, idx) => {
    const isFinishedStep = (finishedSteps as string[]).includes(stepName)
    const isCurrentStep = stepName === flowConfigSteps[currentStep].stepName
    const isOneStepAfterFinishedSteps = finishedSteps.length === idx
    const statusWhenNotStepFinished = isCurrentStep ? 'process' : 'wait'
    const statusWhenStepFinished = isCurrentStep ? 'process' : 'finish'
    return {
      description,
      disabled: !isFinishedStep && !isCurrentStep && !isOneStepAfterFinishedSteps,
      status: isFinishedStep ? statusWhenStepFinished : statusWhenNotStepFinished,
      title,
    }
  })
  return (
    <Spin spinning={isLoading}>
      <StepsElement
        direction="vertical"
        stepsData={stepsData}
        current={currentStep}
        onChange={(step) =>
          getSavingStepHandler(() => history.push(`/flow-config/${projectId}/${step + 1}/${flowId}`), false)()
        }
      />
    </Spin>
  )
}

export default FlowConfigSteps
