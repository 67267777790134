import { useSelector } from 'react-redux'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { useHistory, useLocation } from 'react-router'
import { useAppDispatch } from 'redux/store'
import { hideNewEntryModal, addNewEntry } from 'redux/files/files-new-entry'
import useUpload from 'hooks/upload'
import Icon from 'components/atoms/icon'
import TooltipElement from 'components/molecules/tooltip-element'
import UploadSingleFile from 'components/organisms/upload/single-file/UploadSingleFile'
import {
  selectIsNewEntryAttributesFormValid,
  selectIsNewEntryDescriptionValid,
  selectAddNewEntryLoading,
} from 'redux/files/files-new-entry/FilesNewEntry.selectors'
import { unwrapResult } from '@reduxjs/toolkit'

import { t, defineMessage } from '@lingui/macro'
import trans from 'helpers/i18n.helpers'
import { selectFilesDefaultSuitabilityCode } from 'redux/files/files-suitability/FilesSuitability.selectors'
import AddFileModal, { addFileErrorMessagesDict } from '../AddFileModal'
import AddFileHeading from '../AddFileHeading'
import EntryAttributesForm from './EntryAttributesForm'
import AddEntryDescription from './AddEntryDescription'

const errorMessageDict = {
  'entry.not_unique_discipline_project_description': defineMessage({
    id: 'project.files.add_file_modal.errors.description_not_unique',
    message: 'Such file already exists. Please change discipline and/or description.',
  }),
  'entry.discipline_not_available': defineMessage({
    id: 'project.files.add_file_modal.errors.no_write_permission_in_discipline',
    message: 'You cannot create files in this discipline.',
  }),
  'entry.metadata_values_required': defineMessage({
    id: 'project.files.add_file_modal.errors.missing_attribute_value',
    message: 'Not all required attributes have been set.',
  }),
  'entry.metadata_value_not_available': defineMessage({
    id: 'project.files.add_file_modal.errors.attributes_unavailable',
    message: 'Some attributes are not available in this project.',
  }),
  'entry.missing_required_metadata': defineMessage({
    id: 'project.files.add_file_modal.errors.missing_attribute',
    message: 'Not all required attributes have been set.',
  }),
  ...addFileErrorMessagesDict,
}

const modalHelpMessage = defineMessage({
  id: 'project.files.add_file_modal.help_text',
  message: "Should required attributes be unavailable, please contact the project's administrator",
})

const AddEntryModal: React.FC = () => {
  const dispatch = useAppDispatch()
  const { file, beforeUpload, onRemove } = useUpload()
  const attributesValidity = useSelector(selectIsNewEntryAttributesFormValid)
  const descriptionValidity = useSelector(selectIsNewEntryDescriptionValid)
  const defaultSuitabilityCode = useSelector(selectFilesDefaultSuitabilityCode)
  const addNewEntryLoading = useSelector(selectAddNewEntryLoading)
  const history = useHistory()
  const { pathname } = useLocation()
  const routeToNewEntry = (entryId: number) =>
    history.replace(`${pathname}?sideDrawer=files&content=documentation&entryId=${entryId}`)
  const onComplete = async () => {
    if (file) {
      await dispatch(addNewEntry({ file, routeToNewEntry }))
        .then(unwrapResult)
        .then(onRemove)
        .catch((e: Error) => {
          beforeUpload(file, [])
          throw e
        })
    }
  }
  const heading = file ? <AddFileHeading name={file.name} /> : null
  return (
    <AddFileModal
      errorMessageDict={errorMessageDict}
      error={addNewEntryLoading.error}
      isLoading={addNewEntryLoading.isLoading}
      isTouched={file !== null}
      onCancel={() => dispatch(hideNewEntryModal())}
      onComplete={onComplete}
      headerText={t({ id: 'project.files.add_file_modal.header_text', message: 'Add a file' })}
      headerToolTip={
        <TooltipElement placement="bottom" title={trans(modalHelpMessage)}>
          <Icon hoverColor="primaryP03">
            <QuestionCircleOutlined />
          </Icon>
        </TooltipElement>
      }
      stepsData={[
        {
          component: <UploadSingleFile file={file} beforeUpload={beforeUpload} onRemove={onRemove} />,
          description: t({ id: 'project.files.add_file_modal.select_file', message: 'File selection' }),
          isValid: file !== null,
        },
        {
          component: <EntryAttributesForm />,
          description: t({ id: 'project.files.add_file_modal.select_attributes', message: 'Attributes selection' }),
          isValid: attributesValidity && !!defaultSuitabilityCode,
          heading,
        },
        {
          component: <AddEntryDescription />,
          description: t({ id: 'project.files.add_file_modal.description', message: 'Description' }),
          isValid: descriptionValidity,
          heading,
        },
      ]}
    />
  )
}

export default AddEntryModal
