import { useSelector } from 'react-redux'
import { Modal, Form, Spin } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import ErrorAlert from 'components/atoms/error-alert'
import DividerElement from 'components/atoms/divider'
import Text from 'components/atoms/text'
import Icon from 'components/atoms/icon'
import Button from 'components/molecules/button-element'
import { handleApiErrors } from 'helpers/HttpError.helpers'
import { ThunkAPIConfig } from 'models/errors'
import { useAppDispatch } from 'redux/store'
import { editCodeLength, hideCodeSettingsModal } from 'redux/administration/administration-code-settings'
import { CodeType, CodeTypes } from 'redux/administration/administration-code-settings/AdministrationCodeSettings.types'
import {
  selectAdministrationCodeLengthDict,
  selectEditingCodeLengthLoading,
} from 'redux/administration/administration-code-settings/AdministrationCodeSettings.selectors'
import { useCallback, useMemo, useState } from 'react'
import { AsyncThunk } from '@reduxjs/toolkit'
import { defineMessage, Trans } from '@lingui/macro'
import { cancelText, saveText } from 'data/messages/controls'
import trans from 'helpers/i18n.helpers'
import { CodeLengthEdit } from 'components/molecules/code-length'
import ManageValueAndNameFields from 'components/molecules/code-form-field/ManageValueAndNameFields'
import { CodeField } from 'models/administration'

interface ModalProps {
  codeType: CodeType
  data: unknown[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  reloadDataFun: AsyncThunk<any, void, ThunkAPIConfig>
  nameFieldLabel: string
}

interface CodeProps {
  bestMin: number
  bestMax: number
}

const invalidCodeLengthMessage = defineMessage({
  id: 'administration.common.codes.modal.invalid_code_length_error',
  message: 'Length of some codes does not match the selected length.',
})

const errorMessageDict = {
  'settings.invalid_value': defineMessage({
    id: 'administration.common.codes.modal.invalid_length_error',
    message: 'Invalid code length.',
  }),
  'settings.duplicated_value': defineMessage({
    id: 'administration.common.codes.modal.duplicated_code_error',
    message: 'All codes must be unique.',
  }),
  'settings.missing_values': defineMessage({
    id: 'administration.common.codes.modal.missing_values_error',
    message: 'Not all values were changed. Refresh the page and try again.',
  }),
  'project_stage.invalid_code_length': invalidCodeLengthMessage,
  'project.invalid_code_length': invalidCodeLengthMessage,
  'suitability_code.invalid_code_length': invalidCodeLengthMessage,
  'organization.invalid_code_length': invalidCodeLengthMessage,
  'discipline.invalid_code_length': invalidCodeLengthMessage,
}

function getCodeTypeProps(codeType: CodeType): CodeProps {
  const defaultDict: Record<CodeType, CodeProps> = {
    [CodeTypes.ORGANIZATION_CODE]: { bestMin: 2, bestMax: 6 },
    [CodeTypes.DISCIPLINE_CODE]: { bestMin: 1, bestMax: 2 },
    [CodeTypes.PROJECT_CODE]: { bestMin: 2, bestMax: 6 },
    [CodeTypes.SUITABILITY_CODE_CODE]: { bestMin: 1, bestMax: 2 },
    [CodeTypes.PROJECT_STAGE_CODE]: { bestMin: 1, bestMax: 2 },
    [CodeTypes.WAITING_ROOM_SIZE]: { bestMin: 1, bestMax: Number.MAX_SAFE_INTEGER },
  }
  return defaultDict[codeType]
}

// eslint-disable-next-line max-lines-per-function
const EditCodeLengthModal: React.FC<ModalProps> = ({ codeType, data, reloadDataFun, nameFieldLabel }) => {
  const [form] = Form.useForm()
  const dispatch = useAppDispatch()
  const editingLoading = useSelector(selectEditingCodeLengthLoading)
  const handleCancel = () => dispatch(hideCodeSettingsModal())
  const handleChangeCode = useCallback(
    (fields) =>
      dispatch(
        editCodeLength({
          codeType,
          newCodeLength: fields.codeLength,
          values: fields.values.map(({ id, code }: { id?: number; code: string }) => ({ id, code })),
        })
      ),
    [dispatch, codeType]
  )
  const handleSubmit = () => {
    form
      .validateFields()
      .then(async () => {
        const fields = form.getFieldsValue()
        handleChangeCode(fields)
          .then(() => dispatch(reloadDataFun()))
          .catch(() => {})
      })
      .catch(() => {})
  }
  const codeLengthDict = useSelector(selectAdministrationCodeLengthDict)
  const [length, setLength] = useState<number>(codeLengthDict[codeType])
  const alertMessageDict = [
    {
      error: editingLoading.error,
      message: handleApiErrors(errorMessageDict, editingLoading.error?.errors),
    },
  ]
  const bestValues = getCodeTypeProps(codeType)
  const onCodeLengthChange = (value: string | number | undefined) => {
    setLength(Number(value))
    form.validateFields()
  }
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState<boolean>(false)
  const valuesData = useMemo(
    () => (data as { code: CodeField }[]).map(({ code, ...r }) => ({ ...r, code: code.code })),
    [data]
  )
  return (
    <Modal
      visible
      onCancel={handleCancel}
      closeIcon={
        <Icon>
          <CloseOutlined />
        </Icon>
      }
      width={1200}
      maskClosable={false}
      title={
        <Text textStyle="header-H2b">
          <Trans id="administration.common.codes.modal.header_text">Edit required code length</Trans>
        </Text>
      }
      footer={
        <>
          <Button onClick={handleCancel} type="tertiary">
            {trans(cancelText)}
          </Button>
          <Button loading={editingLoading.isLoading} onClick={handleSubmit} disabled={isSaveButtonDisabled}>
            {trans(saveText)}
          </Button>
        </>
      }
    >
      <Spin spinning={editingLoading.isLoading}>
        <Form
          form={form}
          colon={false}
          initialValues={{
            codeLength: codeLengthDict[codeType],
            values: valuesData,
          }}
          onFieldsChange={() => {
            setIsSaveButtonDisabled(form.getFieldsError().some(({ errors }) => errors.length))
          }}
        >
          <CodeLengthEdit
            currentLength={codeLengthDict[codeType]}
            bestValues={bestValues}
            onChange={onCodeLengthChange}
            withWarning
          />
          <DividerElement />
          <ManageValueAndNameFields
            formListName="values"
            isDisabled={false}
            allowValueEdit={false}
            valueFieldName="name"
            valueCodeLength={length}
            nameLabel={nameFieldLabel}
            onCodeChange={() => form.validateFields()}
          />
        </Form>
        <ErrorAlert alertMessageDict={alertMessageDict} />
      </Spin>
    </Modal>
  )
}

export default EditCodeLengthModal
