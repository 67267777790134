import ButtonElement from 'components/molecules/button-element'

interface AdministrationToolbarToolbarProps {
  text: string | React.ReactNode
  onClick: () => void
}

const AdministrationToolbarButton: React.FC<AdministrationToolbarToolbarProps> = ({ text, onClick }) => {
  return (
    <ButtonElement type="primary" onClick={onClick}>
      {text}
    </ButtonElement>
  )
}

export default AdministrationToolbarButton
