import { Form, Input } from 'antd'
import styled from 'styled-components'
import Text from 'components/atoms/text'
import { t, Trans } from '@lingui/macro'
import { FormListFieldData } from 'antd/lib/form/FormList'
import { codeInputRules } from 'helpers/Validators.helpers'

const StyledCodeItem = styled(Form.Item)`
  display: inline-block;
  > :last-child {
    margin-top: 0;
  }
`

interface CodeItemProps {
  isFirstInColumn: boolean
  field: FormListFieldData
  showButtons: boolean
  disabled: boolean
  valueCodeLength?: number
  formListName: string
  onCodeChange?: () => void
}

const CodeItem: React.FC<CodeItemProps> = ({
  isFirstInColumn,
  field,
  onCodeChange,
  valueCodeLength,
  formListName,
  showButtons = false,
  disabled = false,
}) => {
  return (
    <StyledCodeItem
      label={
        isFirstInColumn && (
          <Text textStyle="regularText-smallSemiBold">
            <Trans id="administration.common.codes.modal.code_label">Code</Trans>
          </Text>
        )
      }
      validateFirst
      colon={false}
      name={[field.name, 'code']}
      style={{
        display: 'inline-block',
        width: showButtons ? 'calc(33% - 8px)' : 'calc(36% - 8px)',
      }}
      rules={[
        ...codeInputRules(valueCodeLength),
        ({ getFieldValue }) => ({
          validator(_, value) {
            const values = getFieldValue(formListName) as { code: string }[]
            if (values.filter(({ code }) => code === value).length !== 1) {
              return Promise.reject(
                t({
                  id: 'administration.common.codes.modal.code_not_unique_error',
                  message: 'Code not unique',
                })
              )
            }
            return Promise.resolve()
          },
        }),
      ]}
      fieldKey={[field.fieldKey, 'code']}
    >
      <Input
        onChange={onCodeChange}
        disabled={disabled}
        placeholder={t({ id: 'administration.attributes.modal.value_code_placeholder', message: 'Code' })}
      />
    </StyledCodeItem>
  )
}

export default CodeItem
