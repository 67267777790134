import { Trans } from '@lingui/macro'
import { Form, Space } from 'antd'
import MultipleSelectWithTagAndAllOption from 'components/atoms/select-element/multiple-with-tag/MultipleSelectWithTagAndAllOption'
import BaseFormItem from 'components/molecules/form-item/BaseFormItem'
import { cdeStatusOptions } from 'data/cdeStatus'
import { anyValueText } from 'data/messages/controls'
import trans from 'helpers/i18n.helpers'
import {
  getAvailableSuitabilityCodesFromStatuses,
  getOrganizationsOptions,
  getSuitabilityCodesOptions,
} from 'models/administration'
import { FlowConditionsConfig, FlowUsersTypeRadioOption } from 'models/flow'
import { getDisciplinesOptions } from 'models/project/projectConfig'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectGettingFlowConditionsLoading,
  selectOrganizations,
} from 'redux/flow-conifg/conditions/FlowConditionsConfig.selectors'
import { updateFlowConditionsConfig } from 'redux/flow-conifg/conditions/FlowConditionsConfig.slice'
import { selectAvailableDisciplines } from 'redux/project/project-config/members-permissions/ProjectMembersPermissionsConfig.selectors'
import { selectProjectSuitabilityCodes } from 'redux/project/project-config/suitability/ProjectSuitabilityConfig.selectors'
import UsersRadioGroup from './UsersRadioGroup'

interface FlowConditionsConfigFormProps {
  flowConditionsConfig?: FlowConditionsConfig
  flowUsersTypeRadioOption: FlowUsersTypeRadioOption
}

const FlowConditionsConfigForm: React.FC<FlowConditionsConfigFormProps> = ({
  flowConditionsConfig,
  flowUsersTypeRadioOption,
}) => {
  const [form] = Form.useForm()
  const organizations = useSelector(selectOrganizations)
  const disciplines = useSelector(selectAvailableDisciplines)
  const suitabilityCodes = useSelector(selectProjectSuitabilityCodes)
  const dispatch = useDispatch()
  const { isLoading: isFetchingConditionsLoading } = useSelector(selectGettingFlowConditionsLoading)
  if (isFetchingConditionsLoading || !flowConditionsConfig) return null
  return (
    <Form
      layout="vertical"
      form={form}
      initialValues={flowConditionsConfig}
      onValuesChange={(changedVals: FlowConditionsConfig, vals: FlowConditionsConfig) => {
        const filteredCodesByStatusList = getAvailableSuitabilityCodesFromStatuses(
          suitabilityCodes.filter(({ id }) => vals.initialSuitabilityCodes.includes(id)),
          vals.initialCdeStatuses
        ).map(({ id }) => id)
        form.setFieldsValue({
          initialSuitabilityCodes: filteredCodesByStatusList,
        })
        dispatch(
          updateFlowConditionsConfig({
            ...vals,
            initialSuitabilityCodes: filteredCodesByStatusList,
          })
        )
      }}
    >
      <Space direction="vertical" style={{ width: 428 }}>
        <BaseFormItem
          name="initialCdeStatuses"
          label={<Trans id="flow_config.conditions.cde_statuses_label">CDE directories to start process</Trans>}
          tooltip={
            <Trans id="flow_config.conditions.cde_statuses_tooltip">
              The process will only be available for files used in selected CDE directories.
            </Trans>
          }
        >
          <MultipleSelectWithTagAndAllOption options={cdeStatusOptions} placeholder={trans(anyValueText)} />
        </BaseFormItem>
        <BaseFormItem
          name="initialOrganizations"
          label={<Trans id="flow_config.conditions.organizations_label">Organizations</Trans>}
          tooltip={
            <Trans id="flow_config.conditions.organizations_tooltip">
              The process will only be available to members of selected organizations
            </Trans>
          }
        >
          <MultipleSelectWithTagAndAllOption
            options={getOrganizationsOptions(organizations)}
            placeholder={trans(anyValueText)}
          />
        </BaseFormItem>
        <BaseFormItem
          name="initialDisciplines"
          label={<Trans id="flow_config.conditions.disciplines_label">Disciplines</Trans>}
          tooltip={
            <Trans id="flow_config.conditions.disciplines_tooltip">
              The process will only be available for files full of discipline selections.
            </Trans>
          }
        >
          <MultipleSelectWithTagAndAllOption
            options={getDisciplinesOptions(disciplines)}
            placeholder={trans(anyValueText)}
          />
        </BaseFormItem>
        <BaseFormItem
          name="initialSuitabilityCodes"
          label={<Trans id="flow_config.conditions.suitability_codes_label">Suitability codes</Trans>}
          tooltip={
            <Trans id="flow_config.conditions.suitability_codes_tooltip">
              The process will only be available for files that have one of the approved suitability codes.
            </Trans>
          }
        >
          <MultipleSelectWithTagAndAllOption
            options={getSuitabilityCodesOptions(
              getAvailableSuitabilityCodesFromStatuses(suitabilityCodes, flowConditionsConfig.initialCdeStatuses)
            )}
            placeholder={trans(anyValueText)}
          />
        </BaseFormItem>
        <UsersRadioGroup
          flowConditionsConfig={flowConditionsConfig}
          flowUsersTypeRadioOption={flowUsersTypeRadioOption}
        />
      </Space>
    </Form>
  )
}

export default FlowConditionsConfigForm
