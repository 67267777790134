import { Radio, Space } from 'antd'
import { RadioChangeEvent } from 'antd/lib/radio'

interface RadioOption {
  value: string
  label: string
  disabled?: boolean
}

interface RadioGroupProps {
  selectedValue?: number | string
  options: RadioOption[]
  onChange?: (e: RadioChangeEvent) => void
}

const RadioGroup: React.FC<RadioGroupProps> = ({ selectedValue, onChange, options }) => {
  return (
    <Radio.Group value={selectedValue} onChange={onChange}>
      <Space direction="vertical">
        {options?.map(({ value, label, disabled }) => (
          <Radio value={value} disabled={disabled} key={value}>
            {label}
          </Radio>
        ))}
      </Space>
    </Radio.Group>
  )
}

export default RadioGroup
