import { t, Trans } from '@lingui/macro'
import { required } from 'helpers/Validators.helpers'
import styled from 'styled-components'
import Text from 'components/atoms/text'
import { Space } from 'antd'
import MAX_CODE_LENGTH from 'data/codes'
import Icon from 'components/atoms/icon'
import { InfoCircleOutlined } from '@ant-design/icons'
import FormInputNumber from '../form-item/FormNumberInput'
import CodeLengthFieldLabel from './CodeLengthFieldLabel'

interface CodeLengthEditProps {
  currentLength: number
  bestValues: { bestMin: number; bestMax: number }
  onChange: (value: string | number | undefined) => void
  valueName?: string
  withWarning?: boolean
}

const Grid = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  height: ${(props) => props.theme.sizes.l};
  margin-top: -15px;
  > :last-child {
    padding-left: ${(props) => props.theme.sizes.m};
  }
`

const StyledSpace = styled.div`
  display: flex;
  gap: ${(props) => props.theme.sizes.xxs};
  height: ${(props) => props.theme.sizes.l};
  align-items: center;
  border-right: 1px solid ${(props) => props.theme.colors.grayG03};
  padding-right: ${(props) => props.theme.sizes.m};
`

const StyledSpan = styled.span`
  display: flex;
  gap: ${(props) => props.theme.sizes.xxs};
`

const headerInfo = (
  <StyledSpan>
    <Icon color="gold6" size="s">
      <InfoCircleOutlined />
    </Icon>
    <Text textStyle="regularText-smallSemiBold" color="grayG08">
      <Trans id="administration.common.codes.modal.changing_lengths_requires_changin_codes_info">
        Changing code&apos;s length results in the need to change all invalid codes
      </Trans>
    </Text>
  </StyledSpan>
)

const CodeLengthEdit: React.FC<CodeLengthEditProps> = ({
  currentLength,
  bestValues,
  onChange,
  valueName = 'codeLength',
  withWarning = false,
}) => {
  const mainContent = (
    <Grid>
      <StyledSpace>
        <Text textStyle="regularText-small">
          <Trans id="administration.common.codes.modal.current_code_length_text">Current code length:</Trans>
        </Text>
        <Text textStyle="regularText-smallSemiBold">{currentLength}</Text>
      </StyledSpace>
      <Space style={{ justifyItems: 'left' }} align="start">
        <CodeLengthFieldLabel minValue={bestValues.bestMin} maxValue={bestValues.bestMax} />
        <FormInputNumber
          style={{ width: '125px' }}
          name={valueName}
          placeholder={t({
            id: 'administration.common.codes.modal.code_length_placeholder',
            message: 'Enter code length',
          })}
          min={1}
          max={MAX_CODE_LENGTH}
          rules={[required()]}
          onChange={onChange}
        />
      </Space>
    </Grid>
  )
  if (!withWarning) return mainContent
  return (
    <Space direction="vertical" size="large">
      {headerInfo}
      {mainContent}
    </Space>
  )
}

export default CodeLengthEdit
