import ErrorAlert from 'components/atoms/error-alert'
import { useSelector } from 'react-redux'
import {
  selectFetchPermissionsLoading,
  selectFetchProjectsDisciplinesLoading,
  selectFetchProjectsLoading,
} from 'redux/user-profile/permissions/UserPermissions.selectors'

const UserProfilePermissionsAlert: React.FC = () => {
  const fetchingProjectsLoading = useSelector(selectFetchProjectsLoading)
  const fetchingDisciplinesLoading = useSelector(selectFetchProjectsDisciplinesLoading)
  const fetchingPermissionsLoading = useSelector(selectFetchPermissionsLoading)
  const alertMessageDict = [
    {
      error: fetchingProjectsLoading.error,
      message: 'Nie udało się pobrać projektów',
    },
    {
      error: fetchingDisciplinesLoading.error,
      message: 'Nie udało się pobrać branż',
    },
    {
      error: fetchingPermissionsLoading.error,
      message: 'Nie udało się pobrać uprawnień',
    },
  ]
  return <ErrorAlert alertMessageDict={alertMessageDict} />
}

export default UserProfilePermissionsAlert
