import { Card, Row, Space } from 'antd'
import Text from 'components/atoms/text'
import themePxValueToNumber from 'helpers/Styles.helpers'
import { useSelector } from 'react-redux'
import {
  selectSelectedUserProfileSubMenu,
  selectSelectedUserProfileMenuItem,
} from 'redux/user-profile/user-profile-menu/UserProfileMenu.selectors'
import styled from 'styled-components'
import userProfileOptions from '../userProfileOptions'

const StyledDiv = styled.div`
  height: 100%;
  .ant-typography {
    flex-shrink: 0;
  }
`

const StyledCard = styled(Card)`
  min-height: 100%;
  min-width: max-content;
  display: flex;
  .ant-card-body {
    width: 100%;
  }
`

const StyledSpace = styled(Space)`
  height: calc(100% - ${(props) => themePxValueToNumber(props.theme.sizes.l)}px);
  & > .ant-space-item:nth-child(3) {
    height: calc(100% - ${(props) => themePxValueToNumber(props.theme.sizes.m) * 2}px);
    overflow-y: auto;
  }
`

const UserProfileContent: React.FC = () => {
  const selectedUserProfileSubMenu = useSelector(selectSelectedUserProfileSubMenu)
  const selectedUserProfileItem = useSelector(selectSelectedUserProfileMenuItem)

  const currentSubMenu =
    userProfileOptions.find(({ key }) => key === selectedUserProfileSubMenu) ?? userProfileOptions[0]
  const currentItem = currentSubMenu.items.find(({ key }) => key === selectedUserProfileItem)

  return (
    <StyledDiv>
      <Text textStyle="header-H1">{currentSubMenu?.name}</Text>
      <StyledSpace size="middle" direction="vertical" style={{ width: '100%' }}>
        <Row justify="space-between" align="bottom">
          <Text textStyle="header-H2">{currentItem?.name}</Text>
        </Row>
        <div>{currentItem?.heading}</div>
        <StyledCard>{currentItem?.component}</StyledCard>
      </StyledSpace>
    </StyledDiv>
  )
}

export default UserProfileContent
