import { t } from '@lingui/macro'
import { AsyncThunkAction, unwrapResult } from '@reduxjs/toolkit'
import { Modal, Spin } from 'antd'
import FullScreenModalFooter from 'components/organisms/fullscreen-modal/FullscreenModalFooter'
import FullScreenModalHeader from 'components/organisms/fullscreen-modal/FullScreenModalHeader'
import FullScreenModalTemplate from 'components/templates/fullscreen-modal'
import { cancelText, closeText, nextText } from 'data/messages/controls'
import { yesText } from 'data/messages/misc'
import trans from 'helpers/i18n.helpers'
import { openNotification } from 'helpers/Notifications.helpers'
import { ThunkAPIConfig } from 'models/errors'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { clearProjectConfigState } from 'redux/project/project-config'
import { selectFetchingProjectConfigFinishedStepsLoading } from 'redux/project/project-config/status/ProjectStatusConfig.selectors'
import { steps } from 'redux/project/project-config/status/ProjectStatusConfig.types'
import {
  selectFetchProjectDetailsLoading,
  selectProjectDetails,
  selectProjectId,
} from 'redux/project/project-details/ProjectDetails.selectors'
import { useAppDispatch } from 'redux/store'
import useStepsParams from '../../../../hooks/stepsParams'
import FullScreenModalHeaderText from './FullScreenModalHeaderText'
import ProjectConfigSteps from './steps/ProjectConfigSteps'

interface BaseProjectConfigViewProps {
  content: React.ReactNode
  isContentValid?: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  saveStepThunk?: AsyncThunkAction<any, any, ThunkAPIConfig>
  handleCompleteProjectConfig?: (onConfigLeave: () => void) => () => void
  isContentLoading?: boolean
}

const getCancelAction = (isValid: boolean, handleSaveStep: () => void) => () => {
  Modal.confirm({
    title: t({
      id: 'project_config.base.notification.cancel_title',
      message: 'Closing the window will abort the project configuration.',
    }),
    content: isValid
      ? t({
          id: 'project_config.base.notification.invalid_changes_message',
          message: 'The changes made so far will be saved. You can complete the setup at any time.',
        })
      : t({
          id: 'project_config.base.notification.valid_changes_message',
          message:
            'The changes made so far are incorrect. Interrupting the configuration will result in the loss of the entered data.',
        }),
    cancelText: trans(cancelText),
    okText: trans(closeText),
    onOk: handleSaveStep,
  })
}
// eslint-disable-next-line max-lines-per-function
const BaseProjectConfigView: React.FC<BaseProjectConfigViewProps> = ({
  content,
  isContentValid = true,
  saveStepThunk,
  handleCompleteProjectConfig,
  isContentLoading = false,
}) => {
  const { stepIdx, step } = useStepsParams()
  const projectId = useSelector(selectProjectId)
  const history = useHistory()
  const isProjectActive = !!useSelector(selectProjectDetails)?.isActive
  const { isLoading: isFetchingDetailsLoading } = useSelector(selectFetchProjectDetailsLoading)
  const { isLoading: fetchingProjectConfigFinishedStepsLoading } = useSelector(
    selectFetchingProjectConfigFinishedStepsLoading
  )
  const { isLoading: addingProjectConfigFinishedStepsLoading } = useSelector(
    selectFetchingProjectConfigFinishedStepsLoading
  )
  const isLoading =
    isContentLoading ||
    isFetchingDetailsLoading ||
    fetchingProjectConfigFinishedStepsLoading ||
    addingProjectConfigFinishedStepsLoading
  const dispatch = useAppDispatch()

  const handleNextStep = () => projectId && history.push(`/project-config/${projectId}/${stepIdx + 2}`)
  const handlePrevStep = () => projectId && history.push(`/project-config/${projectId}/${stepIdx}`)

  const handleLeave = () => {
    dispatch(clearProjectConfigState())
    if (isProjectActive) {
      history.replace(`/project/${projectId}`)
    } else {
      history.replace('/')
    }
  }

  const getSavingProgressHandler = (
    onComplete: () => void,
    shouldCompleteConfig: boolean,
    completeWithError?: boolean
  ) => () => {
    if (saveStepThunk)
      if (isContentValid)
        dispatch(saveStepThunk)
          .then(unwrapResult)
          .then(() => {
            openNotification('success', {
              message: t({
                id: 'project_config.base.notification.success_message',
                message: `Step ${step} has been saved.`,
              }),
              placement: 'bottomLeft',
            })
            onComplete()
          })
          .catch(() => {
            if (completeWithError) {
              onComplete()
            }
          })
      else
        Modal.confirm({
          title: t({
            id: 'project_config.base.modal.confirm_title',
            message: 'Inserted data are invalid. Do you want to continue?',
          }),
          cancelText: trans(cancelText),
          okText: trans(yesText),
          onOk: onComplete,
        })
    else if (handleCompleteProjectConfig && shouldCompleteConfig) handleCompleteProjectConfig(onComplete)()
    else onComplete()
  }
  const isLastStepWhenProjectActive = isProjectActive && stepIdx === steps.length - 1
  return (
    <FullScreenModalTemplate
      header={
        <FullScreenModalHeader
          handleClose={getCancelAction(isContentValid, getSavingProgressHandler(handleLeave, true, true))}
          text={<FullScreenModalHeaderText />}
        />
      }
      contentLeft={
        <ProjectConfigSteps
          currentStep={stepIdx}
          getSavingStepHandler={getSavingProgressHandler}
          isLoading={isLoading}
        />
      }
      contentRight={<Spin spinning={isLoading}>{content}</Spin>}
      footer={
        <FullScreenModalFooter
          onNextBtnClick={getSavingProgressHandler(
            handleCompleteProjectConfig || isLastStepWhenProjectActive ? handleLeave : handleNextStep,
            true
          )}
          onPrevBtnClick={getSavingProgressHandler(handlePrevStep, false)}
          isPrevBtnDisabled={stepIdx === 0}
          isNextBtnDisabled={!isContentValid}
          nextBtnText={
            handleCompleteProjectConfig || isLastStepWhenProjectActive
              ? t({ id: 'project_config.base.modal.finish_config_button', message: 'Finish configuration' })
              : trans(nextText)
          }
          isLoading={isLoading}
        />
      }
    />
  )
}

export default BaseProjectConfigView
