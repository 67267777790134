import { FunctionComponent, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { ImageryLayerFeatureInfo } from 'cesium'
import { setFeaturesInfo, clearCesiumViewerState } from 'redux/cesium-viewer'
import { selectIsAuthenticated } from 'redux/login/Login.selectors'
import { fetchGisFileLayers } from 'redux/map/map-gis-layers/map-gis-file-layers'
import { fetchGisProjectLayers } from 'redux/map/map-gis-layers/map-gis-project-layers'
import { fetchWmsLayers } from 'redux/map/map-wms-layers'
import { fetchProjectEntries } from 'redux/files/files-documentation'
import { fetchGeoserverAuth } from 'redux/cesium-viewer/CesiumViewer.slice'
import CesiumViewer from 'components/organisms/cesium-viewer/CesiumViewer'
import ProjectTemplate from 'components/templates/project'
import ProjectNamePanel from 'components/organisms/project-name-panel'
import FilesOptionBar from 'components/organisms/files/options-bar'
import CoordinatesPanel from 'components/organisms/coordinates-panel'
import MapToolBar from 'components/organisms/map/map-toolbar'
import WithHeaderTemplate from 'components/templates/with-header'
import MainNavBar from 'components/organisms/main-navbar'
import MapSideDrawer from 'components/organisms/map/map-sidedrawer'
import FilesSideDrawer from 'components/organisms/files/sidedrawer'
import { selectFilesControlsShowAllVersions } from 'redux/files/files-controls/FilesControls.selectors'
import { clearFilesState } from 'redux/files'
import { clearMapState } from 'redux/map'
import { clearProjectDetailsState, fetchProjectDetails, clearProjectDetailsError } from 'redux/project/project-details'
import { selectFetchProjectDetailsLoading } from 'redux/project/project-details/ProjectDetails.selectors'
import useError from 'hooks/error'
import { fetchPossibleProjections } from 'redux/project/project-details/ProjectDetails.slice'
import useProjectId from 'hooks/useProjectId'
import { defineMessage } from '@lingui/macro'
import { fetchProjectExecutableFlows } from 'redux/files/flows'

const PROJECT_ERROR_MESSAGES = {
  403: defineMessage({ id: 'project.errors.no_access', message: 'You do not have permission to access this project' }),
}

const ProjectPage: FunctionComponent = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const isAuthenticated = useSelector(selectIsAuthenticated)
  const showAllVersions = useSelector(selectFilesControlsShowAllVersions)
  const projectId = useProjectId()

  useError(selectFetchProjectDetailsLoading, clearProjectDetailsError, PROJECT_ERROR_MESSAGES, () => history.push('/'))
  useEffect(() => {
    if (isAuthenticated && projectId) {
      dispatch(fetchGisProjectLayers(projectId))
      dispatch(fetchGisFileLayers(projectId))
      dispatch(fetchGeoserverAuth(projectId))
      dispatch(fetchProjectDetails(projectId))
      dispatch(fetchPossibleProjections(projectId))
      dispatch(fetchWmsLayers())
    }
  }, [dispatch, isAuthenticated, projectId])

  useEffect(() => {
    if (isAuthenticated && projectId) {
      dispatch(fetchProjectEntries({ projectId, allVersions: showAllVersions }))
    }
  }, [dispatch, showAllVersions, isAuthenticated, projectId])

  useEffect(() => {
    if (isAuthenticated && projectId) {
      dispatch(fetchProjectExecutableFlows(projectId))
    }
  }, [dispatch, isAuthenticated, projectId])

  useEffect(() => {
    return () => {
      dispatch(clearFilesState())
      dispatch(clearMapState())
      dispatch(clearCesiumViewerState())
      dispatch(clearProjectDetailsState())
    }
  }, [dispatch])

  function handleFeatureSelection(featurePromise: Promise<ImageryLayerFeatureInfo[]> | undefined) {
    if (featurePromise) {
      featurePromise.then((info) => dispatch(setFeaturesInfo(info.map((f) => f.data.properties))))
    }
  }

  return (
    <>
      <WithHeaderTemplate
        header={<MainNavBar />}
        content={
          <ProjectTemplate
            topPanel={<ProjectNamePanel />}
            rightAside={
              <>
                <MapToolBar />
                <MapSideDrawer />
              </>
            }
            viewer={<CesiumViewer onFeatureSelected={handleFeatureSelection} />}
            bottomPanel={<CoordinatesPanel />}
            leftAside={
              <>
                <FilesSideDrawer />
                <FilesOptionBar />
              </>
            }
          />
        }
      />
    </>
  )
}

export default ProjectPage
