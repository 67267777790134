import { FormItemProps } from 'antd/lib/form'
import { InputProps } from 'antd/lib/input'
import FormInput from 'components/molecules/form-item/FormInput'
import { codePlaceholder, codeText } from 'data/messages/misc'
import trans from 'helpers/i18n.helpers'
import { codeInputRules } from 'helpers/Validators.helpers'

interface CodeFormInputProps {
  codeLength?: number
}

const CodeFormInput: React.FC<CodeFormInputProps & FormItemProps & InputProps> = ({
  codeLength,
  name = 'code',
  label,
  placeholder,
  ...restProps
}) => {
  return (
    <FormInput
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...restProps}
      name={name}
      label={label || trans(codeText)}
      placeholder={placeholder || trans(codePlaceholder)}
      rules={codeInputRules(codeLength)}
      validateFirst
    />
  )
}

export default CodeFormInput
