import { InputNumber } from 'antd'
import { FormItemProps } from 'antd/lib/form'
import { InputNumberProps } from 'antd/lib/input-number'
import BaseFormItem from './BaseFormItem'

const FormInputNumber: React.FC<FormItemProps & InputNumberProps> = ({
  placeholder,
  disabled = false,
  onChange,
  value,
  label,
  name,
  rules,
  size,
  onFocus,
  onBlur,
  validateStatus,
  help,
  min,
  max,
  style,
}) => (
  <BaseFormItem rules={rules} label={label} name={name} validateStatus={validateStatus} help={help}>
    <InputNumber
      size={size}
      onFocus={onFocus}
      onBlur={onBlur}
      defaultValue={value}
      onChange={onChange}
      placeholder={placeholder && placeholder.toString()}
      disabled={disabled}
      min={min}
      max={max}
      style={style}
    />
  </BaseFormItem>
)

export default FormInputNumber
