import { Card, Space } from 'antd'
import Text from 'components/atoms/text'
import { BasicOrganizationData } from 'models/administration'
import styled from 'styled-components'
import { Draggable, Droppable } from 'react-beautiful-dnd'
import DraggableItem from './DraggableItem'

const StyledDiv = styled.div`
  display: flex;
  gap: ${(props) => props.theme.sizes.xs};
  align-items: center;
  min-height: 100%;
`
const StyledCard = styled(Card)`
  background-color: ${(props) => props.theme.colors.grayG01};
  width: 390px;
  height: 100%;
  .ant-card-body {
    height: 100%;
  }
  .ant-card-body > div:first-child {
    height: 100%;
    align-items: baseline;
  }
`

const StyledSpace = styled(Space)`
  min-width: 100%;
`

interface ItemProps {
  organizations: BasicOrganizationData[]
  index: number
}

const DroppableItems: React.FC<ItemProps> = ({ organizations, index }) => {
  return (
    <StyledCard>
      <Droppable key={`${index}`} droppableId={`${index}`}>
        {(droppableProvided) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <StyledDiv ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
            <StyledSpace size="large" direction="vertical">
              <Text center textStyle="header-H2b">{`WIP ${index}`}</Text>
              {organizations.map(({ id: orgId, name }, idx) => {
                return (
                  <Draggable key={orgId} draggableId={`org-${orgId}`} index={idx}>
                    {(draggableProvided) => (
                      <div
                        ref={draggableProvided.innerRef}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...draggableProvided.draggableProps}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...draggableProvided.dragHandleProps}
                        style={draggableProvided.draggableProps.style}
                      >
                        <DraggableItem text={name} />
                      </div>
                    )}
                  </Draggable>
                )
              })}
            </StyledSpace>
            {droppableProvided.placeholder}
          </StyledDiv>
        )}
      </Droppable>
    </StyledCard>
  )
}

export default DroppableItems
