import { useDispatch, useSelector } from 'react-redux'
import { Modal, Form, Spin } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import Text from 'components/atoms/text'
import Icon from 'components/atoms/icon'
import FormInput from 'components/molecules/form-item/FormInput'

import Button from 'components/molecules/button-element'
import { requiredAndNotBlank } from 'helpers/Validators.helpers'
import objectsDiff from 'helpers/Objects.helpers'
import {
  selectAddingProjectStageLoading,
  selectProjectStageDisplayedInModal,
  selectUpdatingProjectStageLoading,
} from 'redux/administration/administration-project-stages/AdministrationProjectStages.selectors'
import {
  addProjectStage,
  closeProjectStageModal,
  updateProjectStage,
} from 'redux/administration/administration-project-stages'
import { t, Trans } from '@lingui/macro'
import { addText, cancelText, saveText } from 'data/messages/controls'
import trans from 'helpers/i18n.helpers'
import { selectAdministrationCodeLengthDict } from 'redux/administration/administration-code-settings/AdministrationCodeSettings.selectors'
import CodeFormInput from 'components/molecules/code-length/CodeFormInput'
import ProjectStageModalError from './ProjectStageModalError'

const ProjectStageModal: React.FC = () => {
  const [form] = Form.useForm()
  const stage = useSelector(selectProjectStageDisplayedInModal)
  const currentLengthDict = useSelector(selectAdministrationCodeLengthDict)

  const dispatch = useDispatch()
  const handleCancel = () => dispatch(closeProjectStageModal())
  const handleSubmit = async () => {
    form
      .validateFields()
      .then(() => {
        const formValues = form.getFieldsValue()
        if (stage === undefined) {
          dispatch(addProjectStage(formValues))
        } else {
          const fieldsToUpdate = objectsDiff({ code: stage.code.code, name: stage.name }, formValues)
          dispatch(
            updateProjectStage({
              stageId: stage.id,
              stageFieldsToUpdate: fieldsToUpdate,
            })
          )
        }
      })
      .catch(() => {})
  }

  const { isLoading: isUpdateLoading } = useSelector(selectUpdatingProjectStageLoading)
  const { isLoading: isAddLoading } = useSelector(selectAddingProjectStageLoading)
  const isLoading = stage === undefined ? isAddLoading : isUpdateLoading
  const modalTitle =
    stage === undefined
      ? t({ id: 'administration.project_stages.modal.add_project_stage_header', message: 'Create project stage' })
      : t({ id: 'administration.project_stages.modal.edit_project_stage_header', message: 'Edit project stage' })

  const formFields = [
    {
      label: <Trans id="administration.project_stages.modal.name_field_label">Name</Trans>,
      name: 'name',
      placeholder: t({ id: 'administration.project_stages.modal.name_field_placeholder', message: 'Enter name' }),
    },
  ]

  return (
    <Modal
      visible
      onCancel={handleCancel}
      closeIcon={
        <Icon>
          <CloseOutlined />
        </Icon>
      }
      maskClosable={false}
      title={<Text textStyle="header-H2b">{modalTitle}</Text>}
      footer={
        <>
          <Button onClick={handleCancel} type="tertiary">
            {trans(cancelText)}
          </Button>
          <Button loading={isLoading} onClick={handleSubmit}>
            {trans(stage === undefined ? addText : saveText)}
          </Button>
        </>
      }
    >
      <Spin spinning={isLoading}>
        <Form
          form={form}
          labelCol={{ sm: { span: 5 } }}
          initialValues={stage && { name: stage.name, code: stage.code.code }}
        >
          <CodeFormInput
            codeLength={currentLengthDict.PROJECT_STAGE_CODE}
            label={<Trans id="administration.project_stages.modal.code_field_label">Code</Trans>}
          />
          {formFields.map(({ label, name, placeholder }) => (
            <FormInput label={label} name={name} placeholder={placeholder} rules={requiredAndNotBlank()} />
          ))}
        </Form>
        <ProjectStageModalError />
      </Spin>
    </Modal>
  )
}

export default ProjectStageModal
