import { MAX_COMMAND_LENGTH, MAX_DESCRIPTION_LENGTH, MAX_NAME_LENGTH } from 'data/flow/generalConfigValidationData'
import { FlowConfig } from 'models/flow'
import { BackendLoading } from 'redux/redux.shared'
import { RootState } from 'redux/store'
import { FlowGeneralConfigState } from './FlowGeneralConfig.types'

const selectFlowGeneralConfigState = (state: RootState): FlowGeneralConfigState => state.flowConfig.general

export const selectFlowConfig = (state: RootState): FlowConfig => selectFlowGeneralConfigState(state).flowConfig

export const selectFlowId = (state: RootState): number | undefined => selectFlowConfig(state).id

export const selectFlowConfigValidity = (state: RootState): boolean => {
  const { name, description, command } = selectFlowConfig(state)
  const isNameValid = !!name && name.length <= MAX_NAME_LENGTH
  const isDescriptionValid = !!description && description.length <= MAX_DESCRIPTION_LENGTH
  const isCommandValid = !!command && command.length <= MAX_COMMAND_LENGTH
  return isNameValid && isDescriptionValid && isCommandValid
}

export const selectShouldCreateFlow = (state: RootState): boolean =>
  selectFlowGeneralConfigState(state).shouldCreateFlow

export const selectOriginalFlowName = (state: RootState): string | undefined =>
  selectFlowGeneralConfigState(state).originalName

export const selectCreatingFlowLoading = (state: RootState): BackendLoading =>
  selectFlowGeneralConfigState(state).loading.createFlow

export const selectUpdatingFlowLoading = (state: RootState): BackendLoading =>
  selectFlowGeneralConfigState(state).loading.updateFlow

export const selectGettingFlowLoading = (state: RootState): BackendLoading =>
  selectFlowGeneralConfigState(state).loading.getFlow

export const selectIsGeneralConfigContentLoading = (state: RootState): boolean =>
  selectCreatingFlowLoading(state).isLoading ||
  selectUpdatingFlowLoading(state).isLoading ||
  selectGettingFlowLoading(state).isLoading
