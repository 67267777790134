import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { persistStore } from 'redux-persist'
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from 'redux-persist/es/constants'
import { useDispatch } from 'react-redux'
import { createStateSyncMiddleware, initMessageListener } from 'redux-state-sync'
import { loginReducer, initialTokenRefresh } from './login'
import { cesiumViewerReducer } from './cesium-viewer'
import { accountActivationReducer } from './account-activation'
import mapReducer from './map'
import filesReducer from './files'
import { portfolioProjectsReducer } from './portfolio'
import projectReducer from './project'
import { passwordResetReducer } from './password'
import { notificationsReducer } from './notifications'
import administrationReducer from './administration'
import userProfileReducer from './user-profile'
import flowConfigReducer from './flow-conifg/FlowConfig.reducer'

const store = configureStore({
  reducer: {
    login: loginReducer,
    cesiumViewer: cesiumViewerReducer,
    accountActivation: accountActivationReducer,
    passwordReset: passwordResetReducer,
    map: mapReducer,
    files: filesReducer,
    portfolio: portfolioProjectsReducer,
    project: projectReducer,
    notifications: notificationsReducer,
    administration: administrationReducer,
    profile: userProfileReducer,
    flowConfig: flowConfigReducer,
  },
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }).concat(
    createStateSyncMiddleware({
      whitelist: ['login/loginUser/fulfilled', 'login/logoutUser/fulfilled', 'login/initialTokenRefresh/fulfilled'],
    })
  ),
})

initMessageListener(store)

export const persistor = persistStore(store, {}, () => store.dispatch(initialTokenRefresh()))

// Infer the `RootState` type from the store itself
export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch
export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>()

export default store
