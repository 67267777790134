import { t, Trans } from '@lingui/macro'
import BaseFormItem from 'components/molecules/form-item/BaseFormItem'
import FormSelect from 'components/molecules/form-item/FormSelect'
import cdeStatusDict from 'data/cdeStatus'
import { noChangesText } from 'data/messages/controls'
import trans from 'helpers/i18n.helpers'
import { CdeStatuses, cdeStatuses } from 'models/files'
import { useSelector } from 'react-redux'
import { selectFlowConditionsInitialCdeStatuses } from 'redux/flow-conifg/conditions/FlowConditionsConfig.selectors'

const CdeStatusCodesSelect: React.FC = () => {
  const initialCdeStatuses = useSelector(selectFlowConditionsInitialCdeStatuses)
  return (
    <BaseFormItem
      label={<Trans id="flow_config.consequences.cde_folder_label">Following CDE Folder</Trans>}
      tooltip={
        <Trans id="flow_config.consequences.cde_folder_tooltip">
          This CDE folder will be assigned to file after the flow is finished - must be adequate to the suitability
          code.
        </Trans>
      }
    >
      <FormSelect
        showSearch
        name="followingCdeStatus"
        placeholder={trans(noChangesText)}
        options={[
          { value: -1, label: trans(noChangesText) },
          ...cdeStatuses.map((status) => ({ label: trans(cdeStatusDict[status].text), value: status })),
        ]}
        hasFeedback
        rules={[
          ({ getFieldValue }) => ({
            validator() {
              const value = getFieldValue('followingCdeStatus')
              if (
                initialCdeStatuses !== undefined &&
                initialCdeStatuses.length !== 1 &&
                value !== -1 &&
                value !== CdeStatuses.ARCHIVED
              ) {
                return Promise.reject(
                  new Error(
                    t({
                      id: 'flow_config.consequences.cde_folder.not_match_with_initials',
                      message:
                        'The cde directory cannot be other than ARCHIVED or No Changes if no initial directories or more than one directory was selected',
                    })
                  )
                )
              }
              return Promise.resolve()
            },
          }),
        ]}
      />
    </BaseFormItem>
  )
}

export default CdeStatusCodesSelect
