import { Space, Spin } from 'antd'
import { useEffect, useMemo } from 'react'
import { fetchProjects } from 'redux/administration/administration-projects'

import { useAppDispatch } from 'redux/store'
import { useSelector } from 'react-redux'
import {
  selectCodeLengthModalVisibility,
  selectFetchingCodeLengthLoading,
} from 'redux/administration/administration-code-settings/AdministrationCodeSettings.selectors'
import { fetchCodeLength } from 'redux/administration/administration-code-settings'
import { CodeTypes } from 'redux/administration/administration-code-settings/AdministrationCodeSettings.types'
import {
  selectAdministrationProjects,
  selectFetchingActiveUsersLoading,
  selectFetchingProjectsLoading,
  selectIsProjectModalVisible,
} from 'redux/administration/administration-projects/AdministrationProjects.selectors'
import styled from 'styled-components'
import { fetchActiveUsers } from 'redux/administration/administration-projects/AdministrationProjects.slice'
import EditCodeLengthModal from 'components/organisms/administration/administration-content/modals/edit-code-length/EditCodeLengthModal'
import trans from 'helpers/i18n.helpers'
import { projectText } from 'data/messages/misc'
import ExpandableTable from 'components/molecules/table/ExpandableTable'
import { Project } from 'models/administration'
import { ProjectStatusType } from 'redux/administration/administration-projects/AdministrationProjects.types'
import { getProjectStatus } from 'helpers/Models.helpers'
import useAdministrationProjectsColumns from './table-columns/useAdministrationProjectsColumns'
import ProjectModal from './modals/ProjectModal'
import AdministrationProjectsAlerts from './AdministrationProjectsAlerts'

// eslint-disable-next-line react/jsx-props-no-spreading
const StyledTable = styled((props) => <ExpandableTable {...props} />)`
  width: 1300px;
  &&&& {
    td {
      &:nth-child(7) {
        text-align: center;
      }
      &:first-child {
        border-right: 0px;
      }
      &:last-child {
        padding-right: 10px;
      }
    }
  }
`

const typeValues: Record<ProjectStatusType, number> = {
  ACTIVE: 0,
  IN_CONFIG: 1,
  ARCHIVED: 2,
}

const sortByStatus: (p1: Project, p2: Project) => number = (p1, p2) => {
  return typeValues[getProjectStatus(p1)] - typeValues[getProjectStatus(p2)]
}

const AdministrationProjects: React.FC = () => {
  const dispatch = useAppDispatch()
  const { isLoading } = useSelector(selectFetchingProjectsLoading)
  const { isLoading: fetchingCodeLoading } = useSelector(selectFetchingCodeLengthLoading)
  const { isLoading: fetchingUsersLoading } = useSelector(selectFetchingActiveUsersLoading)
  const projects = useSelector(selectAdministrationProjects)
  const isModalVisible = useSelector(selectIsProjectModalVisible)
  useEffect(() => {
    dispatch(fetchProjects())
    dispatch(fetchCodeLength(CodeTypes.PROJECT_CODE))
    dispatch(fetchActiveUsers())
  }, [dispatch])
  const columns = useAdministrationProjectsColumns()
  const isCodeModalVisible = useSelector(selectCodeLengthModalVisibility)
  const sortedProjects = useMemo(() => [...projects].sort(sortByStatus), [projects])
  return (
    <Spin spinning={isLoading || fetchingCodeLoading || fetchingUsersLoading}>
      {!isLoading && !fetchingCodeLoading && !fetchingUsersLoading && (
        <Space direction="vertical" size="middle">
          <StyledTable
            columns={columns}
            dataSource={sortedProjects}
            bordered
            pagination={false}
            rowKey="id"
            scroll={{ y: 'calc(100vh - 310px)' }}
          />
          {isModalVisible && <ProjectModal />}
          <AdministrationProjectsAlerts />
          {isCodeModalVisible && (
            <EditCodeLengthModal
              codeType="PROJECT_CODE"
              data={projects}
              reloadDataFun={fetchProjects}
              nameFieldLabel={trans(projectText)}
            />
          )}
        </Space>
      )}
    </Spin>
  )
}

export default AdministrationProjects
