import { SelectProps, SelectValue } from 'antd/lib/select'
import { Select } from 'antd'
import CheckboxElement from 'components/molecules/checkbox'
import { selectText } from 'data/messages/controls'
import trans, { transIfDescriptorType } from 'helpers/i18n.helpers'
import styled from 'styled-components'
import themePxValueToNumber from 'helpers/Styles.helpers'
import Text from '../../text'

const StyledDiv = styled.div`
  background-color: ${(props) => props.theme.colors.primaryP01};
  ${({ theme }) => theme.mixins.flexCenter}
  padding: 0 ${({ theme }) => theme.sizes.xs};
  height: 32px;
  border-radius: ${({ theme }) => theme.sizes.xs};
  gap: ${({ theme }) => theme.sizes.xs};
  div {
    cursor: pointer;
  }
`

const StyledCheckbox = styled(CheckboxElement)`
  width: 100%;
  .ant-checkbox + span {
    display: inline-flex;
    max-width: calc(100% - ${({ theme }) => themePxValueToNumber(theme.sizes.xs)}px);
  }
`

type OptionVal = number | string

interface MultipleSelectWithTagProps {
  value?: OptionVal[]
  options?: SelectProps<OptionVal[]>['options']
  onChange?: (val: SelectValue) => void
  filterOption?: SelectProps<OptionVal[]>['filterOption']
  optionFilterProp?: SelectProps<OptionVal[]>['optionFilterProp']
  placeholder?: React.ReactNode
  dropdownRender?: SelectProps<OptionVal[]>['dropdownRender']
}

const Tag: React.FC<MultipleSelectWithTagProps> = ({ value, options, onChange, placeholder }) => {
  if (value?.length === 0) return <Text textStyle="regularText-small">{placeholder || trans(selectText)}</Text>
  const displayedText =
    value?.length === 1
      ? transIfDescriptorType(options?.find((option) => option.value === value[0])?.label)
      : `Wybrano 
    (${value?.length})`
  return (
    <StyledDiv>
      <Text textStyle="regularText-smallSemiBold" color="grayG07">
        {displayedText}
      </Text>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <div onClick={() => onChange?.([])}>
        <Text textStyle="regularText-smallSemiBold" color="grayG07">
          X
        </Text>
      </div>
    </StyledDiv>
  )
}

const StyledSelect = styled(Select)`
  &&& {
    .ant-select-selection-item {
      height: auto;
      border: none;
      background: transparent;
      max-width: 50%;
    }
    .ant-select-selector {
      flex-wrap: nowrap;
      padding-right: ${({ theme }) => theme.sizes.s};
    }
  }
`

const MultipleSelectWithTag: React.FC<MultipleSelectWithTagProps> = ({
  options,
  value,
  onChange,
  filterOption,
  optionFilterProp,
  placeholder,
  dropdownRender,
}) => {
  return (
    <StyledSelect
      maxTagCount={0}
      maxTagPlaceholder={<Tag value={value} options={options} onChange={onChange} placeholder={placeholder} />}
      mode="multiple"
      showSearch
      placeholder={
        <Text textStyle="regularText-small" color="grayG05">
          {placeholder || trans(selectText)}
        </Text>
      }
      onChange={onChange}
      value={value}
      filterOption={filterOption}
      optionFilterProp={optionFilterProp}
      dropdownRender={dropdownRender}
      size="large"
    >
      {options?.map((option) => (
        <Select.Option key={option.value} value={option?.value}>
          <StyledCheckbox noEvents checked={!!value?.find((v) => v === option.value)}>
            {transIfDescriptorType(option.label)}
          </StyledCheckbox>
        </Select.Option>
      ))}
    </StyledSelect>
  )
}

export default MultipleSelectWithTag
