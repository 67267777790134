import { useSelector } from 'react-redux'
import {
  selectAddingOrganizationLoading,
  selectOrganizationDisplayedInModal,
  selectUpdatingOrganizationLoading,
} from 'redux/administration/administration-organizations/AdministrationOrganizations.selectors'
import { MessageErrorAlert } from 'components/atoms/error-alert'
import { handleApiErrors } from 'helpers/HttpError.helpers'
import { defineMessage, t } from '@lingui/macro'
import { invalidCodeErrorMessage } from 'data/messages/misc'

const errorMessageDict = {
  'organization.exists': defineMessage({
    id: 'administration.organizations.code_exists_error',
    message: 'Organization with given code already exists.',
  }),
  'organization.invalid_code_length': defineMessage({
    id: 'administration.organizations.invalid_code_length_error',
    message: "Invalid organization's code length.",
  }),
  'codes.code_invalid': invalidCodeErrorMessage,
}

const OrganizationModalError: React.FC = () => {
  const organization = useSelector(selectOrganizationDisplayedInModal)
  const { error: updateError } = useSelector(selectUpdatingOrganizationLoading)
  const { error: addError } = useSelector(selectAddingOrganizationLoading)

  if (organization === undefined) {
    return addError ? (
      <MessageErrorAlert
        message={t({ id: 'administration.organizations.modal.add_failed', message: 'Could not add organization.' })}
        description={handleApiErrors(errorMessageDict, addError.errors)}
      />
    ) : null
  }
  return updateError ? (
    <MessageErrorAlert
      message={t({ id: 'administration.organizations.modal.update_failed', message: 'Could not update organization.' })}
      description={handleApiErrors(errorMessageDict, updateError.errors)}
    />
  ) : null
}

export default OrganizationModalError
