import { useSelector } from 'react-redux'
import { Modal, Form, Spin } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import ErrorAlert from 'components/atoms/error-alert'
import Text from 'components/atoms/text'
import Icon from 'components/atoms/icon'
import { useAppDispatch } from 'redux/store'
import { getAlertMessageDict } from 'helpers/HttpError.helpers'
import { Attribute } from 'models/administration'
import { HttpBackendError } from 'models/errors'
import {
  selectAddingAttributeLoading,
  selectModalAttribute,
  selectEditingAttributeLoading,
} from 'redux/administration/administration-attributes/AdministrationAttributes.selectors'
import { addAttribute, hideAttributeModal, editAttribute } from 'redux/administration/administration-attributes'
import { BackendLoading } from 'redux/redux.shared'
import { defineMessage, t } from '@lingui/macro'
import { invalidCodeErrorMessage } from 'data/messages/misc'
import { AttributeArgs } from 'redux/administration/administration-attributes/AdministrationAttributes.types'
import AttributeModalFooter from './AttributeModalFooter'
import AttributeModalForm from './AttributeModalForm'

interface ModalProps {
  visible: boolean
}

interface AttributeLoadingValues {
  text: string
  isLoading: boolean
  error?: HttpBackendError
  isEditingModal: boolean
}

const errorMessageDict = {
  'metadata.already_exists': defineMessage({
    id: 'administration.attributes.name_already_exists_error',
    message: 'Attribute with given name already exists',
  }),
  'metadata_values.currently_used': defineMessage({
    id: 'administration.attributes.cannot_delete_used_value_error',
    message: 'Value is used and cannot be deleted',
  }),
  'metadata_values.already_exists': defineMessage({
    id: 'administration.attributes.value_already_exists_error',
    message: 'Value with given name already exists',
  }),
  'metadata_values.code_already_exists': defineMessage({
    id: 'administration.attributes.value_code_already_exists_error',
    message: 'Value with given code already exists',
  }),
  'metadata_values.invalid_code_length': defineMessage({
    id: 'administration.attributes.invalid_value_code_length_error',
    message: "Invalid value's code length",
  }),
  'metadata.does_not_exist': defineMessage({
    id: 'administration.attributes.attribute_does_not_exist_error',
    message: 'Attribute does not exist',
  }),
  'codes.code_invalid': invalidCodeErrorMessage,
}

function getValues(
  editedAttribute: Attribute | undefined,
  editLoading: BackendLoading,
  addLoading: BackendLoading
): AttributeLoadingValues {
  const isEditingModal = editedAttribute !== undefined
  const isLoading: boolean = isEditingModal ? editLoading.isLoading : addLoading.isLoading
  const text: string = isEditingModal
    ? t({
        id: 'administration.attributes.edit_attribute_modal_header',
        message: 'Edit attribute',
      })
    : t({
        id: 'administration.attributes.create_attribute_modal_header',
        message: 'Create attribute',
      })
  const error = isEditingModal ? editLoading.error : addLoading.error
  return { isLoading, text, error, isEditingModal }
}

const AttributeModal: React.FC<ModalProps> = ({ visible }) => {
  const [form] = Form.useForm()
  const dispatch = useAppDispatch()
  const addLoading = useSelector(selectAddingAttributeLoading)
  const editLoading = useSelector(selectEditingAttributeLoading)
  const handleCancel = () => dispatch(hideAttributeModal())
  const editedAttribute = useSelector(selectModalAttribute)
  const attributeValues = getValues(editedAttribute, editLoading, addLoading)
  const handleSubmit = async () => {
    form
      .validateFields()
      .then(() => {
        if (editedAttribute === undefined) {
          const fields: Exclude<Attribute, 'id'> = form.getFieldsValue()
          if (fields.values === undefined) {
            fields.values = []
          }
          dispatch(addAttribute(fields))
        } else {
          const fieldsToUpdate: Partial<Exclude<Attribute, 'id'>> = form.getFieldsValue()
          const { name, projectValuesAllowed, valueCodeLength, values } = fieldsToUpdate
          const newData = {
            name,
            projectValuesAllowed,
            valueCodeLength,
            values: values?.map(({ id, code, value }) => ({ id, code, value })),
          } as AttributeArgs
          dispatch(
            editAttribute({
              attributeId: editedAttribute.id,
              attributeFieldsToUpdate: newData,
            })
          )
        }
      })
      .catch(() => {})
  }
  return (
    <Modal
      visible={visible}
      onCancel={handleCancel}
      width={1400}
      closeIcon={
        <Icon>
          <CloseOutlined />
        </Icon>
      }
      maskClosable={false}
      title={<Text textStyle="header-H2b">{attributeValues.text}</Text>}
      footer={
        <AttributeModalFooter
          handleSubmit={handleSubmit}
          isEditingModal={attributeValues.isEditingModal}
          addLoading={addLoading}
          editLoading={editLoading}
        />
      }
    >
      <Spin spinning={attributeValues.isLoading}>
        <AttributeModalForm form={form} attribute={editedAttribute} />
        {attributeValues.error !== undefined && (
          <ErrorAlert alertMessageDict={getAlertMessageDict(errorMessageDict, attributeValues.error)} />
        )}
      </Spin>
    </Modal>
  )
}

export default AttributeModal
