import { MemberOrganization } from 'models/project/projectConfig'
import Text from 'components/atoms/text'
import { useMemo } from 'react'
import styled from 'styled-components'
import { Trans } from '@lingui/macro'
import MultipleSelectWithTag from 'components/atoms/select-element/multiple-with-tag/MultipleSelectWithTag'

interface OrganizationFilterProps {
  organizations?: MemberOrganization[]
  onChange: (arg: number[]) => void
  selectedOrganizations: number[]
}

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 250px;
`

const organizationText = <Trans id="project_config.members.modal.organizations_filter">Organizations</Trans>
const OrganizationFilter: React.FC<OrganizationFilterProps> = ({ organizations, onChange, selectedOrganizations }) => {
  const options = useMemo(() => organizations?.map((org) => ({ value: org.id, label: org.abbreviation })), [
    organizations,
  ])
  return (
    <StyledDiv>
      <Text textStyle="regularText-small" color="baseBlackish">
        {organizationText}
      </Text>
      <MultipleSelectWithTag
        onChange={(val) => {
          onChange(val as number[])
        }}
        value={selectedOrganizations}
        filterOption={(val, option) => {
          return option?.children?.props.children.toLowerCase().includes(val.toLowerCase())
        }}
        placeholder={
          <Text textStyle="regularText-small" color="grayG06">
            {organizationText}
          </Text>
        }
        options={options}
      />
    </StyledDiv>
  )
}

export default OrganizationFilter
