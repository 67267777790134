import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { t } from '@lingui/macro'
import { Divider, Modal, Space } from 'antd'
import Icon from 'components/atoms/icon'
import TooltipElement from 'components/molecules/tooltip-element'
import { noText, yesText } from 'data/messages/misc'
import trans from 'helpers/i18n.helpers'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { deleteFlow } from 'redux/project/project-config/flow/ProjectFlowConfig.slice'
import { selectProjectId } from 'redux/project/project-details/ProjectDetails.selectors'
import { useAppDispatch } from 'redux/store'

interface FlowActionsProps {
  id: number
  name: string
}

const FlowActions: React.FC<FlowActionsProps> = ({ id, name }) => {
  const projectId = useSelector(selectProjectId) as number
  const dispatch = useAppDispatch()
  return (
    <Space>
      <TooltipElement title={t({ id: 'project_config.flow.edit_flow_tooltip', message: 'Edit process' })}>
        <Link to={`/flow-config/${projectId}/1/${id}`}>
          <Icon size="default" color="grayG07">
            <EditOutlined />
          </Icon>
        </Link>
      </TooltipElement>
      <Divider type="vertical" />
      <TooltipElement title={t({ id: 'project_config.flow.delete_flow_tooltip', message: 'Remove process' })}>
        <Icon
          size="default"
          color="grayG07"
          onClick={async () => {
            Modal.confirm({
              title: t({
                id: 'project_config.flow.delete_confirm_modal_title',
                message: `Do you want to delete ${name}?`,
              }),
              cancelText: trans(noText),
              okText: trans(yesText),
              onOk: async () => {
                await dispatch(deleteFlow({ projectId, flowId: id }))
              },
            })
          }}
        >
          <DeleteOutlined />
        </Icon>
      </TooltipElement>
    </Space>
  )
}

export default FlowActions
