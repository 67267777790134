import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { activateUser, checkActivationCode } from 'routes/external.routes'
import {
  combine,
  createAsyncBackendThunk,
  generateExtraBackendReducers,
  generateInitialLoadingState,
  BackendLoading,
} from '../redux.shared'

const loadingTypes = ['validateActivationCode', 'activateAccount'] as const
type LoadingTypes = typeof loadingTypes[number]

export interface ActivationState {
  loading: Record<LoadingTypes, BackendLoading>
  redirectPath?: string
  isValid: boolean
  tokenValidated: boolean
  accountActivated: boolean
  username?: string
  email?: string
}

export interface ValidationCodeResponse {
  isValid: boolean
  email?: string
  username?: string
}

export const validateActivationCode = createAsyncBackendThunk(
  'validateActivationCode',
  async ({ activationCode }: { activationCode: string }) => {
    const response = await axios.get(checkActivationCode(activationCode))
    return response.data
  }
)

export const activateAccount = createAsyncBackendThunk(
  'accountActivation/activateAccount',
  async ({ activationCode, password }: { activationCode: string; password: string }) => {
    const activateData = {
      activation_code: activationCode,
      password,
    }
    const response = await axios.post(activateUser, activateData)
    return response.data
  }
)

const accountActivationSlice = createSlice({
  name: 'accountActivation',
  initialState: {
    loading: generateInitialLoadingState<LoadingTypes>(loadingTypes),
    isValid: false,
    tokenValidated: false,
    accountActivated: false,
    username: undefined,
    email: undefined,
  } as ActivationState,
  reducers: {},
  extraReducers: combine([
    generateExtraBackendReducers<ActivationState, LoadingTypes, ValidationCodeResponse>({
      promise: validateActivationCode,
      loadingType: 'validateActivationCode',
      onRejected: (state) => {
        state.isValid = false
        state.username = undefined
        state.email = undefined
        state.tokenValidated = true
      },
      onFulfilled: (state, action) => {
        state.username = action.payload.username
        state.email = action.payload.email
        state.isValid = action.payload.isValid
        state.tokenValidated = true
      },
    }),
    generateExtraBackendReducers<ActivationState, LoadingTypes>({
      promise: activateAccount,
      loadingType: 'activateAccount',
      onFulfilled: (state) => {
        state.accountActivated = true
      },
    }),
  ]),
})

export const accountActivationReducer = accountActivationSlice.reducer
