import { Card } from 'antd'
import { useHistory, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectIsFlowModalVisible } from 'redux/files/flows/Flows.selectors'
import { Close } from '@material-ui/icons'
import styled from 'styled-components'
import Icon from 'components/atoms/icon'
import useSideDrawer from 'hooks/side-drawer/sideDrawer'
import trans from 'helpers/i18n.helpers'
import filesSideDrawerOptions from './FilesSideDrawerOptions'
import FlowExecModal from './content/documentation/exec-flow-modal/FlowExecModal'

interface StyledCardProps {
  $isVisible: boolean
}

const StyledCard = styled(Card)<StyledCardProps>`
  background-color: ${(props) => props.theme.colors.baseLight};
  box-shadow: ${(props) => props.theme.effects.mainShadow};
  width: ${(props) => `calc(100vw - ${props.theme.sizes.projectAsideWidth} * 2 - ${props.theme.sizes.default});`}
  position: absolute;
  top: 0;
  bottom: 0;
  left: ${(props) => props.theme.sizes.projectAsideWidth};
  transition: ${(props) => props.theme.transitions.default};
  ${(props) => (props.$isVisible ? '' : `transform: translateX(calc(-100% - ${props.theme.sizes.projectAsideWidth}))`)};
  z-index: 1;
  border-radius: 0 ${(props) => props.theme.sizes.xs} ${(props) => props.theme.sizes.xs} 0px;
  .ant-card-body {
    padding: 0;
    height: 100%;
  }
`

const StyledDiv = styled.div`
  position: absolute;
  top: ${(props) => props.theme.sizes.xs};
  right: ${(props) => props.theme.sizes.default};
  transition: ${(props) => props.theme.transitions.default};
  z-index: 5;
`

const FilesSideDrawer: React.FC = () => {
  const history = useHistory()
  const { pathname } = useLocation()
  const { isVisible, content } = useSideDrawer(
    'files',
    filesSideDrawerOptions.map(({ tooltip, ...rest }) => ({ tooltip: tooltip ? trans(tooltip) : undefined, ...rest }))
  )
  const isModalVisible = useSelector(selectIsFlowModalVisible)
  return (
    <StyledCard $isVisible={isVisible}>
      {content}
      <StyledDiv>
        <Icon onClick={() => history.replace(pathname)}>
          <Close />
        </Icon>
      </StyledDiv>
      {isModalVisible && <FlowExecModal />}
    </StyledCard>
  )
}

export default FilesSideDrawer
