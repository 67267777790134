import { useSelector, useDispatch } from 'react-redux'
import FormInput from 'components/molecules/form-item/FormInput'
import { Form } from 'antd'
import { selectIsAddingNextFile, selectNewEntryDescription } from 'redux/files/files-new-entry/FilesNewEntry.selectors'
import { setNewEntryDescription } from 'redux/files/files-new-entry'
import CheckboxElement from 'components/molecules/checkbox'
import styled from 'styled-components'
import { setAddingNextFile } from 'redux/files/files-new-entry/FilesNewEntry.slice'
import { t, Trans } from '@lingui/macro'
import StyledDiv from './AddEntry.styles'

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  margin-left: ${({ theme }) => theme.sizes.m};
  .ant-checkbox-wrapper {
    align-self: flex-start;
  }
`

const AddEntryDescription: React.FC = () => {
  const descriptionValue = useSelector(selectNewEntryDescription)
  const isAddingNextFile = useSelector(selectIsAddingNextFile)
  const dispatch = useDispatch()
  const onDescriptionChange: React.ChangeEventHandler<HTMLInputElement> = (e) =>
    dispatch(setNewEntryDescription(e.target.value))
  return (
    <StyledDiv>
      <StyledForm layout="vertical">
        <FormInput
          value={descriptionValue}
          onChange={onDescriptionChange}
          label={t({ id: 'project.files.add_file_modal.description_text', message: 'Description' })}
          placeholder={t({ id: 'project.files.add_file_modal.description_placeholder', message: 'Enter description' })}
        />
        {/* <FormSelect disabled label="Nazwa" placeholder="name" /> */}
        <CheckboxElement checked={isAddingNextFile} onChange={(e) => dispatch(setAddingNextFile(e.target.checked))}>
          <Trans id="project.files.add_file_modal.add_another_file_checkbox_text">Add another file</Trans>
        </CheckboxElement>
      </StyledForm>
    </StyledDiv>
  )
}

export default AddEntryDescription
