import { Menu } from 'antd'
import {
  ArrowRightOutlined,
  DeleteOutlined,
  DownloadOutlined,
  FormOutlined,
  LinkOutlined,
  PlayCircleOutlined,
  StopOutlined,
  SwapRightOutlined,
} from '@ant-design/icons'
import { Add } from '@material-ui/icons'
import { ReactComponent as FlowIcon } from 'assets/FlowIcon.svg'
import styled from 'styled-components'
import FilesEntryMenuItem from 'components/molecules/files/files-entry-menu/FilesEntryMenuItem'
import FilesEntrySubMenu from 'components/molecules/files/files-entry-menu/FilesEntrySubMenu'
import { FrontendFile, Permissions } from 'models/files'
import { showNewVersionPopover } from 'redux/files/files-new-version/FilesNewVersion.slice'
import { AppDispatch, useAppDispatch } from 'redux/store'
import { selectProjectFlows } from 'redux/files/flows/Flows.selectors'
import { downloadFile } from 'helpers/Files.helpers'
import {
  copyFileToInProgress,
  deleteEntry,
  deleteFile,
  moveToNextStatus,
  rejectFile,
  requestAcceptance,
} from 'helpers/CdeFlow.helpers'
import { itemArr } from 'helpers/Collection.helpers'
import { openDocumentationNotification } from 'helpers/Notifications.helpers'
import { deleteText } from 'data/messages/controls'
import trans from 'helpers/i18n.helpers'
import { t } from '@lingui/macro'
import { linkCopiedMessage } from 'data/messages/misc'
import { useSelector } from 'react-redux'
import { ProjectFlowsExecDetails } from 'models/flow'
import { setFlowModalVisibility } from 'redux/files/flows'

interface FilesEntryMenuProps {
  permissions: Permissions
  setVisible: React.Dispatch<React.SetStateAction<boolean>>
  frontendFile: FrontendFile
}

const StyledMenu = styled(Menu)`
  padding: 0;
  background-color: ${(props) => props.theme.colors.baseWhiteish};
`

interface MenuItemData {
  key: string
  text: string
  icon?: JSX.Element
  disabled: boolean
  onClick?: () => void
  submenuItems?: MenuItemData[]
  tooltip?: React.ReactNode
}

const createDownloadSubmenuItem = (frontendFile: FrontendFile) => {
  const downloadWithSystemNameItem = {
    key: 'download',
    text: t({ id: 'project.files.actions.download_with_system_name', message: 'With system name' }),
    icon: <DownloadOutlined />,
    disabled: frontendFile.type === 'DIR',
    onClick: () => frontendFile.fileId && downloadFile(frontendFile.fileId),
  }
  const downloadWithOriginalNameItem = {
    key: 'downloadOriginal',
    text: t({ id: 'project.files.actions.download_with_original_name', message: 'With original name' }),
    icon: <DownloadOutlined />,
    disabled: frontendFile.type === 'DIR',
    onClick: () => frontendFile.fileId && downloadFile(frontendFile.fileId, true),
  }

  return {
    key: 'download',
    text: t({ id: 'project.files.actions.download', message: 'Download' }),
    icon: <DownloadOutlined />,
    disabled: frontendFile.type === 'DIR',
    submenuItems: [downloadWithSystemNameItem, downloadWithOriginalNameItem],
  }
}
const createFlowExecuteSubmenuItem = (
  frontendFile: FrontendFile,
  flows: ProjectFlowsExecDetails[],
  dispatch: AppDispatch
) => {
  const isCurrentlyFlowRunning = frontendFile.currentFlow !== null
  const disableNewFlow = {
    key: 'disabledExec',
    text: t({
      id: 'project.files.actions.execute_disabled',
      message: 'One flow is currently running. Two flows cannot be executed simultaneously.',
    }),
    disabled: true,
  }
  const hasProjectFlows = frontendFile.flows.length > 0
  const projectText = hasProjectFlows
    ? t({ id: 'project.files.actions.execute_in_project', message: 'In project' })
    : t({ id: 'project.files.actions.no_project_flows_info', message: 'No available project flows' })
  const projectFlows =
    hasProjectFlows && !isCurrentlyFlowRunning
      ? flows
          .filter(({ id }) => frontendFile.flows.includes(id))
          .map((flow) => ({
            key: flow.id.toString(),
            text: flow.command,
            disabled: false,
            onClick: () => dispatch(setFlowModalVisibility({ isVisible: true, flow, frontendFile })),
          }))
      : []
  const executeInProject = {
    key: 'execProject',
    text: projectText,
    disabled: !hasProjectFlows,
    submenuItems: projectFlows,
  }
  // const executeInGroup = {
  //   key: 'execGroup',
  //   text: t({ id: 'project.files.actions.execute_in_group', message: 'In group' }),
  //   disabled: true,
  //   submenuItems: [],
  // }

  return {
    key: 'flowExec',
    text: t({ id: 'project.files.actions.execute_flow', message: 'Execute flow' }),
    icon: <FlowIcon />,
    disabled: false,
    submenuItems: [
      ...itemArr(executeInProject, !isCurrentlyFlowRunning),
      // ...itemArr(executeInGroup, !isCurrentlyFlowRunning),
      ...itemArr(disableNewFlow, isCurrentlyFlowRunning),
    ],
  }
}

const createDeleteMenuItem = (frontendFile: FrontendFile, permissions: Permissions, dispatch: AppDispatch) => {
  if (!frontendFile.isCurrent) {
    return {
      key: 'delete',
      text: trans(deleteText),
      icon: <DeleteOutlined />,
      disabled: !permissions.delete,
      onClick: () => frontendFile.fileId && deleteFile(frontendFile, dispatch),
    }
  }
  if (!permissions.deleteEntry && !permissions.delete) {
    return {
      key: 'delete',
      text: trans(deleteText),
      icon: <DeleteOutlined />,
      disabled: true,
    }
  }
  const deleteEntryItem = {
    key: 'deleteEntry',
    text: t({ id: 'project.files.actions.delete_entry', message: 'Delete file' }),
    icon: <DeleteOutlined />,
    disabled: !permissions.deleteEntry,
    onClick: () => frontendFile.entryId && deleteEntry(frontendFile, dispatch),
  }
  const deleteFileItem = {
    key: 'deleteFile',
    text: t({ id: 'project.files.actions.delete_revision', message: 'Delete revision' }),
    icon: <DeleteOutlined />,
    disabled: !permissions.delete,
    onClick: () => frontendFile.fileId && deleteFile(frontendFile, dispatch),
  }
  return {
    key: 'delete',
    text: trans(deleteText),
    icon: <DeleteOutlined />,
    disabled: false,
    submenuItems: [deleteEntryItem, deleteFileItem],
  }
}

const createStatusChangeMenuItem = (permissions: Permissions, dispatch: AppDispatch, frontendFile: FrontendFile) => {
  const requestAcceptanceItem = {
    key: 'requestAcceptance',
    text: t({ id: 'project.files.actions.request_acceptance', message: 'Request acceptance' }),
    icon: <PlayCircleOutlined />,
    disabled: !permissions.requestAcceptance,
    onClick: () => requestAcceptance(frontendFile, dispatch),
  }
  const changeStatusItem = {
    key: 'changeStatus',
    text: t({ id: 'project.files.actions.accept_file', message: 'Accept and change status' }),
    icon: <ArrowRightOutlined />,
    disabled: !permissions.changeStatusTo,
    onClick: () => moveToNextStatus(frontendFile, dispatch),
  }
  const reeditItem = {
    key: 'copyToInProgress',
    text: t({ id: 'project.files.actions.reedit', message: 'Copy to WIP' }),
    icon: <FormOutlined />,
    disabled: !permissions.reedit,
    onClick: () => copyFileToInProgress(frontendFile, dispatch),
  }
  const rejectFileItem = {
    key: 'rejectFile',
    text: t({ id: 'project.files.actions.reject_file', message: 'Reject' }),
    icon: <StopOutlined />,
    disabled: !permissions.changeStatusTo,
    onClick: () => rejectFile(frontendFile, dispatch),
  }

  const statusChangeSubmenuDisabled =
    !permissions.requestAcceptance && !permissions.changeStatusTo && !permissions.reedit

  return {
    key: 'statusChange',
    text: t({ id: 'project.files.actions.change_status', message: 'Change status' }),
    icon: <SwapRightOutlined />,
    disabled: statusChangeSubmenuDisabled,
    submenuItems: [requestAcceptanceItem, changeStatusItem, reeditItem, rejectFileItem],
  }
}

const createMenuData = (
  permissions: Permissions,
  dispatch: AppDispatch,
  frontendFile: FrontendFile,
  availableFlows: ProjectFlowsExecDetails[]
): MenuItemData[] => {
  const { isCurrent } = frontendFile

  // const previewItem = {
  //   key: 'search',
  //   text: t({ id: 'project.files.actions.preview', message: 'Preview' }),
  //   icon: <EyeOutlined />,
  //   disabled: false,
  //   onClick: undefined,
  // }
  // const showAllVersionsItem = {
  //   key: 'allVersions',
  //   text: t({ id: 'project.files.actions.show_previous_revisions', message: 'Show previous revisions' }),
  //   icon: <LibraryBooksOutlined />,
  //   disabled: false,
  //   onClick: undefined,
  // }
  const addNewVersion = {
    key: 'addNewVersion',
    text: t({ id: 'project.files.actions.add_new_revision', message: 'Add a new revision' }),
    icon: <Add />,
    disabled: !permissions.addNewVersion,
    onClick: () => dispatch(showNewVersionPopover(frontendFile)),
  }

  const copyLink = {
    key: 'copyLink',
    text: t({ id: 'project.files.actions.copy_link', message: 'Copy link to clipboard' }),
    icon: <LinkOutlined />,
    onClick: () => {
      const { origin, pathname } = window.location
      const query = `?sideDrawer=files&content=documentation&entryId=${frontendFile.entryId}`
      navigator.clipboard.writeText(`${origin}${pathname}${query}`).then(() => {
        openDocumentationNotification('success', {
          message: trans(linkCopiedMessage),
        })
      })
    },
    disabled: false,
  }

  const downloadSubmenuItem = createDownloadSubmenuItem(frontendFile)
  const statusChangeSubmenuItem = createStatusChangeMenuItem(permissions, dispatch, frontendFile)
  const deleteSubmenuItem = createDeleteMenuItem(frontendFile, permissions, dispatch)
  const executeFlowSubmenuItem = createFlowExecuteSubmenuItem(frontendFile, availableFlows, dispatch)

  return [
    executeFlowSubmenuItem,
    statusChangeSubmenuItem,
    copyLink,
    downloadSubmenuItem,
    ...itemArr(addNewVersion, isCurrent),
    deleteSubmenuItem,
    // previewItem,
    // ...itemArr(showAllVersionsItem, isCurrent),
  ]
}

const FilesEntryMenu: React.FC<FilesEntryMenuProps & React.ComponentProps<typeof Menu>> = ({
  permissions,
  setVisible,
  selectable,
  frontendFile,
}) => {
  const dispatch = useAppDispatch()
  const availableFlows = useSelector(selectProjectFlows)
  const menuItems = createMenuData(permissions, dispatch, frontendFile, availableFlows)
  const handleClick = () => {
    setVisible(false)
  }

  const mapMenuItemsToElements = (items: MenuItemData[]) => {
    return items.map(({ disabled, icon, key, onClick, submenuItems, text }) => {
      if (submenuItems && submenuItems.length > 0) {
        return (
          <FilesEntrySubMenu title={text} icon={icon} disabled={disabled} key={key}>
            {mapMenuItemsToElements(submenuItems)}
          </FilesEntrySubMenu>
        )
      }
      return <FilesEntryMenuItem key={key} onClick={onClick} icon={icon} text={text} disabled={disabled} />
    })
  }
  return (
    <StyledMenu selectable={selectable} onClick={handleClick}>
      {mapMenuItemsToElements(menuItems)}
    </StyledMenu>
  )
}

export default FilesEntryMenu
