import { ReactNode } from 'react'
import { Form, Steps } from 'antd'
import ButtonElement from 'components/atoms/button.legacy/Button'
import { stepText } from 'data/messages/controls'
import trans from 'helpers/i18n.helpers'
import selectAccountActivation from 'redux/account-activation/AccountActivation.selectors'
import { shallowEqual, useSelector } from 'react-redux'

interface ActivationFormProps {
  steps: ReactNode[]
  currentStep: number
  handleNext: () => void
  handleSubmit: () => void
  buttonTexts: string[]
}

const ActivationForm: React.FunctionComponent<ActivationFormProps> = ({
  steps,
  currentStep,
  handleNext,
  handleSubmit,
  buttonTexts,
}) => {
  const isSubmitStep = steps.length - 1 === currentStep
  const { Step } = Steps
  const { username, email } = useSelector(selectAccountActivation, shallowEqual)
  return (
    <Form
      layout="vertical"
      name="normal_login"
      className="login-form"
      onFinish={isSubmitStep ? handleSubmit : handleNext}
      initialValues={!!username && !!email ? { username, email } : {}}
    >
      <Steps size="small" current={currentStep} style={{ margin: '3rem 0' }}>
        {steps.map((singleStep, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Step key={index} title={`${trans(stepText)} ${index + 1}`} />
        ))}
      </Steps>

      {currentStep <= steps.length - 1 && (
        <>
          <Form.Item>{steps[currentStep]}</Form.Item>
          <Form.Item>
            <ButtonElement elementType="submit">{isSubmitStep ? buttonTexts[1] : buttonTexts[0]}</ButtonElement>
          </Form.Item>
        </>
      )}
    </Form>
  )
}

export default ActivationForm
