import FlowConfig from 'components/organisms/flow-config/FlowConfig'
import useProjectDetailsRedirection from 'hooks/projectDetailsRedirection'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { clearFlowConfigState } from 'redux/flow-conifg'
import { selectFlowId } from 'redux/flow-conifg/general/FlowGeneralConfig.selectors'
import { fetchFlowConfigFinishedSteps } from 'redux/flow-conifg/steps'
import { selectProjectId } from 'redux/project/project-details/ProjectDetails.selectors'

const FlowConfigPage: React.FC = () => {
  const dispatch = useDispatch()
  const projectId = useSelector(selectProjectId)
  const flowId = useSelector(selectFlowId)

  useProjectDetailsRedirection()

  useEffect(() => {
    if (projectId && flowId) {
      dispatch(fetchFlowConfigFinishedSteps({ flowId, projectId }))
    }
  }, [projectId, flowId, dispatch])

  useEffect(() => {
    return () => {
      dispatch(clearFlowConfigState())
    }
  }, [dispatch])
  return <FlowConfig />
}

export default FlowConfigPage
