import { Trans } from '@lingui/macro'
import BaseFormItem from 'components/molecules/form-item/BaseFormItem'
import RadioGroup from 'components/molecules/radio/RadioGroup'
import MembersSelectionTable from 'components/organisms/flow-config/form-items/members/MembersSelectionTable'
import trans from 'helpers/i18n.helpers'
import { FlowDecisionConfig, ReviewersUsersTypes, reviewersUsersTypes } from 'models/flow'
import { setDecisionFlowValues, setReviewUsersList } from 'redux/flow-conifg/decisions/FlowDecisionsConfig.slice'
import { useAppDispatch } from 'redux/store'
import { reviewersUsersTypesDict } from '../FlowDecision.helpers'

interface FlowGeneralConfigFormProps {
  flow: FlowDecisionConfig
}

const ReviewersUsersTypeRadio: React.FC<FlowGeneralConfigFormProps> = ({ flow }) => {
  const dispatch = useAppDispatch()
  return (
    <>
      <BaseFormItem
        label={<Trans id="flow_config.decisions.reviewers_users_type_radio_label">User who decides</Trans>}
        tooltip={
          <Trans id="flow_config.decisions.reviewers_users_type_radio_tooltip">
            User from selected group will give opinion about file.
          </Trans>
        }
      >
        <RadioGroup
          selectedValue={flow.reviewersUsersType}
          options={reviewersUsersTypes.map((value) => ({
            label: trans(reviewersUsersTypesDict[value]),
            value: value as string,
          }))}
          onChange={(e) => {
            if (e.target.value !== ReviewersUsersTypes.SELECTED) {
              dispatch(setDecisionFlowValues({ ...flow, reviewUsersList: [], reviewersUsersType: e.target.value }))
            } else {
              dispatch(setDecisionFlowValues({ ...flow, reviewersUsersType: e.target.value }))
            }
          }}
        />
      </BaseFormItem>
      {flow.reviewersUsersType === ReviewersUsersTypes.SELECTED && (
        <MembersSelectionTable initialSelection={flow.reviewUsersList} updateUsersFn={setReviewUsersList} />
      )}
    </>
  )
}

export default ReviewersUsersTypeRadio
