import { Trans } from '@lingui/macro'
import Title from 'antd/es/typography/Title'
import FormInput from 'components/molecules/form-item/FormInput'
import FormInputPasswordPanel from 'components/molecules/form-item/FormInputPasswordPanel'

interface ActivationStepProps {
  setPassword: (event: React.ChangeEvent<HTMLInputElement>) => void
  password: string
}

const ActivationSecondStep: React.FunctionComponent<ActivationStepProps> = ({ setPassword, password }) => {
  return (
    <>
      <Title level={4}>
        <Trans id="account_activation.set_password_header">Set password</Trans>
      </Title>
      <FormInput disabled label={<Trans id="account_activation.user.email_label">Email</Trans>} name="email" />
      <FormInput
        disabled
        label={<Trans id="account_activation.user.username_label">Name and surname</Trans>}
        name="username"
      />
      <FormInputPasswordPanel
        passwordLabel={<Trans id="account_activation.set_password_label">Password</Trans>}
        password={password}
        setPassword={setPassword}
      />
    </>
  )
}

export default ActivationSecondStep
