import { t, Trans } from '@lingui/macro'
import Title from 'antd/es/typography/Title'
import { required } from 'helpers/Validators.helpers'
import FormInput from '../form-item/FormInput'

interface ActivationStepProps {
  setCode: (event: React.ChangeEvent<HTMLInputElement>) => void
  code: string
}

const ActivationFirstStep: React.FunctionComponent<ActivationStepProps> = ({ setCode, code }) => {
  return (
    <>
      <Title level={4}>
        <Trans id="account_activation.check_activation_code_header">Check activation code</Trans>
      </Title>
      <FormInput
        label={<Trans id="account_activation.activation_code_label">Activation code (sent in an email message)</Trans>}
        placeholder={t({
          id: 'account_activation.activation_code_placeholder',
          message: 'Enter activation code',
        })}
        name="code"
        rules={[required()]}
        value={code}
        onChange={setCode}
      />
    </>
  )
}

export default ActivationFirstStep
