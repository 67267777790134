import ActivationSecondStep from 'components/molecules/activation-steps/ActivationSecondStep'

interface ActivationCodeStepProps {
  password: string
  setPassword: (value: string) => void
}

const SetPasswordStep: React.FunctionComponent<ActivationCodeStepProps> = ({ password, setPassword }) => {
  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)

  return <ActivationSecondStep password={password} setPassword={handlePasswordChange} />
}

export default SetPasswordStep
