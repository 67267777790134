import { Space, Spin } from 'antd'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'redux/store'
import {
  selectCodeLengthModalVisibility,
  selectFetchingCodeLengthLoading,
} from 'redux/administration/administration-code-settings/AdministrationCodeSettings.selectors'
import { fetchCodeLength } from 'redux/administration/administration-code-settings'
import {
  selectAdministrationSuitabilityCodes,
  selectAdministrationSuitabilityCodesLoading,
  selectSuitabilityCodeModalVisibility,
} from 'redux/administration/administration-suitability/AdministrationSuitability.selectors'
import { fetchSuitabilityCodes } from 'redux/administration/administration-suitability/AdministrationSuitability.slice'
import { CodeTypes } from 'redux/administration/administration-code-settings/AdministrationCodeSettings.types'
import Table from 'components/molecules/table'
import EditCodeLengthModal from 'components/organisms/administration/administration-content/modals/edit-code-length/EditCodeLengthModal'

import sortByStatus from 'helpers/Models.helpers'
import styled from 'styled-components'
import { t } from '@lingui/macro'
import AdministrationSuitabilityAlerts from './AdministrationSuitabilityAlerts'
import useAdministrationSuitabilityColumns from './useAdministrationSuitabilityColumns'
import AddingSuitabilityCodeModal from './modals/adding/AddingSuitabilityCodeModal'

const StyledTable = styled(Table)`
  width: 800px;
`

const AdministrationSuitability: React.FC = () => {
  const dispatch = useAppDispatch()
  const suitabilityCodes = useSelector(selectAdministrationSuitabilityCodes)
  const { isLoading: fetchingCodesLoading } = useSelector(selectAdministrationSuitabilityCodesLoading)
  const { isLoading: fetchingCodeLoading } = useSelector(selectFetchingCodeLengthLoading)
  const columns = useAdministrationSuitabilityColumns()
  useEffect(() => {
    dispatch(fetchSuitabilityCodes())
    dispatch(fetchCodeLength(CodeTypes.SUITABILITY_CODE_CODE))
  }, [dispatch])
  const isCodeModalVisible = useSelector(selectCodeLengthModalVisibility)
  const isModalVisible = useSelector(selectSuitabilityCodeModalVisibility)
  return (
    <Spin spinning={fetchingCodesLoading || fetchingCodeLoading}>
      {!fetchingCodesLoading && !fetchingCodeLoading && (
        <Space direction="vertical" size="middle">
          <StyledTable
            columns={columns}
            dataSource={[...suitabilityCodes].sort((c1, c2) => sortByStatus(c1.status, c2.status))}
            bordered
            pagination={false}
            rowKey="id"
            scroll={{ y: 'calc(100vh - 310px)' }}
          />
          <AdministrationSuitabilityAlerts />
          {isModalVisible && <AddingSuitabilityCodeModal />}
          {isCodeModalVisible && (
            <EditCodeLengthModal
              codeType="SUITABILITY_CODE_CODE"
              data={suitabilityCodes}
              reloadDataFun={fetchSuitabilityCodes}
              nameFieldLabel={t({
                id: 'administration.suitability.code_length_modal.name_field_label',
                message: 'Name',
              })}
            />
          )}
        </Space>
      )}
    </Spin>
  )
}

export default AdministrationSuitability
