import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { selectFlowId, selectGettingFlowLoading } from 'redux/flow-conifg/general/FlowGeneralConfig.selectors'
import { selectFlowConfigFinishedSteps } from 'redux/flow-conifg/steps'
import { selectFlowConfigFinishedStepsLoaded } from 'redux/flow-conifg/steps/FlowStepsConfig.selectors'
import { selectProjectId } from 'redux/project/project-details/ProjectDetails.selectors'
import flowConfigStepsData from './flowConfigSteps'

const useFlowConfigRedirection = (isFlowAdded: boolean, isStepIdxInvalid: boolean): void => {
  const history = useHistory()
  const finishedSteps = useSelector(selectFlowConfigFinishedSteps)
  const projectId = useSelector(selectProjectId)
  const flowId = useSelector(selectFlowId)
  const { error: gettingFlowError } = useSelector(selectGettingFlowLoading)
  const flowConfigFinishedStepsLoaded = useSelector(selectFlowConfigFinishedStepsLoaded)
  const shouldRedirect = useRef(true)

  useEffect(() => {
    if (projectId && flowId && flowConfigFinishedStepsLoaded && shouldRedirect.current) {
      const isFlowConfigurationCompleted = finishedSteps.length === flowConfigStepsData.length
      // redirect to newest step
      if (!isFlowConfigurationCompleted)
        history.replace(`/flow-config/${projectId}/${finishedSteps.length + 1}/${flowId}`)
      // redirect to first step if step is invalid and flow configuration completed
      else if (isStepIdxInvalid) history.replace(`/flow-config/${projectId}/1/${flowId}`)
      shouldRedirect.current = false
    }
  }, [history, finishedSteps, projectId, flowId, flowConfigFinishedStepsLoaded, isStepIdxInvalid])

  useEffect(() => {
    if (!isFlowAdded) {
      shouldRedirect.current = false
    }
  }, [isFlowAdded])

  useEffect(() => {
    if (gettingFlowError && projectId) {
      history.replace(`/flow-config/${projectId}/1`)
      shouldRedirect.current = false
    }
  }, [gettingFlowError, projectId, history])
}

export default useFlowConfigRedirection
