import { InfoCircleOutlined } from '@ant-design/icons'
import { Form } from 'antd'
import { FormItemProps } from 'antd/lib/form'
import Text from 'components/atoms/text'

export interface BaseFormItemProps {
  noMargin?: boolean
}

const BaseFormItem: React.FC<BaseFormItemProps & FormItemProps> = ({
  label,
  children,
  name,
  rules,
  validateStatus,
  help,
  noMargin = false,
  hasFeedback,
  tooltip,
  dependencies,
  valuePropName,
}) => (
  <Form.Item
    validateStatus={validateStatus}
    colon={false}
    dependencies={dependencies}
    name={name}
    hasFeedback={hasFeedback}
    tooltip={tooltip ? { title: tooltip, icon: <InfoCircleOutlined /> } : undefined}
    valuePropName={valuePropName}
    help={
      help && (
        <Text textStyle="regularText-small" color="grayG05">
          {help}
        </Text>
      )
    }
    rules={rules}
    style={{ marginBottom: noMargin ? 0 : 16 }}
    label={
      label && (
        <Text textStyle="regularText-small" color="grayG08">
          {label}
        </Text>
      )
    }
  >
    {children}
  </Form.Item>
)

export default BaseFormItem
