import styled from 'styled-components'
import Table from 'components/molecules/table'
import { useSelector } from 'react-redux'
import { selectAvailableReviewers } from 'redux/files/flows/Flows.selectors'
import { useAppDispatch } from 'redux/store'
import { setReviewer } from 'redux/files/flows'
import useReviewersSelectionColumns from './useReviewersSelectionColumns'

const StyledTable = styled(Table)`
  width: 600px;
`

const ReviewersTable: React.FC = () => {
  const dispatch = useAppDispatch()
  const reviewers = useSelector(selectAvailableReviewers)
  const columns = useReviewersSelectionColumns()
  const rowSelection = {
    type: 'radio',
    onChange: (selectedRowKeys: number[]) => dispatch(setReviewer(selectedRowKeys[0])),
  }
  return (
    <StyledTable
      columns={columns}
      dataSource={reviewers}
      bordered
      pagination={false}
      rowKey="id"
      rowSelection={rowSelection}
      scroll={{ y: '400px' }}
    />
  )
}

export default ReviewersTable
