import { t } from '@lingui/macro'
import BaseFormItem from 'components/molecules/form-item/BaseFormItem'
import FormInput from 'components/molecules/form-item/FormInput'
import { requiredAndNotBlank, valueNotIn } from 'helpers/Validators.helpers'
import DividerElement from 'components/atoms/divider'
import SchemeDetailsField from './details'

interface NamingSchemeModalFormProps {
  currentNames: string[]
  canSetAsNotDefault: boolean
}

const NamingSchemeModalForm: React.FC<NamingSchemeModalFormProps> = ({ currentNames, canSetAsNotDefault }) => {
  return (
    <>
      <div style={{ width: '400px' }}>
        <FormInput
          name="name"
          label={t({ id: 'administration.naming_schemes.modal.scheme_name_label', message: 'Scheme name' })}
          placeholder={t({
            id: 'administration.naming_schemes.modal.scheme_name_placeholder',
            message: 'Enter scheme name',
          })}
          rules={[
            ...requiredAndNotBlank(),
            valueNotIn(
              currentNames,
              t({
                id: 'administration.naming_schemes.modal.name_not_unique_error',
                message: 'This name already exists',
              })
            ),
          ]}
        />
      </div>
      <DividerElement />
      <BaseFormItem name="scheme">
        <SchemeDetailsField canSetAsNotDefault={canSetAsNotDefault} />
      </BaseFormItem>
    </>
  )
}

export default NamingSchemeModalForm
