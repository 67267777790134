import { t, Trans } from '@lingui/macro'
import { required, requireMinLength } from 'helpers/Validators.helpers'
import FormInputPassword from './FormInputPassword'

interface ActivationStepProps {
  passwordLabel: React.ReactNode
  setPassword?: (event: React.ChangeEvent<HTMLInputElement>) => void
  password?: string
}

const FormInputPasswordPanel: React.FunctionComponent<ActivationStepProps> = ({
  passwordLabel,
  setPassword,
  password,
}) => {
  return (
    <>
      <FormInputPassword
        label={passwordLabel}
        placeholder={t({ id: 'password_panel.set_password_placeholder', message: 'Enter password' })}
        name="password"
        rules={[
          required(),
          requireMinLength(
            8,
            t({
              id: 'password_panel.password.too_short',
              message: 'Minimal password length is 8 chars!',
            })
          ),
          () => ({
            validator(_, value) {
              if (!value || !value.match(/^[0-9]+$/)) {
                return Promise.resolve()
              }
              return Promise.reject(
                new Error(
                  t({
                    id: 'password_panel.password.only_numbers',
                    message: 'Password cannot contain only of digits!',
                  })
                )
              )
            },
          }),
        ]}
        value={password}
        onChange={setPassword}
      />
      <FormInputPassword
        name="confirm"
        label={<Trans id="password_panel.confirm_password_label">Confirm password</Trans>}
        dependencies={['password']}
        hasFeedback
        rules={[
          required(
            t({
              id: 'password_panel.confirm_password.required',
              message: 'Please confirm your password!',
            })
          ),
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve()
              }
              return Promise.reject(
                new Error(
                  t({
                    id: 'password_panel.confirm_password.not_mach',
                    message: 'The two passwords that you entered do not match!',
                  })
                )
              )
            },
          }),
        ]}
        placeholder={t({ id: 'password_panel.confirm_password.placeholder', message: 'Repeat password' })}
      />
    </>
  )
}

export default FormInputPasswordPanel
