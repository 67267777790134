import UserProfilePermissions from './user-profile-content/permissions'

const userProfileOptions: {
  component?: React.ReactNode
  key: string
  name: string
  items: {
    key: string
    name: string
    component?: React.ReactNode
    heading?: React.ReactNode
  }[]
}[] = [
  {
    name: 'Mój profil',
    key: 'profile',
    items: [
      {
        key: 'general',
        name: 'Ogólne',
        component: <div>Ogólne</div>,
      },
      {
        key: 'disciplines',
        name: 'Branże',
        component: <div>Branże</div>,
      },
    ],
  },
  {
    name: 'Ustawienia projektowe',
    key: 'projectSettings',
    items: [
      {
        key: 'permissions',
        name: 'Uprawnienia',
        component: <UserProfilePermissions />,
      },
      {
        key: 'groups',
        name: 'Grupy',
        component: <div>Grupy</div>,
      },
    ],
  },
]

export default userProfileOptions
