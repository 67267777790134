import { defineMessage } from '@lingui/macro'

export const uppercaseYesText = defineMessage({ id: 'common.misc.uppercase_yes_text', message: 'YES' })
export const yesText = defineMessage({ id: 'common.misc.yes_text', message: 'Yes' })
export const uppercaseNoText = defineMessage({ id: 'common.misc.uppercase_no_text', message: 'NO' })
export const noText = defineMessage({ id: 'common.misc.no_text', message: 'No' })

export const cdeDirectoryText = defineMessage({ id: 'common.misc.cde_directory_text', message: 'CDE directory' })
export const invalidCodeErrorMessage = defineMessage({
  id: 'common.misc.invalid_code_error',
  message: 'The provided code is invalid',
})
export const codeText = defineMessage({ id: 'common.misc.code_text', message: 'Code' })
export const codePlaceholder = defineMessage({ id: 'common.misc.code_placeholder', message: 'Enter code' })
export const revisionText = defineMessage({ id: 'common.misc.revision_text', message: 'Revision' })
export const acceptanceStatusText = defineMessage({ id: 'common.misc.acceptance_status', message: 'Acceptance status' })
export const documentationText = defineMessage({ id: 'common.misc.documentation_text', message: 'Documentation' })
export const disciplineText = defineMessage({ id: 'common.misc.discipline_text', message: 'Discipline' })
export const projectText = defineMessage({ id: 'common.misc.project_text', message: 'Project' })
export const attributeText = defineMessage({ id: 'common.misc.attribute_text', message: 'Attribute' })
export const notificationsText = defineMessage({ id: 'common.misc.notifications_text', message: 'Notifications' })
export const dateText = defineMessage({ id: 'common.misc.date_text', message: 'Date' })
export const suitabilityCodeText = defineMessage({
  id: 'common.misc.suitability_code_text',
  message: 'Suitability code',
})

export const linkCopiedMessage = defineMessage({
  id: 'common.misc.link_copied_message',
  message: 'Link has been copied',
})

export const inPreparationText = defineMessage({ id: 'common.misc.in_prepation', message: 'In preparation' })
