import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
  :root {
    --color-base-whiteish: #FCFCFD;
    --color-base-whiteish-rgb: 252, 252, 253;
    --color-base-blackish: #0E1125;
    --color-base-background: #F0F1F9;
    --color-base-light: #F9F9FB;
    --color-primary-P01: #DADDF1;
    --color-primary-P01-rgba: rgba(218, 221, 241, 0.4);
    --color-primary-P02: #B5BBE3;
    --color-primary-P02-rgba: rgba(181, 187, 227, 0.4);
    --color-primary-P03: #9099D5;
    --color-primary-P04: #4755B8;
    --color-primary-P05: #2A336F;
    --color-accent-A01: #D4B5E3;
    --color-accent-A02: #A96AC8;
    --color-accent-A03: #763795;
    --color-gray-G01: #F1F2F4;
    --color-gray-G02: #E3E3E8;
    --color-gray-G02-rgb: 213, 214, 221;
    --color-gray-G03: #C7C8D1;
    --color-gray-G04: #ABADBA;
    --color-gray-G05: #8F91A3;
    --color-gray-G06: #73768C;
    --color-gray-G07: #454754;
    --color-gray-G08: #2E2F38;
    --color-tooltip-icon-gray: #DADADA;
    --color-action-select: #DADDF1;
    --color-action-hover: #EDEEF8;
    --color-orange-6: #FA8C16;
    --color-yellow-6: #FADB14;
    --color-blue-6: #1890FF;
    --color-green-8: #237804;
    --color-gold-6: #FAAD14;
    --color-red-6: #F5222D;
    --color-cyan-6: #13C2C2;
    --color-blue-4: #69C0FF;
    --color-success: rgba(205, 235, 176, 0.7);
    --color-success-text: rgba(35, 120, 4, 0.8);
    --color-failure-text: rgba(245, 34, 45, 0.8);
    --color-warning: #FFEB3B;
    --size-xxxs: 2px;
    --color-volcano-5: #FF7A45;
    --color-table: #e3e3e8;
    --color-cde-on: #27AE60;
    --color-cde-request: #FF7A45;
    --size-xxs: 4px;
    --size-xs: 8px;
    --size-s: 12px;
    --size-default-s: 14px;
    --size-default: 16px;
    --size-default-m: 18px;
    --size-m: 24px;
    --size-l: 32px;
    --size-xl: 48px;
    --size-xxl: 72px;
    --size-mainNavbar-height: 60px;
    --size-projectNamePanel-height: 40px;
    --size-projectAside-width: 48px;
    --size-button-small: 24px;
    --size-button-medium: 32px;
    --size-button-large: 40px;
    --effects-main-shadow: drop-shadow(0px 0px 16px rgba(146, 153, 211, 0.15));
    --effects-leftPanel-shadow: 0px 4px 10px rgba(146, 153, 211, 0.15);
    --effects-menu-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
    --effects-drop-shadow: 0px 5px 15px 0px #9299D3 10%;
    --effects-elevation-1: 0px 2px 8px rgba(0, 0, 0, 0.15);
    --transition-default: 0.3s ease-out;
  }
`
