import { defineMessage } from '@lingui/macro'

export const userActiveText = defineMessage({
  id: 'administration.users.account_status.active',
  message: 'Account active',
})
export const userInactiveText = defineMessage({
  id: 'administration.users.account_status.inactive',
  message: 'Account inactive',
})
