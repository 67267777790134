import { Modal } from 'antd'
import axios from 'axios'
import { BACKEND_SERVER_URL } from '../environment/environment'

const backendAxios = axios.create({
  baseURL: BACKEND_SERVER_URL,
})

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const createErrorInterceptor: () => (error: any) => any = () => {
  let isModalVisible = false
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const errorInterceptor = (error: any) => {
    const { response } = error
    if (response.status === 423 && response.data?.detail === 'SYSTEM_BACKUP_IN_PROGRESS' && !isModalVisible) {
      isModalVisible = true
      Modal.error({
        title: 'Wystąpił błąd.',
        content: 'Trwa tworzenie backupu systemu. System działa tylko w trybie oczytu.',
        onOk: () => {
          isModalVisible = false
        },
      })
    }
    return Promise.reject(error)
  }
  return errorInterceptor
}

backendAxios.interceptors.response.use(undefined, createErrorInterceptor())

export default backendAxios
