import { DragIndicator } from '@material-ui/icons'
import { ControlOutlined } from '@ant-design/icons'
import MapSideDrawerDropdown from 'components/atoms/map/map-sidedrawer-dropdown'
import { TreeNode } from './MapSideDrawerTreeTypes'

export default (onDropdownClose: () => void) => (nodeData: TreeNode): JSX.Element => {
  if (nodeData.isLeaf && !nodeData.geodataLayers) {
    return (
      <>
        <DragIndicator className="icon icon--drag" /> {nodeData.title}
        <MapSideDrawerDropdown
          onClose={onDropdownClose}
          options={[<span>Przezroczystość</span>, <span>Priorytet wyświetlania</span>, <span>Wyizoluj warstwę</span>]}
        >
          <ControlOutlined className="icon icon--control" />
        </MapSideDrawerDropdown>
      </>
    )
  }
  return <>{nodeData.title}</>
}
