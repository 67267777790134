import { WarningOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import Text from 'components/atoms/text'
import Icon from 'components/atoms/icon'

const StyledDiv = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const CesiumViewerError: React.FC = () => {
  return (
    <StyledDiv>
      <Icon size="l">
        <WarningOutlined />
      </Icon>
      <Text textStyle="regularText-medium" color="grayG05">
        Nie udało się załadować mapy. Sprawdź czy twoja przeglądarka obsługuje WebGL i spróbuj ponownie.
      </Text>
    </StyledDiv>
  )
}

export default CesiumViewerError
