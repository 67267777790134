import { t } from '@lingui/macro'
import Button from 'components/molecules/button-element'
import { cancelText } from 'data/messages/controls'
import trans from 'helpers/i18n.helpers'

interface ModalProps {
  isCreateModal: boolean
  loading: boolean
  handleSubmit: () => Promise<void> | void
  handleCancel: () => void
  submitEnabled?: boolean
}

const ModalFooter: React.FC<ModalProps> = ({
  isCreateModal,
  loading,
  handleSubmit,
  handleCancel,
  submitEnabled = true,
}) => {
  const confirmButtonText = isCreateModal
    ? t({ id: 'administration.users.modal.add_button_text', message: 'Add' })
    : t({ id: 'administration.users.modal.save_button_text', message: 'Save' })
  return (
    <>
      <Button onClick={handleCancel} type="tertiary">
        {trans(cancelText)}
      </Button>
      <Button loading={loading} onClick={submitEnabled ? handleSubmit : undefined} disabled={!submitEnabled}>
        {confirmButtonText}
      </Button>
    </>
  )
}

export default ModalFooter
