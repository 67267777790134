import { useDispatch, useSelector } from 'react-redux'
import { Modal, Form, Spin, Alert } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import Text from 'components/atoms/text'
import Icon from 'components/atoms/icon'
import FormInput from 'components/molecules/form-item/FormInput'

import Button from 'components/molecules/button-element'
import { handleApiErrors } from 'helpers/HttpError.helpers'
import { requiredAndNotBlank } from 'helpers/Validators.helpers'
import { Discipline } from 'models/administration'
import { hideDisciplineModal } from 'redux/administration/administration-disciplines'
import {
  selectAddDisciplineLoading,
  selectEditDisciplineLoading,
  selectModalDiscipline,
} from 'redux/administration/administration-disciplines/AdministrationDisciplines.selectors'
import {
  addDiscipline,
  editDiscipline,
} from 'redux/administration/administration-disciplines/AdministrationDisciplines.slice'
import { BackendLoading } from 'redux/redux.shared'
import { ApiError } from 'models/errors'
import objectsDiff from 'helpers/Objects.helpers'
import { defineMessage, t, Trans } from '@lingui/macro'
import { addText, cancelText, errorText, saveText } from 'data/messages/controls'
import trans from 'helpers/i18n.helpers'
import CodeFormInput from 'components/molecules/code-length/CodeFormInput'
import { selectAdministrationCodeLengthDict } from 'redux/administration/administration-code-settings/AdministrationCodeSettings.selectors'
import { invalidCodeErrorMessage } from 'data/messages/misc'

interface ModalProps {
  visible: boolean
}

interface DisciplineLoadingValues {
  loading: boolean
  text: string
  errors: ApiError[] | undefined
}

const errorMessageDict = {
  'discipline.already_exists': defineMessage({
    id: 'administration.disciplines.name_already_exists_error',
    message: 'Discipline with given name already exists',
  }),
  'discipline.invalid_code_length': defineMessage({
    id: 'administration.disciplines.invalid_code_length_error',
    message: "Invalid discipline's code length",
  }),
  'discipline.code_already_exists': defineMessage({
    id: 'administration.disciplines.code_already_exists_error',
    message: 'Discipline with given code already exists',
  }),
  'codes.code_invalid': invalidCodeErrorMessage,
}

function getValues(
  editedDiscipline: Discipline | undefined,
  editLoading: BackendLoading,
  addLoading: BackendLoading
): DisciplineLoadingValues {
  const loading: boolean = editedDiscipline !== undefined ? editLoading.isLoading : addLoading.isLoading
  const text: string =
    editedDiscipline !== undefined
      ? t({ id: 'administration.disciplines.edit_discipline_modal_header', message: 'Edit discipline' })
      : t({ id: 'administration.disciplines.add_discipline_modal_header', message: 'Create discipline' })
  const errors = editLoading.error?.errors ?? addLoading.error?.errors
  return { loading, text, errors }
}

const DisciplineModal: React.FC<ModalProps> = ({ visible }) => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const addLoading = useSelector(selectAddDisciplineLoading)
  const editLoading = useSelector(selectEditDisciplineLoading)
  const handleCancel = () => dispatch(hideDisciplineModal())
  const editedDiscipline = useSelector(selectModalDiscipline)

  const disciplineValues = getValues(editedDiscipline, editLoading, addLoading)
  const currentLengthDict = useSelector(selectAdministrationCodeLengthDict)

  const handleSubmit = async () => {
    form.validateFields().then(() => {
      if (editedDiscipline === undefined) {
        const fields = form.getFieldsValue()
        return dispatch(addDiscipline(fields))
      }
      const formValues: Partial<Exclude<Discipline, 'id'>> = form.getFieldsValue()
      const disciplineData = {
        ...formValues,
      }
      return dispatch(
        editDiscipline({
          disciplineId: editedDiscipline.id,
          fieldsToUpdate: objectsDiff(editedDiscipline, disciplineData),
        })
      )
    })
  }
  const formFields = [
    {
      label: <Trans id="administration.disciplines.modal.name_field_label">Name</Trans>,
      name: 'name',
      placeholder: t({ id: 'administration.disciplines.modal.name_field_placeholder', message: 'Enter name' }),
    },
  ]
  return (
    <Modal
      visible={visible}
      onCancel={handleCancel}
      closeIcon={
        <Icon>
          <CloseOutlined />
        </Icon>
      }
      maskClosable={false}
      title={<Text textStyle="header-H2b">{disciplineValues.text}</Text>}
      footer={
        <>
          <Button onClick={handleCancel} type="tertiary">
            {trans(cancelText)}
          </Button>
          <Button loading={disciplineValues.loading} onClick={handleSubmit}>
            {trans(editedDiscipline !== undefined ? saveText : addText)}
          </Button>
        </>
      }
    >
      <Spin spinning={disciplineValues.loading}>
        <Form
          form={form}
          labelCol={{ sm: { span: 5 } }}
          initialValues={{
            code: editedDiscipline?.code.code,
            name: editedDiscipline?.name,
          }}
        >
          <CodeFormInput
            codeLength={currentLengthDict.DISCIPLINE_CODE}
            label={<Trans id="administration.disciplines.modal.code_field_label">Code</Trans>}
          />
          {formFields.map(({ label, name, placeholder }) => (
            <FormInput label={label} name={name} placeholder={placeholder} rules={requiredAndNotBlank()} />
          ))}
        </Form>
        {disciplineValues.errors !== undefined && (
          <Alert
            message={trans(errorText)}
            description={handleApiErrors(errorMessageDict, disciplineValues.errors)}
            type="error"
            showIcon
          />
        )}
      </Spin>
    </Modal>
  )
}

export default DisciplineModal
