import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { fetchProjectDetails } from 'redux/project/project-details'
import { selectIsProjectAdmin } from 'redux/project/project-details/ProjectDetails.selectors'
import { useAppDispatch } from 'redux/store'
import useProjectId from './useProjectId'

const useProjectDetailsRedirection = (): void => {
  const history = useHistory()
  const dispatch = useAppDispatch()
  const [detailsLoaded, setDetailsLoaded] = useState(false)
  const isProjectAdmin = useSelector(selectIsProjectAdmin)
  const projectId = useProjectId()
  useEffect(() => {
    if (detailsLoaded && isProjectAdmin === false) {
      history.replace('/')
    }
  }, [history, isProjectAdmin, detailsLoaded])
  useEffect(() => {
    const projectIdNumber = Number(projectId)
    if (projectId && !Number.isNaN(projectIdNumber)) {
      dispatch(fetchProjectDetails(projectIdNumber))
        .then(() => setDetailsLoaded(true))
        .catch(() => setDetailsLoaded(true))
    }
  }, [dispatch, projectId])
}

export default useProjectDetailsRedirection
