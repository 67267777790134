import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import backendAxios from 'axios/axios'
import {
  createAsyncBackendThunk,
  generateExtraBackendReducers,
  BackendLoading,
  generateInitialLoadingState,
  getStateAsAny,
} from 'redux/redux.shared'
import { EntryKey, FrontendFile, Group, ProjectEntries } from 'models/files'
import { openDocumentationNotification } from 'helpers/Notifications.helpers'
import { selectProjectId } from 'redux/project/project-details/ProjectDetails.selectors'
import cdeStatusDict from 'data/cdeStatus'
import { mapStringToRevision } from 'helpers/Files.helpers'
import { defineMessage, t } from '@lingui/macro'
import trans from 'helpers/i18n.helpers'
import { GroupDto, ProjectEntriesDto } from './FilesDocumentation.dto'
import { addNewlyAddedFileId, removeNewlyAddedFileId } from '../files-table'

const loadingTypes = ['fetchProjectEntries'] as const
type LoadingTypes = typeof loadingTypes[number]

export interface FilesDocumentationState {
  loading: Record<LoadingTypes, BackendLoading>
  projectEntries?: ProjectEntries
}

const noValueText = defineMessage({ id: 'project.files.no_value_directory_name', message: 'No value' })

export const fetchProjectEntries = createAsyncBackendThunk(
  'documentation/fetchProjectEntries',
  async ({ projectId, allVersions }: { projectId: number; allVersions: boolean }) => {
    const response = await backendAxios.post(
      `/projects/${projectId}/entries/grouped/`,
      { type: 'DEFAULT', grouping: null, skipMissingMetadata: true },
      { params: { all_versions: allVersions } }
    )
    const projectEntriesDto = response.data as ProjectEntriesDto
    const toProjectEntries = (entriesDto: ProjectEntriesDto): ProjectEntries => {
      const groupDtoToGroup = (key: EntryKey, group: GroupDto): Group => {
        const { value, entries, lastUpdated } = group
        const grouping = group.grouping ? toProjectEntries(group.grouping) : undefined
        return {
          value: {
            ...value,
            name: key !== 'status' ? value.name || trans(noValueText) : trans(cdeStatusDict[value.name].text),
          },
          grouping,
          lastUpdated,
          frontendFiles: entries?.flatMap(
            ({ id, discipline, shortName, description, docProperties, dirProperties }) => {
              if (dirProperties) {
                const { status, ...dp } = dirProperties
                return {
                  key: `DIR_${id}`,
                  discipline,
                  entryId: id,
                  shortName,
                  description,
                  type: 'DIR',
                  isCurrent: true,
                  cdeStatus: dirProperties.status,
                  ...dp,
                } as FrontendFile
              }
              if (docProperties) {
                return docProperties.versions.map((doc) => ({
                  key: `DOC_${doc.id}`,
                  discipline,
                  fileId: doc.id,
                  entryId: id,
                  shortName,
                  type: 'DOC',
                  isCurrent: doc.isCurrent,
                  isNewest: doc.isNewest,
                  creator: doc.creator,
                  size: doc.size,
                  revision: mapStringToRevision(doc.version),
                  uploadDate: doc.uploadDate,
                  permissions: doc.permissions,
                  cdeStatus: doc.status,
                  originalFilename: doc.originalFilename,
                  filename: doc.filename,
                  description,
                  numberOfVersions: docProperties.numberOfVersions,
                  acceptanceStatus: doc.acceptanceStatus,
                  suitabilityCode: doc.suitabilityCode,
                  flows: doc.flows,
                  currentFlow: doc.currentFlow,
                })) as FrontendFile[]
              }
              return []
            }
          ),
        }
      }
      return {
        key: entriesDto.key,
        groups: entriesDto.groups.map((group) => groupDtoToGroup(entriesDto.key, group)),
        value: entriesDto.value,
      }
    }
    return toProjectEntries(projectEntriesDto)
  }
)

export const refreshEntries = createAsyncThunk(
  'documentation/refreshEntries',
  async ({ addedFileId }: { addedFileId?: number }, { getState, dispatch }) => {
    const state = getStateAsAny(getState)
    const projectId = selectProjectId(state) as number
    await dispatch(fetchProjectEntries({ allVersions: state.files.controls.showAllVersions, projectId }))
      .then(() => {
        if (addedFileId) {
          dispatch(addNewlyAddedFileId(addedFileId))
          setTimeout(() => {
            dispatch(removeNewlyAddedFileId(addedFileId))
          }, 5000)
        }
      })
      .catch(() =>
        openDocumentationNotification('error', {
          message: t({ id: 'project.files.fetch_files_error', message: 'Fetching files failed' }),
          description: t({
            id: 'project.files.fetch_files_error_description',
            message: 'Try again or contact an administrator',
          }),
        })
      )
  }
)

const initialState = {
  loading: generateInitialLoadingState<LoadingTypes>(loadingTypes),
  checkedGroups: [],
  checkedFrontendFiles: [],
  newlyAddedFilesId: [],
} as FilesDocumentationState

const filesDocumentationSlice = createSlice({
  name: 'documentation',
  initialState,
  reducers: {},
  extraReducers: generateExtraBackendReducers<FilesDocumentationState, LoadingTypes, ProjectEntries>({
    promise: fetchProjectEntries,
    loadingType: 'fetchProjectEntries',
    onFulfilled: (state, action) => {
      state.projectEntries = action.payload
    },
  }),
})

export const filesDocumentationReducer = filesDocumentationSlice.reducer
