import { useCallback, useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Alert, Col, Row, Spin } from 'antd'
import Title from 'antd/es/typography/Title'
import ActivationForm from 'components/templates/activation/ActivationForm'
import ActivationCodeStep from 'components/organisms/activation-form/ActivationCodeStep'
import SetPasswordStep from 'components/organisms/activation-form/SetPasswordStep'
import { activateAccount, validateActivationCode } from 'redux/account-activation/AccountActivation.slice'
import { handleApiErrors } from 'helpers/HttpError.helpers'
import selectAccountActivation from 'redux/account-activation/AccountActivation.selectors'
import useActivationParams from 'hooks/query/activationParams'
import { Redirect, useHistory } from 'react-router'
import { t } from '@lingui/macro'
import { errorText } from 'data/messages/controls'
import trans from 'helpers/i18n.helpers'
import PASSWORD_ERROR_MESSAGES from 'data/messages/passwords'
import { selectIsAuthenticated } from 'redux/login/Login.selectors'
import showConfirmationModal from 'helpers/Modals.helpers'
import { logoutUser } from '../../../redux/login'

// eslint-disable-next-line max-lines-per-function
function Activation(): JSX.Element {
  const activationParams = useActivationParams()
  const history = useHistory()
  const [activationCode, setActivationCode] = useState<string>(
    activationParams?.activationCode ? activationParams.activationCode : ''
  )
  const [step, setStep] = useState<number>(activationParams?.activationCode ? 1 : 0)
  const [password, setPassword] = useState<string>('')
  const [redirectPath, setRedirectPath] = useState<string | undefined>(undefined)

  const { loading, isValid, tokenValidated, accountActivated, username, email } = useSelector(
    selectAccountActivation,
    shallowEqual
  )
  const isAuthenticated = useSelector(selectIsAuthenticated)
  const isLoading = loading.validateActivationCode.isLoading || loading.activateAccount.isLoading
  const dispatch = useDispatch()
  const handleActivationCodeCheck = useCallback(
    (code: string) => dispatch(validateActivationCode({ activationCode: code })),
    [dispatch]
  )

  const handleActivateUser = (code: string, passwd: string) =>
    dispatch(activateAccount({ activationCode: code, password: passwd }))

  useEffect(() => {
    if (accountActivated) {
      setTimeout(() => {
        setRedirectPath('/login')
      }, 3000)
    }
  }, [accountActivated, dispatch])

  useEffect(() => {
    if (isAuthenticated) {
      showConfirmationModal({
        title: t({ id: 'account_activation.logout_modal_title', message: 'You will be logged out' }),
        onOk: () => dispatch(logoutUser()),
        onCancel: () => history.replace('/'),
      })
    }
  }, [dispatch, isAuthenticated, history])

  useEffect(() => {
    if (step === 1 && activationCode && !tokenValidated) {
      handleActivationCodeCheck(activationCode)
    }
  }, [step, activationCode, tokenValidated, handleActivationCodeCheck])

  const handleNextStep = () => {
    if (step === 0) {
      handleActivationCodeCheck(activationCode)
    }

    if (tokenValidated && isValid && !loading.validateActivationCode.error && !loading.activateAccount.error) {
      setStep(step + 1)
    }
  }

  const handleSubmitForm = () => {
    handleActivateUser(activationCode, password)
  }

  const handleErrorMessage = () => {
    if (!isValid) {
      return t({ id: 'account_activation.invalid_activation_code_error', message: 'Invalid activation code' })
    }
    return handleApiErrors(
      PASSWORD_ERROR_MESSAGES,
      step === 0 ? loading.validateActivationCode.error?.errors : loading.activateAccount.error?.errors
    )
  }

  const handleSuccessMessage = () => {
    if (!(step === 1 && accountActivated) && !(step === 0 && isValid)) return null
    const description =
      step === 1 && accountActivated
        ? t({
            id: 'account_activation.password_set_message',
            message: "Password has been set. You'll be redirected to the login page.",
          })
        : t({
            id: 'account_activation.activation_code_valid_message',
            message: 'Activation code is valid. You may proceed to the next step.',
          })
    return <Alert message={description} type="success" showIcon />
  }

  return (
    <Row justify="center" align="middle" style={{ minHeight: '100vh' }}>
      <Col span={8} />
      <Col span={8}>
        {redirectPath !== undefined && <Redirect to={redirectPath} />}
        <Title level={2}>{t({ id: 'account_activation.header_text', message: 'Account activation' })}</Title>
        <Spin spinning={isLoading}>
          {(step === 0 || (username && email)) && (
            <ActivationForm
              steps={[
                <ActivationCodeStep code={activationCode} setCode={setActivationCode} />,
                <SetPasswordStep password={password} setPassword={setPassword} />,
              ]}
              currentStep={step}
              buttonTexts={[
                isValid
                  ? t({ id: 'account_activation.next_step_button_text', message: 'Next step' })
                  : t({
                      id: 'account_activation.check_activation_code_button_text',
                      message: 'Validate activation code',
                    }),
                t({ id: 'account_activation.set_password_button_text', message: 'Set password' }),
              ]}
              handleNext={handleNextStep}
              handleSubmit={handleSubmitForm}
            />
          )}
          {handleSuccessMessage()}
          {tokenValidated && (loading.activateAccount.error || !isValid) && (
            <Alert message={trans(errorText)} description={handleErrorMessage()} type="error" showIcon />
          )}
        </Spin>
      </Col>
      <Col span={8} />
    </Row>
  )
}

export default Activation
