import 'antd/dist/antd.less'
import { Spin } from 'antd'
import styled from 'styled-components'
import { BrowserRouter } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Routes from 'routes/routes'
import useAccessTokenForAxiosBackend from 'hooks/axios'
import { selectIsInitialTokenLoading } from 'redux/login/Login.selectors'
import useBackupWarnings from 'hooks/backupWarnings'

const StyledDiv = styled.div`
  height: 100vh;
  width: 100vw;
`

function App(): JSX.Element {
  const isLoading = useSelector(selectIsInitialTokenLoading)

  useAccessTokenForAxiosBackend()
  useBackupWarnings()

  return isLoading ? (
    <Spin size="large">
      <StyledDiv />
    </Spin>
  ) : (
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
  )
}

export default App
