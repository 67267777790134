import { Trans } from '@lingui/macro'
import BaseFormItem from 'components/molecules/form-item/BaseFormItem'
import RadioGroup from 'components/molecules/radio/RadioGroup'
import trans from 'helpers/i18n.helpers'
import { FlowDecisionConfig, reviewTypes } from 'models/flow'
import { setDecisionFlowValues } from 'redux/flow-conifg/decisions/FlowDecisionsConfig.slice'
import { useAppDispatch } from 'redux/store'
import { reviewTypesDict } from '../FlowDecision.helpers'

interface FlowGeneralConfigFormProps {
  flow: FlowDecisionConfig
}

const ReviewTypeRadio: React.FC<FlowGeneralConfigFormProps> = ({ flow }) => {
  const dispatch = useAppDispatch()
  return (
    <BaseFormItem label={<Trans id="flow_config.decisions.review_method_radio_label">Review method</Trans>}>
      <RadioGroup
        selectedValue={flow.reviewType}
        options={reviewTypes.map((value) => ({
          label: trans(reviewTypesDict[value]),
          value: value as string,
          disabled: true,
        }))}
        onChange={(e) => dispatch(setDecisionFlowValues({ ...flow, reviewType: e.target.value }))}
      />
    </BaseFormItem>
  )
}

export default ReviewTypeRadio
