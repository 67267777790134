import { Space } from 'antd'
import { generateStyledTableWithType } from 'helpers/Styles.helpers'
import { Discipline } from 'models/project/projectConfig'
import { useSelector } from 'react-redux'
import { selectSortedDisciplines } from 'redux/project/project-config/members-permissions/ProjectMembersPermissionsConfig.selectors'
import styled, { css } from 'styled-components'
import membersPermissionsColumns, { DisciplinePermissionRowProps } from './membersPermissionsColumns'

const tableMixin = css`
  .ant-table-thead > tr > th {
    text-align: center;
    font-size: ${(props) => props.theme.sizes.s};
    font-weight: ${(props) => props.theme.fontWeights.semiBold};
    border: 1px solid ${(props) => props.theme.colors.table};
  }
  .ant-table-body {
    overflow-y: auto !important;
  }
  td {
    border: 1px solid ${(props) => props.theme.colors.table};
  }
`

const Table = generateStyledTableWithType<DisciplinePermissionRowProps>(tableMixin)

const StyledTable = styled(Table)`
  width: 1100px;
`

const getTableData = (disciplines: Discipline[]) =>
  disciplines.map(({ id, name }) => ({ disciplineId: id, disciplineName: name, key: id }))

const MembersPermissionsTable: React.FC = () => {
  const disciplines = useSelector(selectSortedDisciplines)

  return (
    <Space direction="vertical" style={{ width: 1000 }}>
      <StyledTable
        columns={membersPermissionsColumns}
        dataSource={getTableData(disciplines)}
        pagination={false}
        scroll={{ y: 400 }}
      />
    </Space>
  )
}

export default MembersPermissionsTable
