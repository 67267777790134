import ActivationFirstStep from 'components/molecules/activation-steps/ActivationFirstStep'

interface ActivationCodeStepProps {
  code: string
  setCode: (value: string) => void
}

const ActivationCodeStep: React.FunctionComponent<ActivationCodeStepProps> = ({ code, setCode }) => {
  const handleActivationCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => setCode(e.target.value)

  return <ActivationFirstStep code={code} setCode={handleActivationCodeChange} />
}

export default ActivationCodeStep
