import { LockOutlined, UnlockOutlined } from '@ant-design/icons'
import { useSelector, useDispatch } from 'react-redux'
import { setIsScaleLocked } from 'redux/cesium-viewer/CesiumViewer.slice'
import styled from 'styled-components'
import { selectIsScaleLocked } from '../../../redux/cesium-viewer/CesiumViewer.selectors'
import TooltipElement from '../tooltip-element'

interface MapScalePanelItemProps {
  name: React.ReactNode
  value: React.ReactNode
}

const StyledDiv = styled.div`
  & :last-child {
    margin-left: 5px;
  }
`

const MapScalePanelItem: React.FC<MapScalePanelItemProps> = ({ name }) => {
  const dispatch = useDispatch()
  const isScaleLocked = useSelector(selectIsScaleLocked)

  const lockScale = () => dispatch(setIsScaleLocked(true))
  const unlockScale = () => dispatch(setIsScaleLocked(false))

  const tooltipText = isScaleLocked ? 'Odblokuj skalę' : 'Zablokuj skalę'
  const icon = isScaleLocked ? <LockOutlined onClick={unlockScale} /> : <UnlockOutlined onClick={lockScale} />
  return (
    <StyledDiv>
      {name} {/* value */}
      <TooltipElement placement="topRight" title={tooltipText}>
        {icon}
      </TooltipElement>
    </StyledDiv>
  )
}

export default MapScalePanelItem
