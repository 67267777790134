import { Trans } from '@lingui/macro'
import Text from 'components/atoms/text'
import styled from 'styled-components'
import ButtonElement from 'components/molecules/button-element'
import { useDispatch } from 'react-redux'
import { openSchemeModal } from 'redux/administration/administration-naming-scheme'
import { Space } from 'antd'

const StyledSpace = styled(Space)`
  margin-top: ${(props) => props.theme.sizes.xl};
  align-items: center;
`

const EmptyNamingSchemeTableContent: React.FC = () => {
  const dispatch = useDispatch()
  return (
    <StyledSpace size="large" direction="vertical">
      <Text textStyle="regularText-smallSemiBold" color="grayG06">
        <Trans id="administration.naming_schemes.empty_table.no_schemes_text">No scheme exist</Trans>
      </Text>
      <ButtonElement type="secondary" onClick={() => dispatch(openSchemeModal())}>
        <Trans id="administration.naming_schemes.empty_table.add_scheme_button_text">Add scheme</Trans>
      </ButtonElement>
    </StyledSpace>
  )
}

export default EmptyNamingSchemeTableContent
