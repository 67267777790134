import Table from 'components/molecules/table'
import { identity } from 'helpers/Functions.helpers'
import { Flow } from 'models/flow'
import { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { setPrevFlows } from 'redux/flow-conifg/general/FlowGeneralConfig.slice'
import usePrevFlowsTableColumns from './usePrevFlowsTableColumns'

interface PrevFlowsTableProps {
  prevFlows: Flow[]
  availableFlows: Required<Flow>[]
  currentFlowId?: number
}

const getTableDataSource = (flows: Required<Flow>[], currentFlowId?: number): (Required<Flow> & { key: number })[] =>
  flows.filter(({ id }) => id !== currentFlowId).map((flow) => ({ ...flow, key: flow.id }))

const PrevFlowsTable: React.FC<PrevFlowsTableProps> = ({ prevFlows, currentFlowId, availableFlows }) => {
  const columns = usePrevFlowsTableColumns()
  const dataSource = useMemo(() => getTableDataSource(availableFlows, currentFlowId), [availableFlows, currentFlowId])
  const dispatch = useDispatch()
  return (
    <Table
      columns={columns}
      dataSource={dataSource}
      pagination={false}
      rowSelection={{
        type: 'checkbox',
        onChange: (selectedRowKeys: number[]) => {
          dispatch(
            setPrevFlows(
              selectedRowKeys.map((key) => availableFlows.find(({ id }) => id === key)).filter(identity) as Flow[]
            )
          )
        },
        selectedRowKeys: prevFlows.map(({ id }) => id),
      }}
    />
  )
}

export default PrevFlowsTable
