import { User } from 'models/administration'
import { BackendLoading } from 'redux/redux.shared'

export type AccountActivityStatus = 'active' | 'inactive' | 'link_active' | 'link_expired' | 'link_deactivated'

export const AccountActivityStatuses: Record<string, AccountActivityStatus> = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  LINK_ACTIVE: 'link_active',
  LINK_EXPIRED: 'link_expired',
  LINK_DEACTIVATED: 'link_deactivated',
}

export const loadingTypes = [
  'fetchUsers',
  'editAccountActivationField',
  'editUserAccount',
  'addUser',
  'deactivateAccount',
  'reactivateAccount',
  'deactivateLink',
  'resendLink',
  'addUsersFromFile',
] as const
export type LoadingTypes = typeof loadingTypes[number]

export interface ModalData {
  isModalVisible: boolean
  user?: User
}

interface ValueDiff<T> {
  current: T
  inFile: T
}

export interface LoadUserError {
  email: string
  name: ValueDiff<string>
  surname: ValueDiff<string>
  admin: ValueDiff<boolean>
  disciplines: ValueDiff<string>
  organization: ValueDiff<string>
}

export interface FileModalData {
  isVisible: boolean
  isInfoVisible: boolean
  loadErrors: LoadUserError[]
}

export interface AdministrationUsersState {
  loading: Record<LoadingTypes, BackendLoading>
  users: User[]
  modalData: ModalData
  fileModalData: FileModalData
}

export interface NewUser {
  email: string
  firstName: string
  isAdmin: boolean
  lastName: string
  organization: number
  disciplines: number[]
}
