import { ColumnsType } from 'antd/lib/table'
import useTableColumnFiltering, { onFilterStringColumn } from 'components/molecules/table/useTableColumnFiltering'
import getAccountStatus from 'helpers/Users.helpers'
import { User } from 'models/administration'
import { AccountActivityStatuses } from 'redux/administration/administration-users/AdministrationUsers.types'
import { actionsTableColumnText } from 'data/messages/controls'
import { defineMessage, t } from '@lingui/macro'
import trans from 'helpers/i18n.helpers'
import { ExpandableTableColumnType } from 'components/molecules/table/ExpandableTable'
import { userActiveText, userInactiveText } from '../texts'
import ActionColumn from './ActionColumn'
import DisciplinesColumn from './DisciplinesColumn'
import StatusColumn from './StatusColumn'
import UserDisplay from './UserDisplay'

const accountActivityFilters = [
  { text: userActiveText, value: AccountActivityStatuses.ACTIVE },
  { text: userInactiveText, value: AccountActivityStatuses.INACTIVE },
  {
    text: defineMessage({
      id: 'administration.users.account_status.filters.activation_code_active',
      message: 'Activation code active',
    }),
    value: AccountActivityStatuses.LINK_ACTIVE,
  },
  {
    text: defineMessage({
      id: 'administration.users.account_status.filters.activation_code_invalidated',
      message: 'Activation code invalidated',
    }),
    value: AccountActivityStatuses.LINK_DEACTIVATED,
  },
  {
    text: defineMessage({
      id: 'administration.users.account_status.filters.activation_code_expired',
      message: 'Activation code expired',
    }),
    value: AccountActivityStatuses.LINK_EXPIRED,
  },
]

const useAdministrationUsersColumns = (): ColumnsType<User & ExpandableTableColumnType> => {
  return [
    {
      title: t({ id: 'administration.users.table.column_name_label', message: 'User' }),
      dataIndex: 'username',
      key: 'username',
      width: 300,
      render: (value, { firstName, lastName, email, isAdmin }) => (
        <UserDisplay name={firstName} surname={lastName} email={email} isAdmin={isAdmin} />
      ),
      ...useTableColumnFiltering(t({ id: 'administration.users.search_user_placeholder', message: 'Search for user' })),
      onFilter: (value: string | number | boolean, record: User) =>
        onFilterStringColumn(value, `${record.firstName} ${record.lastName} ${record.email}`),
    },
    {
      title: t({ id: 'administration.users.table.column_organization_label', message: 'Organization' }),
      dataIndex: 'org',
      key: 'org',
      width: 250,
      render: (value, { organization }) => organization.abbreviation,
      ...useTableColumnFiltering(
        t({ id: 'administration.users.search_organization_placeholder', message: 'Search for organization' })
      ),
      onFilter: (value: string | number | boolean, record: User) =>
        onFilterStringColumn(value, record.organization.abbreviation),
    },
    {
      title: t({ id: 'administration.users.table.column_disciplines_label', message: 'Disciplines' }),
      dataIndex: 'disciplines',
      key: 'disciplines',
      width: 250,
      render: (value, { disciplines, isTableRowExpanded }) => (
        <DisciplinesColumn disciplines={disciplines} isExpanded={!!isTableRowExpanded} />
      ),
      ...useTableColumnFiltering(
        t({ id: 'administration.users.search_discipline_placeholder', message: 'Search for discipline' })
      ),
      onFilter: (value: string | number | boolean, record: User) =>
        onFilterStringColumn(value, record.disciplines.map((d) => d.name).join(' ')),
    },
    {
      title: t({ id: 'administration.users.table.column_activity_status_label', message: 'Activity status' }),
      dataIndex: 'status',
      key: 'status',
      width: 200,
      render: (value, { activationDetails }) => <StatusColumn activationDetails={activationDetails} />,
      filters: accountActivityFilters.map(({ text, value }) => ({ value, text: trans(text) })),
      onFilter: (value, record) => getAccountStatus(record.activationDetails) === value,
    },
    {
      title: trans(actionsTableColumnText),
      dataIndex: 'actions',
      key: 'actions',
      render: (value, { activationDetails, id }) => <ActionColumn activationDetails={activationDetails} id={id} />,
    },
  ]
}

export default useAdministrationUsersColumns
