import { t, Trans } from '@lingui/macro'
import { Form, Space } from 'antd'
import BaseFormItem from 'components/molecules/form-item/BaseFormItem'
import FormInput from 'components/molecules/form-item/FormInput'
import FormTextArea from 'components/molecules/form-item/FormTextArea'
import YesNoRadioGroup from 'components/molecules/radio/YesNoRadioGroup'
import { MAX_COMMAND_LENGTH, MAX_DESCRIPTION_LENGTH, MAX_NAME_LENGTH } from 'data/flow/generalConfigValidationData'
import { maxStringLength, required } from 'helpers/Validators.helpers'
import { FlowConfig } from 'models/flow'
import { useDispatch } from 'react-redux'
import { setGeneralFlowValues } from 'redux/flow-conifg/general/FlowGeneralConfig.slice'
import styled from 'styled-components'

interface PrevFlowsTableVisibilityHandler {
  isPrevFlowsTableVisible: boolean
  setPrevFlowsTableVisibility: React.Dispatch<React.SetStateAction<boolean>>
}

interface FlowGeneralConfigFormProps extends PrevFlowsTableVisibilityHandler {
  flow: FlowConfig
  isEmptyFlowList: boolean
}

interface PrevFlowsRadiosProps extends PrevFlowsTableVisibilityHandler {
  isEmptyFlowList: boolean
}

const PrevFlowsRadios: React.FC<PrevFlowsRadiosProps> = ({
  isPrevFlowsTableVisible,
  setPrevFlowsTableVisibility,
  isEmptyFlowList,
}) => {
  return (
    <BaseFormItem
      label={
        <Trans id="flow_config.general.prev_flows_radio_label">Should other processes be performed beforehand?</Trans>
      }
      tooltip={
        <Trans id="flow_config.general.prev_flows_radio_tooltip">
          Running one of the selected processes will be required to make the currently defined process available for the
          selected file
        </Trans>
      }
    >
      <YesNoRadioGroup
        value={isEmptyFlowList ? false : isPrevFlowsTableVisible}
        onChange={(e) => setPrevFlowsTableVisibility(e.target.value)}
        disableYesRadio={isEmptyFlowList}
        disableNoRadio={isEmptyFlowList}
      />
    </BaseFormItem>
  )
}

const StyledSpace = styled(Space)`
  width: 428px;
`
const FlowGeneralConfigForm: React.FC<FlowGeneralConfigFormProps> = ({
  flow,
  isPrevFlowsTableVisible,
  setPrevFlowsTableVisibility,
  isEmptyFlowList,
}) => {
  const dispatch = useDispatch()
  return (
    <Form
      layout="vertical"
      initialValues={flow}
      onValuesChange={(changed, values) => dispatch(setGeneralFlowValues(values))}
    >
      <StyledSpace direction="vertical" size="large">
        <FormInput
          name="name"
          label={<Trans id="flow_config.general.input_name_label">Name</Trans>}
          placeholder={t({ id: 'flow_config.general.input_name_placeholder', message: 'Unique flow name' })}
          rules={[
            required(t({ id: 'flow_config.general.input_name_required_message', message: 'Input flow name' })),
            maxStringLength(
              MAX_NAME_LENGTH,
              t({
                id: 'flow_config.general.input_name_max_length_message',
                message: `The name can be up to ${MAX_NAME_LENGTH} characters long`,
              })
            ),
          ]}
          tooltip={
            <Trans id="flow_config.general.input_name_tooltip">
              The name can be up to {MAX_NAME_LENGTH} characters long and must be unique within the project
            </Trans>
          }
        />
        <FormTextArea
          name="description"
          label={<Trans id="flow_config.general.input_description_label">Description</Trans>}
          placeholder={t({
            id: 'flow_config.general.input_description_placeholder',
            message: 'Description for a given flow',
          })}
          autoSize={{ minRows: 4 }}
          style={{ resize: 'none' }}
          tooltip={
            <Trans id="flow_config.general.input_description_tooltip">
              The description can be up to {MAX_DESCRIPTION_LENGTH} characters long
            </Trans>
          }
          rules={[
            required(
              t({
                id: 'flow_config.general.input_description_required_message',
                message: 'Input flow description',
              })
            ),
            maxStringLength(
              MAX_DESCRIPTION_LENGTH,
              t({
                id: 'flow_config.general.input_description_max_length_message',
                message: `The description can be up to ${MAX_DESCRIPTION_LENGTH} characters long`,
              })
            ),
          ]}
        />
        <FormInput
          name="command"
          label={<Trans id="flow_config.general.input_command_label">Command</Trans>}
          placeholder={t({ id: 'flow_config.general.input_command_placeholder', message: 'Enter command' })}
          tooltip={
            <Trans id="flow_config.general.input_command_tooltip">
              The initialization command can be up to {MAX_COMMAND_LENGTH} characters long and does not have to be
              unique in the project
            </Trans>
          }
          rules={[
            required(
              t({
                id: 'flow_config.general.input_command_required_message',
                message: 'Input flow command',
              })
            ),
            maxStringLength(
              MAX_COMMAND_LENGTH,
              t({
                id: 'flow_config.general.input_command_max_length_message',
                message: `The flow command can be up to ${MAX_COMMAND_LENGTH} characters long`,
              })
            ),
          ]}
        />
        <PrevFlowsRadios
          isPrevFlowsTableVisible={isPrevFlowsTableVisible}
          setPrevFlowsTableVisibility={setPrevFlowsTableVisibility}
          isEmptyFlowList={isEmptyFlowList}
        />
      </StyledSpace>
    </Form>
  )
}

export default FlowGeneralConfigForm
