import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import backendAxios from 'axios/axios'
import { Flow, FlowConfig } from 'models/flow'
import {
  combine,
  createAsyncBackendThunk,
  generateExtraBackendReducers,
  generateInitialLoadingState,
  getStateAsAny,
} from 'redux/redux.shared'
import { selectFlowConfig, selectOriginalFlowName } from './FlowGeneralConfig.selectors'
import { FlowGeneralConfigState, loadingType, LoadingType } from './FlowGeneralConfig.types'

const getFlowForRequest = (flowConfig: FlowConfig): Flow & { prevFlows: number[]; projectId?: number } => ({
  ...flowConfig,
  prevFlows: flowConfig.prevFlows.map(({ id }) => id as number),
})

export const createFlow = createAsyncBackendThunk(
  'flowGeneralConfig/createFlow',
  async (
    { projectId, replaceHistory }: { projectId: number; replaceHistory: (flowId: number) => void },
    { getState }
  ) => {
    const state = getStateAsAny(getState)
    const flowConfig: FlowConfig = selectFlowConfig(state)
    const response = (await backendAxios.post(`projects/${projectId}/flows/`, getFlowForRequest(flowConfig))).data
    replaceHistory(response.id)
    return response
  }
)

export const getFlow = createAsyncBackendThunk(
  'flowGeneralConfig/getFlow',
  async ({ projectId, flowId }: { projectId: number; flowId: number }) => {
    return (await backendAxios.get(`/projects/${projectId}/flows/config/${flowId}/basics/`)).data
  }
)

export const updateFlow = createAsyncBackendThunk(
  'flowGeneralConfig/update',
  async (projectId: number, { getState }) => {
    const state = getStateAsAny(getState)
    const flowConfig = selectFlowConfig(state)
    const originalFlowName = selectOriginalFlowName(state)
    await backendAxios.patch(`/projects/${projectId}/flows/config/${flowConfig.id}/basics/`, {
      ...getFlowForRequest(flowConfig),
      name: originalFlowName === flowConfig.name ? undefined : flowConfig.name,
    })
  }
)

const emptyFlow = {
  name: '',
  description: '',
  command: '',
  prevFlows: [],
}

const initialState: FlowGeneralConfigState = {
  loading: generateInitialLoadingState<LoadingType>(loadingType),
  flowConfig: emptyFlow,
  shouldCreateFlow: true,
}

const flowGeneralConfigSlice = createSlice({
  name: 'flowGeneralConfig',
  initialState,
  reducers: {
    setGeneralFlowValues: (state, action: PayloadAction<Omit<FlowConfig, 'prevFlows'>>) => {
      state.flowConfig = { ...state.flowConfig, ...action.payload }
    },
    setPrevFlows: (state, action: PayloadAction<Flow[]>) => {
      state.flowConfig.prevFlows = action.payload
    },
  },
  extraReducers: combine([
    generateExtraBackendReducers<FlowGeneralConfigState, LoadingType, FlowConfig>({
      promise: createFlow,
      loadingType: 'createFlow',
      onFulfilled: (state, action) => {
        state.flowConfig = action.payload
        state.shouldCreateFlow = false
        state.originalName = action.payload.name
      },
    }),
    generateExtraBackendReducers<FlowGeneralConfigState, LoadingType, FlowConfig>({
      promise: getFlow,
      loadingType: 'getFlow',
      onFulfilled: (state, action) => {
        state.flowConfig = action.payload
        state.shouldCreateFlow = false
        state.originalName = action.payload.name
      },
    }),
    generateExtraBackendReducers<FlowGeneralConfigState, LoadingType>({
      promise: updateFlow,
      loadingType: 'updateFlow',
    }),
  ]),
})

export const { setGeneralFlowValues, setPrevFlows } = flowGeneralConfigSlice.actions

export const flowGeneralConfigReducer = flowGeneralConfigSlice.reducer
