import { t, Trans } from '@lingui/macro'
import { Form, Input } from 'antd'
import Text from 'components/atoms/text'
import { codeFormat, required, requiredLength } from 'helpers/Validators.helpers'
import styled from 'styled-components'

const StyledDiv = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  max-height: 500px;
  overflow-y: scroll;
  > :not(:nth-child(3n + 3)) {
    margin-right: ${({ theme }) => theme.sizes.xs};
    border-right: 1px solid ${(props) => props.theme.colors.grayG03};
  }
`
interface CodeItemsFieldSetProps {
  codeLength: number
  onCodeChange?: () => void
  nameLabel: string
}

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 0;
  padding-bottom: ${(props) => props.theme.sizes.xxxs};
`

const StyledCodeItem = styled(Form.Item)`
  display: inline-block;
  width: calc(30% - 8px);
  min-height: 100%;
  > .ant-form-item-control > :nth-child(2) {
    margin-right: -50px;
  }
`

const CodeItemsFieldSet: React.FC<CodeItemsFieldSetProps> = ({ codeLength, onCodeChange, nameLabel }) => {
  return (
    <Form.List name="values">
      {(fields) => (
        <StyledDiv>
          {fields.map((field, index) => {
            const isFirstInRow = index < 3
            return (
              <StyledFormItem required={false} colon={false} key={field.key}>
                <StyledCodeItem
                  name={[field.name, 'code', 'code']}
                  validateFirst
                  rules={[
                    required(
                      t({ id: 'administration.common.codes.modal.code_required_error', message: 'Missing code' })
                    ),
                    codeFormat(),
                    requiredLength(
                      codeLength,
                      t({
                        id: 'administration.common.codes.modal.code_invalid_length_error',
                        message: `Required length: ${codeLength}`,
                      })
                    ),
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        if (getFieldValue('values').filter((val: any) => val?.code?.code === value).length !== 1) {
                          return Promise.reject(
                            t({
                              id: 'administration.common.codes.modal.code_not_unique_error',
                              message: 'Code not unique',
                            })
                          )
                        }
                        return Promise.resolve()
                      },
                    }),
                  ]}
                  label={
                    isFirstInRow ? (
                      <Text textStyle="regularText-smallSemiBold">
                        <Trans id="administration.common.codes.modal.code_label">Code</Trans>
                      </Text>
                    ) : undefined
                  }
                  fieldKey={[field.fieldKey, 'code', 'code']}
                >
                  <Input
                    onChange={onCodeChange}
                    placeholder={t({ id: 'administration.common.codes.modal.code_placeholder', message: 'Code' })}
                  />
                </StyledCodeItem>
                <Form.Item
                  name={[field.name, 'name']}
                  style={{ display: 'inline-block', width: 'calc(70% - 8px)', margin: '0 8px' }}
                  fieldKey={[field.fieldKey, 'value']}
                  label={isFirstInRow ? <Text textStyle="regularText-smallSemiBold">{nameLabel}</Text> : undefined}
                >
                  <Input
                    placeholder={t({ id: 'administration.common.codes.modal.name_placeholder', message: 'Name' })}
                    disabled
                  />
                </Form.Item>
              </StyledFormItem>
            )
          })}
        </StyledDiv>
      )}
    </Form.List>
  )
}

export default CodeItemsFieldSet
