import { defineMessage } from '@lingui/macro'
import { MessageDescriptor } from '@lingui/core'

const notificationNameDict: { [key: string]: MessageDescriptor } = {
  ENTRY_CREATED: defineMessage({ id: 'notifications.types.entry_created', message: 'New file added' }),
  ENTRY_CREATED_AUDITOR: defineMessage({ id: 'notifications.types.entry_created_auditor', message: 'New file added' }),
  FILE_CREATED: defineMessage({ id: 'notifications.types.file_created', message: 'New file revision added' }),
  FILE_CREATED_AUDITOR: defineMessage({
    id: 'notifications.types.file_created_auditor',
    message: 'New file revision added',
  }),
  FILE_CREATED_ENTRY_CREATOR: defineMessage({
    id: 'notifications.types.file_created_entry_creator',
    message: 'New file revision added',
  }),
  FILE_COMMENTED: defineMessage({
    id: 'notifications.types.file_commented',
    message: 'New comment added to file',
  }),
  ENTRY_COMMENTED: defineMessage({
    id: 'notifications.types.entry_commented',
    message: 'New comment added to file',
  }),
  FILE_CONVERSION_REQUESTED: defineMessage({
    id: 'notifications.types.file_conversion_requested',
    message: 'New file conversion request',
  }),
  FILE_CONVERTED: defineMessage({
    id: 'notifications.types.file_converted',
    message: 'File converted to geodata',
  }),
  FILE_CONVERSION_REJECTED: defineMessage({
    id: 'notifications.types.file_conversion_rejected',
    message: 'File conversion request rejected',
  }),
  FILE_REVIEW_REQUESTED: defineMessage({
    id: 'notifications.types.file_review_requested',
    message: 'File acceptance request created',
  }),
  FILE_ACCEPTANCE_REQUEST_ACCEPTED: defineMessage({
    id: 'notifications.types.file_acceptance_request_accepted',
    message: 'File acceptance request accepted',
  }),
  FILE_ACCEPTANCE_REQUEST_REJECTED: defineMessage({
    id: 'notifications.types.file_acceptance_request_rejected',
    message: 'File acceptance request rejected',
  }),
  FILE_REVIEW_REQUEST_REMOVED: defineMessage({
    id: 'notifications.types.file_acceptance_request_removed',
    message: 'File acceptance request removed',
  }),
  ENTRY_REVIEW_REQUESTED: defineMessage({
    id: 'notifications.types.entry_review_requested',
    message: 'File acceptance request created',
  }),
  ENTRY_ACCEPTED: defineMessage({
    id: 'notifications.types.entry_accepted',
    message: 'File acceptance request accepted',
  }),
  ENTRY_REJECTED: defineMessage({
    id: 'notifications.types.entry_rejected',
    message: 'File acceptance request rejected',
  }),
  ENTRY_REVIEW_REQUEST_REMOVED: defineMessage({
    id: 'notifications.types.entry_acceptance_request_removed',
    message: 'File acceptance request removed',
  }),
  ENTRY_STATUS_CHANGED: defineMessage({
    id: 'notifications.types.entry_status_changed',
    message: "File's directory changed",
  }),
  FILE_IN_ENTRY_ACCEPTANCE_STATUS_CHANGED: defineMessage({
    id: 'notifications.types.file_acceptance_status_changed',
    message: "File's acceptance status changed",
  }),
  FILE_CONVERSION_TASK_FINISHED: defineMessage({
    id: 'notifications.types.file_conversion_finished',
    message: 'File converted to geodata',
  }),
  BACKUP_CREATED: defineMessage({
    id: 'notifications.types.backup_created',
    message: 'Backup created',
  }),
  BACKUP_CREATION_FAILED: defineMessage({
    id: 'notifications.types.backup_creation_failed',
    message: 'Backup creation failed',
  }),
  FLOW_REVIEW_REQUESTED_FOR_REVIEWER: defineMessage({
    id: 'notifications.types.flow_review_requested',
    message: 'File review requested',
  }),
}

export default notificationNameDict
