import { ActionCreatorWithPayload } from '@reduxjs/toolkit'
import Table from 'components/molecules/table'
import { useDispatch, useSelector } from 'react-redux'
import { selectMembersConfig } from 'redux/flow-conifg/decisions/FlowDecisionsConfig.selectors'
import styled from 'styled-components'
import useMembersSelectionColumns from './useMembersSelectionColumns'

interface FlowGeneralConfigFormProps {
  initialSelection: number[]
  updateUsersFn: ActionCreatorWithPayload<number[]>
}

const StyledTable = styled(Table)`
  width: 450px;
`

const MembersSelectionTable: React.FC<FlowGeneralConfigFormProps> = ({ initialSelection, updateUsersFn }) => {
  const dispatch = useDispatch()
  const members = useSelector(selectMembersConfig)
  const columns = useMembersSelectionColumns()

  const rowSelection = {
    selectedRowKeys: initialSelection,
    onChange: (selectedRowKeys: number[]) => dispatch(updateUsersFn(selectedRowKeys)),
  }
  return (
    <>
      <StyledTable
        columns={columns}
        dataSource={members}
        bordered
        pagination={false}
        rowKey="id"
        rowSelection={rowSelection}
        scroll={{ y: '400px' }}
      />
    </>
  )
}

export default MembersSelectionTable
