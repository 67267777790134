import { MessageDescriptor } from '@lingui/core'
import React from 'react'
import { t } from '@lingui/macro'

const trans = (message: MessageDescriptor): string => t({ id: message.id })

export const transIfDescriptorType = (message?: React.ReactNode | MessageDescriptor): React.ReactNode =>
  typeof message === 'object' && !React.isValidElement(message) ? trans(message as MessageDescriptor) : message

export default trans
