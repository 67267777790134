import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import backendAxios from 'axios/axios'
import { createAsyncBackendThunk, generateExtraBackendReducers, combine, getStateAsAny } from 'redux/redux.shared'
import { selectProjectId } from 'redux/project/project-details/ProjectDetails.selectors'
import { Discipline, LoadingTypes, initialState, ProjectDisciplinesConfigState } from './ProjectDisciplinesConfig.types'
import { addProjectConfigFinishedStep } from '../status/ProjectStatusConfig.slice'
import { selectSelectedDisciplinesIds } from './ProjectDisciplinesConfig.selectors'

interface FetchedDisciplines {
  selectedDisciplines: Discipline[]
  allDisciplines: Discipline[]
}

export const fetchDisciplines = createAsyncBackendThunk('fetchDisciplines', async (projectId: number) => {
  const fetchDisciplinesPromise = backendAxios.get(`/disciplines/`)
  const fetchSelectedDisciplinesPromise = backendAxios.get(`/projects/${projectId}/disciplines/`)
  const [disciplinesResponse, selectedDisciplinesResponse] = await Promise.all([
    fetchDisciplinesPromise,
    fetchSelectedDisciplinesPromise,
  ])
  const allDisciplines = disciplinesResponse.data as Discipline[]
  const selectedDisciplines = selectedDisciplinesResponse.data as Omit<Discipline, 'marker'>[]
  return { selectedDisciplines, allDisciplines }
})

export const updateDisciplinesConfig = createAsyncBackendThunk(
  'updateDisciplinesConfig',
  async (arg, { dispatch, getState }) => {
    const state = getStateAsAny(getState)
    const projectId = selectProjectId(state) as number
    const newIds = selectSelectedDisciplinesIds(state) as number[]
    await backendAxios.post(`projects/${projectId}/disciplines/`, newIds)
    await dispatch(addProjectConfigFinishedStep({ projectId, step: 'DISCIPLINES' }))
  }
)

const projectDisciplinesSlice = createSlice({
  name: 'disciplines',
  initialState,
  reducers: {
    setAddingNewDiscipline: (state, action: PayloadAction<boolean>) => {
      state.isAddingNewDiscipline = action.payload
    },
    addDisciplineToSelected: (state, action: PayloadAction<number>) => {
      const addedDiscipline = state.disciplines.find(({ id }) => id === action.payload)
      if (addedDiscipline) {
        state.selectedDisciplines.push(addedDiscipline)
      }
      state.isAddingNewDiscipline = false
    },
    removeDisciplineFromSelected: (state, action: PayloadAction<number>) => {
      state.selectedDisciplines = state.selectedDisciplines.filter(({ id }) => id !== action.payload)
      state.isAddingNewDiscipline = false
    },
  },
  extraReducers: combine([
    generateExtraBackendReducers<ProjectDisciplinesConfigState, LoadingTypes, FetchedDisciplines>({
      promise: fetchDisciplines,
      loadingType: 'fetchDisciplines',
      onFulfilled: (state, action) => {
        state.disciplines = action.payload.allDisciplines
        state.selectedDisciplines = action.payload.selectedDisciplines
      },
    }),
    generateExtraBackendReducers<ProjectDisciplinesConfigState, LoadingTypes, FetchedDisciplines>({
      promise: updateDisciplinesConfig,
      loadingType: 'updateDisciplinesConfig',
      onFulfilled: (state) => {
        state.isAddingNewDiscipline = false
      },
    }),
  ]),
})

export const {
  setAddingNewDiscipline,
  addDisciplineToSelected,
  removeDisciplineFromSelected,
} = projectDisciplinesSlice.actions

export const projectDisciplinesConfigReducer = projectDisciplinesSlice.reducer
