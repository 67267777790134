import { useEffect } from 'react'
import { ActionCreatorWithoutPayload } from '@reduxjs/toolkit'
import { useDispatch, useSelector } from 'react-redux'
import { Modal } from 'antd'
import { BackendLoading } from 'redux/redux.shared'
import { handleErrorByCode } from 'helpers/HttpError.helpers'
import { RootState } from 'redux/store'
import { MessageDescriptor } from '@lingui/core'

const useError = (
  loadingSelector: (state: RootState) => BackendLoading,
  clearErrorAction: ActionCreatorWithoutPayload<string>,
  errorMessageDict: Record<string, string | MessageDescriptor>,
  onOk?: () => void
): void => {
  const dispatch = useDispatch()
  const { error } = useSelector(loadingSelector)
  useEffect(() => {
    if (error) {
      Modal.error({
        title: 'Wystąpił błąd',
        content: handleErrorByCode(errorMessageDict, error),
        onOk: () => {
          dispatch(clearErrorAction())
          if (onOk) onOk()
        },
      })
    }
  }, [error, dispatch, errorMessageDict, clearErrorAction, onOk])
}

export default useError
