import { useEffect } from 'react'
import { Space } from 'antd'
import { Trans } from '@lingui/macro'
import Text from 'components/atoms/text'
import { BasicOrganizationData, WipGroup } from 'models/administration'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'redux/store'
import {
  addNewWipGroupForOrganization,
  changeWipGroupForOrganization,
  fetchWipGroups,
} from 'redux/project/project-config/wip-groups'
import { selectProjectWipGroups } from 'redux/project/project-config/wip-groups/ProjectWipGroupsConfig.selectors'
import styled from 'styled-components'
import { selectProjectId } from 'redux/project/project-details/ProjectDetails.selectors'
import DroppableItems from './DroppableItems'
import ProjectWipGroupsConfigAlerts from './ProjectWipGroupsConfigAlerts'

const StyledDiv = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: ${(props) => props.theme.sizes.m};
  border-width: 2px;
  border-style: dashed;
  border-color: ${(props) => props.theme.colors.grayG03};
  padding: ${(props) => props.theme.sizes.m};
  padding-bottom: ${(props) => props.theme.sizes.xxl};
`

const StyledSpace = styled(Space)`
  max-width: 100%;
  overflow-x: hidden;
`

const ProjectWipGroupsConfigContent: React.FC = () => {
  const dispatch = useAppDispatch()
  const projectId = useSelector(selectProjectId)
  useEffect(() => {
    if (projectId) dispatch(fetchWipGroups(projectId))
  }, [dispatch, projectId])
  const wipGroups = useSelector(selectProjectWipGroups)
  const createWip = (sourceWip: number, sourceGroup: WipGroup, movedOrg: BasicOrganizationData) => {
    if (sourceGroup && sourceGroup.organizations.length !== 1) {
      const maxNum: number = Math.max(...wipGroups.map((org) => org.displayedId)) ?? 1
      dispatch(
        addNewWipGroupForOrganization({
          sourceWip,
          movedOrg,
          newId: maxNum + 1,
        })
      )
    }
  }
  const changeGroup = (sourceWip: number, movedOrg: BasicOrganizationData, destinationWip: number) => {
    dispatch(
      changeWipGroupForOrganization({
        sourceWip,
        movedOrg,
        destinationWip,
      })
    )
  }
  return (
    <StyledSpace direction="vertical" size="large">
      <Text textStyle="regularText-medium">
        <Trans id="project_config.wips.step_header">WIP groups</Trans>
      </Text>
      <Text textStyle="regularText-small">
        <Trans id="project_config.wips.step_description">
          Move organizations between WIP groups to restrict the visibility of files in WIP folder
        </Trans>
      </Text>
      <Space size="large">
        <DragDropContext
          onDragEnd={({ source, destination }) => {
            const sourceWip = Number(source.droppableId)
            const sourceGroup = wipGroups.find((wip) => wip.displayedId === sourceWip)
            if (sourceGroup) {
              const movedOrg = sourceGroup.organizations[source.index]
              if (destination) {
                const destinationId = destination.droppableId
                if (destinationId === 'droppable') {
                  createWip(sourceWip, sourceGroup, movedOrg)
                } else {
                  const destinationWip = Number(destinationId)
                  if (sourceWip !== destinationWip) {
                    changeGroup(sourceWip, movedOrg, destinationWip)
                  }
                }
              }
            }
          }}
        >
          <Droppable key="droppable" droppableId="droppable" direction="horizontal">
            {(droppableProvided1) => (
              // eslint-disable-next-line react/jsx-props-no-spreading
              <StyledDiv ref={droppableProvided1.innerRef} {...droppableProvided1.droppableProps}>
                {wipGroups.map(({ organizations, displayedId }) => (
                  <DroppableItems organizations={organizations} index={displayedId} key={`di-${displayedId}`} />
                ))}
                {droppableProvided1.placeholder}
              </StyledDiv>
            )}
          </Droppable>
        </DragDropContext>
      </Space>
      <ProjectWipGroupsConfigAlerts />
    </StyledSpace>
  )
}

export default ProjectWipGroupsConfigContent
