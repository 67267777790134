import { useDispatch, useSelector } from 'react-redux'
import { Modal, Form, Spin } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import DividerElement from 'components/atoms/divider'
import Text from 'components/atoms/text'
import Icon from 'components/atoms/icon'
import Button from 'components/molecules/button-element'
import { booleanToNumeral, numeralToBoolean } from 'helpers/Component.helpers'
import objectsDiff from 'helpers/Objects.helpers'
import { OrganizationDetails } from 'models/administration'
import {
  selectAddingOrganizationLoading,
  selectOrganizationDisplayedInModal,
  selectUpdatingOrganizationLoading,
} from 'redux/administration/administration-organizations/AdministrationOrganizations.selectors'
import {
  addOrganization,
  closeOrganizationModal,
  updateOrganization,
} from 'redux/administration/administration-organizations'
import { defineMessage } from '@lingui/macro'
import { addText, cancelText, saveText } from 'data/messages/controls'
import trans from 'helpers/i18n.helpers'
import { OrganizationEditableData } from 'redux/administration/administration-organizations/AdministrationOrganizations.types'
import OrganizationModalError from './OrganizationModalError'
import OrganizationModalSection from './OrganizationModalSection'
import ContactModalSection from './ContactModalSection'

interface FormValuesModel {
  name: string
  abbreviation: string
  code: string
  address: string | null
  isActive: number
  contactFirstName: string
  contactLastName: string
  contactEmail: string
  contactPhoneNumber: string
  contactPosition: string
}

function getDataFromFormValues(formValues: FormValuesModel): OrganizationEditableData {
  return {
    name: formValues.name,
    abbreviation: formValues.abbreviation,
    isActive: numeralToBoolean(formValues.isActive),
    address: formValues.address,
    contact: {
      firstName: formValues.contactFirstName,
      lastName: formValues.contactLastName,
      phoneNumber: formValues.contactPhoneNumber,
      email: formValues.contactEmail,
      position: formValues.contactPosition,
    },
    code: formValues.code,
  }
}

const getFormInitialValues: (org: OrganizationDetails | undefined) => Partial<FormValuesModel> | undefined = (org) => {
  if (org === undefined) {
    return undefined
  }
  const { name, abbreviation, code, address, isActive } = org
  let contactDict = {}
  if (org.contact) {
    const { email, firstName, lastName, phoneNumber, position } = org.contact
    contactDict = {
      contactEmail: email,
      contactFirstName: firstName,
      contactLastName: lastName,
      contactPhoneNumber: phoneNumber,
      contactPosition: position,
    }
  }
  return {
    name,
    abbreviation,
    code: code.code,
    address,
    isActive: booleanToNumeral(isActive),
    ...contactDict,
  } as Partial<FormValuesModel>
}

const getCurrentOrganizationData: (org: OrganizationDetails) => OrganizationEditableData = (org) => {
  const { name, abbreviation, isActive, address, contact, code } = org
  return { name, abbreviation, isActive, address, contact, code: code.code }
}

const modalTitles = {
  ADD: defineMessage({
    id: 'administration.organizations.modal.add_organization_header',
    message: 'Create organization',
  }),
  EDIT: defineMessage({
    id: 'administration.organizations.modal.edit_organization_header',
    message: 'Edit organization',
  }),
}

const OrganizationModal: React.FC = () => {
  const [form] = Form.useForm()
  const organization = useSelector(selectOrganizationDisplayedInModal)

  const dispatch = useDispatch()
  const handleCancel = () => dispatch(closeOrganizationModal())
  const handleSubmit = async () => {
    form
      .validateFields()
      .then(() => {
        const formValues = form.getFieldsValue()
        const organizationData = getDataFromFormValues(formValues)
        if (organization === undefined) {
          dispatch(addOrganization(organizationData))
        } else {
          const currentData = getCurrentOrganizationData(organization)
          dispatch(
            updateOrganization({
              organizationId: organization.id,
              organizationFieldsToUpdate: objectsDiff(currentData, organizationData, {
                contact: (c1, c2) => Object.keys(objectsDiff(c1, c2)).length === 0,
              }),
            })
          )
        }
      })
      .catch(() => {})
  }

  const { isLoading: isUpdateLoading } = useSelector(selectUpdatingOrganizationLoading)
  const { isLoading: isAddLoading } = useSelector(selectAddingOrganizationLoading)
  const isLoading = isUpdateLoading || isAddLoading

  return (
    <Modal
      visible
      onCancel={handleCancel}
      closeIcon={
        <Icon>
          <CloseOutlined />
        </Icon>
      }
      maskClosable={false}
      title={
        <Text textStyle="header-H2b">{trans(organization === undefined ? modalTitles.ADD : modalTitles.EDIT)}</Text>
      }
      footer={
        <>
          <Button onClick={handleCancel} type="tertiary">
            {trans(cancelText)}
          </Button>
          <Button loading={isLoading} onClick={handleSubmit}>
            {trans(organization === undefined ? addText : saveText)}
          </Button>
        </>
      }
    >
      <Spin spinning={isLoading}>
        <Form form={form} labelCol={{ sm: { span: 7 } }} initialValues={getFormInitialValues(organization)}>
          <OrganizationModalSection />
          <DividerElement />
          <ContactModalSection />
        </Form>
        <OrganizationModalError />
      </Spin>
    </Modal>
  )
}

export default OrganizationModal
