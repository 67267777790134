import styled from 'styled-components'
import { InfoOutlined } from '@material-ui/icons'
import { DownloadOutlined, LinkOutlined, ControlOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import { Popover } from 'antd'
import { toggleDetailsVisibility } from 'redux/files/files-details'
import { selectFilesDetailsVisibility } from 'redux/files/files-details/FilesDetails.selectors'
import Button from 'components/atoms/button.legacy'
import Icon from 'components/atoms/icon'
import { withKeysAndOnClick } from 'helpers/Component.helpers'
import { selectFilesControlsVisibility } from 'redux/files/files-controls/FilesControls.selectors'
import { setControlsVisibility } from 'redux/files/files-controls'
import { selectCheckedFrontendFiles } from 'redux/files/files-table/FilesTable.selectors'
import { downloadFile, downloadFiles } from 'helpers/Files.helpers'
import ButtonElement from 'components/molecules/button-element'
import { showNewEntryModal } from 'redux/files/files-new-entry'
import { openDocumentationNotification } from 'helpers/Notifications.helpers'
import { selectEntryCreationPermission, selectProjectId } from 'redux/project/project-details/ProjectDetails.selectors'
import TooltipElement from 'components/molecules/tooltip-element'
import { selectProjectEntriesExist } from 'redux/files/files-documentation/FilesDocumentation.selectors'
import { t } from '@lingui/macro'
import { linkCopiedMessage } from 'data/messages/misc'
import trans from 'helpers/i18n.helpers'
import FilesControls from './controls'

const StyledDiv = styled.div`
  display: flex;
  position: relative;
  z-index: 1;
  margin: ${(props) => props.theme.sizes.xs} ${(props) => props.theme.sizes.default};
  > *:not(:nth-child(2)):not(:first-child):not(:last-child) {
    margin-right: ${(props) => props.theme.sizes.m};
  }
  > *:nth-child(2) {
    margin-right: ${(props) => props.theme.sizes.m};
  }
  > *:first-child {
    margin-right: auto;
  }
`

const FilesDocumentationTools: React.FC = () => {
  const dispatch = useDispatch()
  const isFilesDetailsVisible = useSelector(selectFilesDetailsVisibility)
  const filesControlsVisibility = useSelector(selectFilesControlsVisibility)
  const checkedFiles = useSelector(selectCheckedFrontendFiles)
  const canCreateEntry = useSelector(selectEntryCreationPermission)
  const checkedDocFiles = checkedFiles.filter((f) => f.type === 'DOC')
  const anyEntryExists = useSelector(selectProjectEntriesExist)
  const projectId = useSelector(selectProjectId)

  const buttonOptions: {
    component: JSX.Element
    onClick?: React.MouseEventHandler<HTMLElement>
    isActive?: boolean
    disabled?: boolean
  }[] = [
    // { component: <SearchOutlined /> },
    {
      component: <InfoOutlined />,
      onClick: () => dispatch(toggleDetailsVisibility()),
      isActive: isFilesDetailsVisible,
      disabled: !anyEntryExists,
    },
    {
      component: <DownloadOutlined />,
      disabled: checkedDocFiles.length === 0,
      onClick: () =>
        checkedDocFiles.length === 1
          ? checkedDocFiles[0].fileId && downloadFile(checkedDocFiles[0].fileId)
          : projectId && downloadFiles(projectId, checkedFiles.map((f) => f.fileId).filter(Boolean) as number[]),
    },
    {
      component: <LinkOutlined />,
      onClick: () => {
        navigator.clipboard.writeText(window.location.href).then(() => {
          openDocumentationNotification('success', { message: trans(linkCopiedMessage) })
        })
      },
    },
  ]

  const newEntryButtonText = t({ id: 'project.files.add_new_file_button_text', message: 'Add file' })
  return (
    <StyledDiv>
      <TooltipElement
        placement="top"
        title={t({ id: 'project.files.no_write_permission_text', message: 'You do not have write permission' })}
        disabled={canCreateEntry}
      >
        <ButtonElement onClick={() => dispatch(showNewEntryModal())} disabled={!canCreateEntry}>
          {newEntryButtonText}
        </ButtonElement>
      </TooltipElement>

      {withKeysAndOnClick(buttonOptions).map(({ key, component, onClick, isActive, disabled }) => (
        <Button
          key={key}
          elementType="button"
          shape="circle"
          onClick={onClick}
          isActive={isActive}
          disabled={disabled}
          icon={
            <Icon size="defaultM" color="grayG07">
              {component}
            </Icon>
          }
        />
      ))}
      <Popover
        visible={filesControlsVisibility}
        placement="bottomLeft"
        onVisibleChange={(visibility) => dispatch(setControlsVisibility(visibility))}
        content={<FilesControls />}
        trigger="click"
      >
        <Button
          elementType="button"
          shape="circle"
          isActive={filesControlsVisibility}
          icon={
            <Icon size="defaultM" color="grayG07">
              <ControlOutlined />
            </Icon>
          }
        />
      </Popover>
    </StyledDiv>
  )
}

export default FilesDocumentationTools
