import styled from 'styled-components'
import MainNavBarOptions from 'components/organisms/main-navbar/main-navbar-options'
import { Link } from 'react-router-dom'
import logo from 'assets/logo.svg'
import MainNavBarUserData from './main-navbar-user-data'

const StyledNav = styled.nav`
  &:not(:first-child) {
    padding: 0 ${(props) => props.theme.sizes.l};
  }
  &:first-child {
    padding: 0 ${(props) => props.theme.sizes.l} 0 ${(props) => props.theme.sizes.s};
  }
  background: ${(props) => props.theme.colors.baseWhiteish};
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-gap: 20px;
  align-items: center;
  height: ${(props) => props.theme.sizes.mainNavbarHeight};
  box-shadow: ${(props) => props.theme.effects.menuShadow};
`

const StyledImg = styled.img`
  height: ${(props) => props.theme.sizes.mainNavbarHeight};
`

const MainNavBar: React.FC = () => {
  return (
    <StyledNav>
      <Link to="/">
        <StyledImg src={logo} />
      </Link>
      <MainNavBarUserData />
      <MainNavBarOptions />
    </StyledNav>
  )
}

export default MainNavBar
