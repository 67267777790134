import { Select } from 'antd'
import { SelectProps, SelectValue } from 'antd/lib/select'
import { useEffect, useRef } from 'react'

const AutoSelect: React.FC<SelectProps<SelectValue>> = ({ options, disabled, onChange, value, mode, ...props }) => {
  const isSingleOption = options?.length === 1
  const autoSelectedRef = useRef(false)
  const manyValuesAllowed = mode === 'multiple' || mode === 'tags'
  useEffect(() => {
    if (options && isSingleOption && onChange && !disabled && !autoSelectedRef.current) {
      const val = manyValuesAllowed ? [options[0].value] : options[0].value
      onChange(val, options[0])
      autoSelectedRef.current = true
    }
  }, [options, isSingleOption, onChange, disabled, manyValuesAllowed])
  const defaultValue = manyValuesAllowed && options?.[0].value ? [options?.[0].value] : options?.[0].value
  return (
    <Select
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      mode={mode}
      options={options}
      onChange={onChange}
      value={isSingleOption && !disabled && value === undefined ? defaultValue : value}
      disabled={disabled}
    />
  )
}

export default AutoSelect
