import { InfoCircleOutlined } from '@ant-design/icons'
import { t, Trans } from '@lingui/macro'
import { Form, Modal } from 'antd'
import Icon from 'components/atoms/icon'
import SpaceElement from 'components/atoms/space'
import Text from 'components/atoms/text'
import ButtonElement from 'components/molecules/button-element'
import FormInputNumber from 'components/molecules/form-item/FormNumberInput'
import { MIN_NUMBER_OF_BACKUPS } from 'data/backups'
import { cancelText, saveText } from 'data/messages/controls'
import { required } from 'helpers/Validators.helpers'
import trans from 'helpers/i18n.helpers'
import moment from 'moment'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setSettingsModalVisibility } from 'redux/administration/administration-backups'
import {
  selectBackupSavingLoading,
  selectBackupsSettings,
} from 'redux/administration/administration-backups/AdministrationBackups.selectors'
import { editBackupSettings } from 'redux/administration/administration-backups/AdministrationBackups.slice'
import { numberOfStoredBackupsText } from '../../messages'
import BackupSettingsModalAlert from './BackupSettingsModalAlert'

const decreaseNumberOfStoredBackupsWarnings = (
  <SpaceElement size="xs" direction="horizontal">
    <Icon color="gold6" size="s">
      <InfoCircleOutlined />
    </Icon>
    <div style={{ width: '70%' }}>
      <Text paragraph textStyle="regularText-small" color="grayG08">
        <Trans id="administration.backups.modal.settings.decrease_number_of_backups_warning">
          Decreasing the number of stored backups will result in oldest backups being deleted
        </Trans>
      </Text>
    </div>
  </SpaceElement>
)

const BackupSettingsModal: React.FC = () => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const onCancel = () => dispatch(setSettingsModalVisibility(false))
  const handleSubmit = () => {
    form
      .validateFields()
      .then(() => {
        // const { hour, ...values } = form.getFieldsValue()
        // const hourDate: Date = hour.toDate()
        // const hourStr = `${formatTimeString(hourDate.getUTCHours())}:${formatTimeString(hourDate.getUTCMinutes())}`
        // dispatch(
        //   editBackupSettings({
        //     hour: hourStr,
        //     ...values,
        //   })
        // )
        const { maxStoredBackups } = form.getFieldsValue()
        dispatch(editBackupSettings({ maxStoredBackups }))
      })
      .catch(() => {})
  }
  const { isLoading } = useSelector(selectBackupSavingLoading)
  const settings = useSelector(selectBackupsSettings)
  // const [periodType, setPeriodType] = useState<PeriodType | undefined>(settings?.creationPeriod)
  const [backupsNumber, setBackupsNumber] = useState<number | undefined>(settings?.maxStoredBackups)
  return (
    <Modal
      visible
      onCancel={onCancel}
      maskClosable={false}
      title={
        <Text textStyle="header-H2b">
          <Trans id="administration.backups.modal.automatic_backup.header">Automatic backup settings</Trans>
        </Text>
      }
      footer={
        <>
          <ButtonElement onClick={onCancel} type="tertiary">
            {trans(cancelText)}
          </ButtonElement>
          <ButtonElement loading={isLoading} onClick={handleSubmit}>
            {trans(saveText)}
          </ButtonElement>
        </>
      }
    >
      <Form
        style={{ width: '75%', margin: 'auto' }}
        form={form}
        labelCol={{ sm: { span: 11 } }}
        initialValues={{ ...settings, hour: moment(settings?.hour, 'HH:mm') }}
      >
        <FormInputNumber
          label={
            <Text textStyle="regularText-small" paragraph>
              {trans(numberOfStoredBackupsText)}
            </Text>
          }
          name="maxStoredBackups"
          placeholder={t({
            id: 'administration.backups.modal.settings.max_stored_backups_placeholder',
            message: 'Enter',
          })}
          onChange={(val) => setBackupsNumber(Number(val))}
          min={MIN_NUMBER_OF_BACKUPS}
          rules={[required()]}
        />
        {(backupsNumber || 0) < (settings?.maxStoredBackups || 0) && decreaseNumberOfStoredBackupsWarnings}
        {/* <DividerElement />
        <FormSelect
          label={trans(backupCreationPeriodText)}
          name="creationPeriod"
          placeholder={trans(selectText)}
          options={periodOptions
            .filter(({ value }) => value === 'WEEK' || value === 'MONTH')
            .map(({ label, ...rest }) => ({ label: trans(label), ...rest }))}
          onChange={(v) => {
            form.setFields([{ name: 'days', value: [] }])
            setPeriodType(v as PeriodType)
          }}
          rules={[required()]}
        />
        <BaseFormItem rules={[required()]} label={trans(backupDaysText)} name="days">
          <DaysSelect type={periodType} />
        </BaseFormItem>
        <FormTimePicker
          label={trans(backupTimeText)}
          name="hour"
          format="HH:mm"
          showNow={false}
          rules={[required()]}
          clearIcon={undefined}
        /> */}
      </Form>
      <BackupSettingsModalAlert />
    </Modal>
  )
}

export default BackupSettingsModal
