import { useSelector } from 'react-redux'
import { hideNewVersionPopover, addNewEntryVersion } from 'redux/files/files-new-version'
import useUpload from 'hooks/upload'
import { useAppDispatch } from 'redux/store'
import {
  selectNewVersionFile,
  selectAddNewEntryVersionLoading,
} from 'redux/files/files-new-version/FilesNewVersion.selectors'
import { UploadSingleFile } from 'components/organisms/upload/single-file'
import { t } from '@lingui/macro'
import { selectFilesDefaultSuitabilityCode } from 'redux/files/files-suitability/FilesSuitability.selectors'
import AddFileModal from '../AddFileModal'
import AddNewFileVersionHeading from '../AddFileHeading'
import AddNewFileVersionAttributes from './AddNewFileVersionAttributes'

const AddNewFileVersionModal: React.FC = () => {
  const dispatch = useAppDispatch()
  const { file, beforeUpload, onRemove } = useUpload()
  const frontendFile = useSelector(selectNewVersionFile)
  const newVersionLoading = useSelector(selectAddNewEntryVersionLoading)
  const defaultSuitabilityCode = useSelector(selectFilesDefaultSuitabilityCode)
  const onComplete = async () => {
    if (file && frontendFile) {
      await dispatch(addNewEntryVersion({ file, frontendFile })) // api req
    }
  }
  return (
    <AddFileModal
      onCancel={() => dispatch(hideNewVersionPopover())}
      isLoading={newVersionLoading.isLoading}
      error={newVersionLoading.error}
      onComplete={onComplete}
      headerText={t({ id: 'project.files.add_revision_modal.header_text', message: 'Add new revision' })}
      isTouched={file !== null}
      stepsData={[
        {
          component: <UploadSingleFile file={file} beforeUpload={beforeUpload} onRemove={onRemove} />,
          isValid: file !== null,
          description: t({ id: 'project.files.add_revision_modal.select_file', message: 'File selection' }),
        },
        {
          component: <AddNewFileVersionAttributes />,
          isValid: !!defaultSuitabilityCode,
          description: t({
            id: 'project.files.add_revision_modal.attributes_overview',
            message: 'Attributes overview',
          }),
          heading: file && <AddNewFileVersionHeading name={file.name} />,
        },
      ]}
    />
  )
}

export default AddNewFileVersionModal
