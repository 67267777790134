import { SettingOutlined } from '@ant-design/icons'
import { Space } from 'antd'
import Icon from 'components/atoms/icon'
import themePxValueToNumber from 'helpers/Styles.helpers'
import { useDispatch } from 'react-redux'
import { showCodeSettingsModal } from 'redux/administration/administration-code-settings'
import styled from 'styled-components'
import theme from 'styles/Styles.theme'

const StyledSpace = styled(Space)`
  margin-left: -${(props) => themePxValueToNumber(props.theme.sizes.s)}px;
`

const CodeColumnTableHeader: React.FC = ({ children }) => {
  const dispatch = useDispatch()
  return (
    <StyledSpace size={5}>
      <Icon color="grayG05">
        <SettingOutlined style={{ fontSize: theme.sizes.defaultS }} onClick={() => dispatch(showCodeSettingsModal())} />
      </Icon>
      {children}
    </StyledSpace>
  )
}

export default CodeColumnTableHeader
