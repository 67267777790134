import { WarningTwoTone } from '@ant-design/icons'
import { t } from '@lingui/macro'
import { ColumnsType } from 'antd/lib/table'
import Icon from 'components/atoms/icon'
import FilesTableCellItem from 'components/molecules/files/files-table-cells/FilesTableCellItem'
import FilesTableCellStatus from 'components/molecules/files/files-table-cells/FilesTableCellStatus'
import useTableColumnFiltering, { onFilterStringColumn } from 'components/molecules/table/useTableColumnFiltering'
import TooltipElement from 'components/molecules/tooltip-element'
import {
  acceptanceStatusesFilterData,
  getAcceptanceStatusColor,
  getAcceptanceStatusText,
  getAcceptanceStatusTextWithDate,
} from 'data/acceptance_status'
import formatDate from 'helpers/Dates.helpers'
import { formatRevision } from 'helpers/Files.helpers'
import trans from 'helpers/i18n.helpers'
import { FrontendFile } from 'models/files'
import prettyBytes from 'pretty-bytes'
import { useSelector } from 'react-redux'
import { selectFilesControlsShowAllVersions } from 'redux/files/files-controls/FilesControls.selectors'
import theme from 'styles/Styles.theme'
import { acceptanceStatusText, cdeDirectoryText, revisionText, suitabilityCodeText } from 'data/messages/misc'
import { cdeStatusFilters } from 'helpers/Models.helpers'
import FilesTableCellSuitabilityCode from 'components/molecules/files/files-table-cells/FilesTableCellSuitabilityCode'
import { noSuitabilityCodeText } from 'data/messages/files'
import FilesTableEntryOptions from './FilesTableEntryOptions'

// eslint-disable-next-line max-lines-per-function
const useFrontendFilesColumns: () => ColumnsType<FrontendFile> = () => {
  const showAllVersions = useSelector(selectFilesControlsShowAllVersions)

  return [
    {
      title: t({ id: 'files.documentation.table.frontend_files.column_name_header', message: 'Name' }),
      key: 'name',
      dataIndex: 'name',
      render: (val, frontendFile) => (
        <FilesTableCellItem
          indent={!frontendFile.isCurrent && showAllVersions}
          options={
            frontendFile.permissions && (
              <FilesTableEntryOptions frontendFile={frontendFile} permissions={frontendFile.permissions} />
            )
          }
          bold={frontendFile.isCurrent && showAllVersions}
          tooltip={frontendFile.filename}
          text={frontendFile.shortName}
          abbreviated
          icon={
            frontendFile.isCurrent && !frontendFile.isNewest ? (
              <TooltipElement
                title={t({
                  id: 'files.documentation.table.frontend_files.search_name_tooltip',
                  message: 'New revision in progress',
                })}
              >
                <Icon size="s">
                  <WarningTwoTone twoToneColor={theme.colors.warning} />
                </Icon>
              </TooltipElement>
            ) : undefined
          }
        />
      ),
      ...useTableColumnFiltering(
        t({ id: 'files.documentation.table.frontend_files.search_name_placeholder', message: 'Search for name' })
      ),
      onFilter: (value: string | number | boolean, record: FrontendFile) =>
        onFilterStringColumn(value, record.shortName.toString()),
    },
    {
      title: trans(revisionText),
      key: 'revision',
      dataIndex: 'revision',
      render: (val, { revision, isCurrent }) => (
        <FilesTableCellItem abbreviated bold={isCurrent && showAllVersions} text={formatRevision(revision)} />
      ),
      ...useTableColumnFiltering(
        t({
          id: 'files.documentation.table.frontend_files.search_revision_placeholder',
          message: 'Search for revision',
        })
      ),
      onFilter: (value: string | number | boolean, record: FrontendFile) =>
        onFilterStringColumn(value, formatRevision(record.revision) || ''),
    },
    {
      title: trans(cdeDirectoryText),
      key: 'cdeStatus',
      dataIndex: 'cdeStatus',
      render: (val, { cdeStatus }) => <FilesTableCellStatus status={cdeStatus} />,
      filters: cdeStatusFilters(),
      onFilter: (value: string | number | boolean, record: FrontendFile) => record.cdeStatus === value,
    },
    {
      title: trans(suitabilityCodeText),
      key: 'suitabilityCode',
      dataIndex: 'suitabilityCode',
      render: (val, { suitabilityCode, isCurrent }) => (
        <FilesTableCellSuitabilityCode bold={isCurrent && showAllVersions} suitabilityCode={suitabilityCode} />
      ),
      ...useTableColumnFiltering(
        t({
          id: 'files.documentation.table.frontend_files.search_suitability_code_placeholder',
          message: 'Search for suitability code',
        })
      ),
      onFilter: (value: string | number | boolean, record: FrontendFile) => {
        if (!record.suitabilityCode) return onFilterStringColumn(value, trans(noSuitabilityCodeText))
        return (
          onFilterStringColumn(value, record.suitabilityCode.name) ||
          onFilterStringColumn(value, record.suitabilityCode.code)
        )
      },
    },
    {
      title: trans(acceptanceStatusText),
      key: 'process',
      dataIndex: 'process',
      render: (val, { cdeStatus, isCurrent, acceptanceStatus }) => (
        <FilesTableCellItem
          abbreviated
          bold={isCurrent && showAllVersions}
          tooltip={getAcceptanceStatusTextWithDate(acceptanceStatus, cdeStatus)}
          text={getAcceptanceStatusText(acceptanceStatus?.status, cdeStatus)}
          textColor={getAcceptanceStatusColor(acceptanceStatus?.status)}
        />
      ),
      filters: acceptanceStatusesFilterData.map(({ value, text }) => ({ value, text: trans(text) })),
      onFilter: (value, record) => {
        if (value === 'IN_PROGRESS') {
          return record.acceptanceStatus?.status === null
        }
        return value === record.acceptanceStatus?.status
      },
    },
    {
      title: t({ id: 'files.documentation.table.frontend_files.column_description_header', message: 'Description' }),
      key: 'description',
      dataIndex: 'description',
      render: (val, { isCurrent, description }) => (
        <FilesTableCellItem abbreviated bold={isCurrent && showAllVersions} text={description} />
      ),
      ...useTableColumnFiltering(
        t({
          id: 'files.documentation.table.frontend_files.search_description_placeholder',
          message: 'Search for description',
        })
      ),
      onFilter: (value: string | number | boolean, record: FrontendFile) =>
        onFilterStringColumn(value, record.description),
    },
    {
      title: t({ id: 'files.documentation.table.frontend_files.column_size_header', message: 'Size' }),
      key: 'size',
      dataIndex: 'size',
      render: (val, { isCurrent, size }) => (
        <FilesTableCellItem abbreviated bold={isCurrent && showAllVersions} text={size ? prettyBytes(size) : ''} />
      ),
      ...useTableColumnFiltering(
        t({
          id: 'files.documentation.table.frontend_files.search_size_placeholder',
          message: 'Search up to (in B)',
        })
      ),
      onFilter: (value: string | number | boolean, record: FrontendFile) => !!record.size && value >= record.size,
    },
    {
      title: t({ id: 'files.documentation.table.frontend_files.column_update_date_header', message: 'Update date' }),
      key: 'lastUpdated',
      dataIndex: 'lastUpdated',
      render: (val, { isCurrent, lastUpdated, uploadDate }) => (
        <FilesTableCellItem
          abbreviated
          bold={isCurrent && showAllVersions}
          text={formatDate(lastUpdated || uploadDate || '')}
        />
      ),
      ...useTableColumnFiltering(
        t({
          id: 'files.documentation.table.frontend_files.search_update_date_placeholder',
          message: 'Search for update date',
        })
      ),
      onFilter: (value: string | number | boolean, record: FrontendFile) =>
        onFilterStringColumn(value, formatDate(record.lastUpdated || record.uploadDate || '')),
    },
  ]
}

export default useFrontendFilesColumns
