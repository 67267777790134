import { ColumnsType } from 'antd/lib/table'
import useTableColumnFiltering, { onFilterStringColumn } from 'components/molecules/table/useTableColumnFiltering'
import { actionsTableColumnText } from 'data/messages/controls'
import { t, Trans } from '@lingui/macro'
import trans from 'helpers/i18n.helpers'
import { NamingScheme } from 'models/administration'
import { NamingSchemeWithDetails } from 'redux/administration/administration-naming-scheme/AdministrationNamingScheme.types'
import NamingSchemeActions from './NamingSchemeActions'
import NamingSchemeDefaultButton from './NamingSchemeDefaultButton'
import NamingSchemeSampleName from './NamingSchemeSampleName'

const useAdministrationNamingSchemeColumns = (): ColumnsType<NamingSchemeWithDetails> => {
  return [
    {
      title: <Trans id="administration.naming_schemes.main_table.column_name_label">Name</Trans>,
      dataIndex: 'name',
      key: 'name',
      width: 250,
      render: (value, { name }) => name,
      ...useTableColumnFiltering(
        t({ id: 'administration.naming_schemes.main_table.search_name_placeholder', message: 'Search for name' })
      ),
      onFilter: (value: string | number | boolean, record: NamingScheme) => onFilterStringColumn(value, record.name),
    },
    {
      title: <Trans id="administration.naming_schemes.main_table.column_sample_filename_label">Sample filename</Trans>,
      dataIndex: 'sampleFilename',
      key: 'sampleFilename',
      width: 550,
      render: (value, { details }) => <NamingSchemeSampleName scheme={details} />,
    },
    {
      title: <Trans id="administration.naming_schemes.main_table.column_is_default_label">Default</Trans>,
      dataIndex: 'isDefault',
      key: 'isDefault',
      width: 150,
      render: (value, { id, isDefault, name }) => (
        <NamingSchemeDefaultButton isDefault={isDefault} schemeId={id} name={name} />
      ),
    },
    {
      title: trans(actionsTableColumnText),
      dataIndex: 'actions',
      key: 'actions',
      width: 125,
      render: (value, { id, name, isDefault, isEditable, isUsed }) => (
        <NamingSchemeActions id={id} name={name} isDefault={isDefault} isEditable={isEditable} isUsed={isUsed} />
      ),
    },
  ]
}

export default useAdministrationNamingSchemeColumns
