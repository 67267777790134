import { Col, Row } from 'antd'
import styled from 'styled-components'

interface PasswordResetTemplateProps {
  children: React.ReactNode
}

const StyledCol = styled(Col)`
  width: 400px;
`
const StyledRow = styled(Row)`
  min-height: 100vh;
`

const PasswordResetTemplate: React.FC<PasswordResetTemplateProps> = ({ children }) => {
  return (
    <StyledRow justify="center" align="middle">
      <StyledCol span={8}>{children}</StyledCol>
    </StyledRow>
  )
}

export default PasswordResetTemplate
