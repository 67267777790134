import { MessageDescriptor } from '@lingui/core'
import { defineMessage, t } from '@lingui/macro'
import ErrorAlert from 'components/atoms/error-alert'
import { handleApiErrors } from 'helpers/HttpError.helpers'
import { useSelector } from 'react-redux'
import {
  selectCreatingFlowLoading,
  selectUpdatingFlowLoading,
} from 'redux/flow-conifg/general/FlowGeneralConfig.selectors'
import { selectFetchingFlowsLoading } from 'redux/project/project-config/flow/ProjectFlowConfig.selectors'

const flowErrorDict: Record<string, MessageDescriptor | string> = {
  'flow.duplicated_name_in_project': defineMessage({
    id: 'flow_config.alert.duplicated_name',
    message: 'The given flow name already exists.',
  }),
  'flow.prev_flows_only_recurrent_flows': defineMessage({
    id: 'flow_config.alert.prev_flows_only_recurrent',
    message: "The flow won't be avaible for any file. Incorrect previous flows.",
  }),
}

const FlowGeneralConfigContentAlerts: React.FC = () => {
  const { error: fetchingFlowsError } = useSelector(selectFetchingFlowsLoading)
  const { error: creatingFlowError } = useSelector(selectCreatingFlowLoading)
  const { error: updatingFlowsError } = useSelector(selectUpdatingFlowLoading)
  const alertMessageDict = [
    {
      error: fetchingFlowsError,
      message: t({
        id: 'flow_config.alert.fetching_flows_failed',
        message: 'Could not retrieve flows.',
      }),
    },
    {
      error: creatingFlowError,
      message: handleApiErrors(
        flowErrorDict,
        creatingFlowError?.errors,
        t({
          id: 'flow_config.alert.creating_flow_failed',
          message: 'Could not create flow.',
        })
      ),
    },
    {
      error: updatingFlowsError,
      message: handleApiErrors(
        flowErrorDict,
        updatingFlowsError?.errors,
        t({
          id: 'flow_config.alert.updating_flow_failed',
          message: 'Could not update flow.',
        })
      ),
    },
  ]
  return <ErrorAlert alertMessageDict={alertMessageDict} />
}

export default FlowGeneralConfigContentAlerts
