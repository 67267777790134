import { useSelector } from 'react-redux'
import { Spin, Alert } from 'antd'
import Text from 'components/atoms/text'
import {
  selectGisFileLayers,
  selectFetchGisFileLayersLoading,
} from 'redux/map/map-gis-layers/map-gis-file-layers/MapGisFileLayers.selectors'
import { selectWmsLayers, selectFetchWmsLayersLoading } from 'redux/map/map-wms-layers/MapWmsLayers.selectors'
import MapSideDrawerTree from 'components/molecules/map/map-sidedrawer-tree'
import DividerElement from 'components/atoms/divider'
import {
  selectFetchGisProjectLayersLoading,
  selectGisProjectLayers,
} from 'redux/map/map-gis-layers/map-gis-project-layers/MapGisProjectLayers.selectors'
import WmsSideDrawerTree from './WmsSideDrawerTree'
import GisFileSideDrawerTree from './GisFileSideDrawerTree'
import GisProjectSideDrawerTree from './GisProjectSideDrawerTree'

const MapSideDrawerLayers: React.FC = () => {
  const gisFileLayers = useSelector(selectGisFileLayers)
  const gisFileLayersLoading = useSelector(selectFetchGisFileLayersLoading)

  const gisProjectLayers = useSelector(selectGisProjectLayers)
  const gisProjectLayersLoading = useSelector(selectFetchGisProjectLayersLoading)

  const wmsLayers = useSelector(selectWmsLayers)
  const wmsLayersLoading = useSelector(selectFetchWmsLayersLoading)

  return (
    <>
      <Text textStyle="header-H2b">Warstwy</Text>
      <DividerElement />
      <Text textStyle="regularText-small" color="grayG05">
        Mapy ogólne
      </Text>
      <Spin spinning={wmsLayersLoading.isLoading}>
        <WmsSideDrawerTree data={wmsLayers} />
        {wmsLayersLoading.error && (
          <Alert message="Błąd!" description="Nie udało się pobrać warstw ogólnych." type="error" showIcon />
        )}
      </Spin>
      <DividerElement />
      <Text textStyle="regularText-small" color="grayG05">
        Warstwy własne
      </Text>
      <MapSideDrawerTree
        data={[
          {
            id: 1,
            name: 'Obszary',
            children: [
              {
                id: 2,
                name: 'Obwodnica zatora',
                children: [],
              },
            ],
          },
        ]}
      />
      <DividerElement />
      <Text textStyle="regularText-small" color="grayG05">
        Warstwy GIS (projektowe)
      </Text>
      <Spin spinning={gisProjectLayersLoading.isLoading}>
        <GisProjectSideDrawerTree data={gisProjectLayers} />
        {gisProjectLayersLoading.error && (
          <Alert message="Błąd!" description="Nie udało się pobrać warstw gis projektu." type="error" showIcon />
        )}
      </Spin>
      <DividerElement />
      <Text textStyle="regularText-small" color="grayG05">
        Warstwy GIS (z plików)
      </Text>
      <Spin spinning={gisFileLayersLoading.isLoading}>
        <GisFileSideDrawerTree data={gisFileLayers} />
        {gisFileLayersLoading.error && (
          <Alert message="Błąd!" description="Nie udało się pobrać warstw gis plików." type="error" showIcon />
        )}
      </Spin>
    </>
  )
}

export default MapSideDrawerLayers
