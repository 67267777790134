import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import localStorage from 'redux-persist/lib/storage'
import { persistReducer } from 'redux-persist'
import axios from 'axios'
import { loginUserUrl } from 'routes/external.routes'
import backendAxios from 'axios/axios'
import { RootState } from 'redux/store'
import {
  combine,
  createAsyncBackendThunk,
  generateExtraBackendReducers,
  generateInitialLoadingState,
  getStateAsAny,
} from '../redux.shared'
import { GlobalPermissions, loadingType, LoadingType, LoginState } from './Login.types'

const selectRefreshToken: (state: RootState) => string | undefined = (state: RootState) => state.login.refreshToken

export const loginUser = createAsyncBackendThunk(
  'login/loginUser',
  async ({ email, password }: { email: string; password: string }) => {
    const user = {
      email,
      password,
    }
    const response = await axios.post(loginUserUrl, user)
    return {
      accessToken: response.data.access,
      refreshToken: response.data.refresh,
      permissions: response.data.permissions,
    }
  }
)

export const initialTokenRefresh = createAsyncThunk(
  'login/initialTokenRefresh',
  async (arg, { getState, rejectWithValue }) => {
    const refreshToken = selectRefreshToken(getStateAsAny(getState))
    if (refreshToken) {
      const response = await backendAxios.post('/api/token/refresh/', { refresh: refreshToken })
      return {
        accessToken: response.data.access,
        permissions: response.data.permissions,
      }
    }
    return rejectWithValue('No refresh token')
  }
)

export const logoutUser = createAsyncBackendThunk('login/logoutUser', async (arg, { getState }) => {
  const refreshToken = selectRefreshToken(getStateAsAny(getState))
  await backendAxios.post('api/token/logout/', { refreshToken })
})

export const fetchUserAccountData = createAsyncBackendThunk('login/fetchAccountData', async () => {
  const response = await backendAxios.get('users/account/current/')
  return response.data
})

const loginInitialState = {
  loading: generateInitialLoadingState<LoadingType>(loadingType),
  isAuthenticated: false,
} as LoginState

const loginSlice = createSlice({
  name: 'login',
  initialState: loginInitialState,
  reducers: {
    setRedirectLink: (state, action: PayloadAction<string | undefined>) => {
      state.redirectLink = action.payload
    },
  },
  extraReducers: combine([
    generateExtraBackendReducers<
      LoginState,
      LoadingType,
      { accessToken: string; refreshToken: string; permissions: GlobalPermissions }
    >({
      promise: loginUser,
      loadingType: 'loginUser',
      onFulfilled: (state, action) => {
        state.isAuthenticated = true
        state.accessToken = action.payload.accessToken
        state.refreshToken = action.payload.refreshToken
        state.permissions = action.payload.permissions
      },
    }),
    generateExtraBackendReducers<LoginState, LoadingType, { accessToken: string; permissions: GlobalPermissions }>({
      promise: initialTokenRefresh,
      loadingType: 'initialTokenRefresh',
      onFulfilled: (state, action) => {
        state.isAuthenticated = true
        state.accessToken = action.payload.accessToken
        state.permissions = action.payload.permissions
      },
      onRejected: (state) => {
        state.refreshToken = undefined
      },
    }),
    generateExtraBackendReducers<LoginState, LoadingType>({
      promise: logoutUser,
      loadingType: 'logoutUser',
      onFulfilled: (state) => {
        Object.assign(state, loginInitialState)
      },
    }),
    generateExtraBackendReducers<LoginState, LoadingType, { name: string; organization: string }>({
      promise: fetchUserAccountData,
      loadingType: 'fetchAccountData',
      onFulfilled: (state, action) => {
        state.accountData = action.payload
      },
    }),
  ]),
})

const persistConfig = {
  key: 'login',
  storage: localStorage,
  whitelist: ['refreshToken'],
}

export const { setRedirectLink } = loginSlice.actions

export const loginReducer = persistReducer(persistConfig, loginSlice.reducer)
