import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { Spin } from 'antd'
import { selectFilesDetailsVisibility } from 'redux/files/files-details/FilesDetails.selectors'
import { selectIsNewFileVersionModalVisible } from 'redux/files/files-new-version/FilesNewVersion.selectors'
import { selectIsEntryModalVisible } from 'redux/files/files-new-entry/FilesNewEntry.selectors'
import { selectFetchProjectEntriesLoading } from 'redux/files/files-documentation/FilesDocumentation.selectors'
import { selectFetchProjectDetailsLoading } from 'redux/project/project-details/ProjectDetails.selectors'
import FilesDocumentationHeading from './FilesDocumentationHeading'
import FilesDocumentationNavigator from './navigator/FilesDocumentationNavigator'
import FilesDocumentationTools from './FilesDocumentationTools'
import FilesTable from './table'
import FilesDetails from './details/FilesDetails'
import AddNewFileVersionModal from './addfile-modal/add-newfile-version/AddNewFileVersionModal'
import AddEntryModal from './addfile-modal/add-entry'

const StyledContent = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto auto 1fr;
  position: relative;
  > * {
    &:first-child {
      grid-column: 1/-1;
    }
    &:nth-child(2) {
      grid-row: 2/-1;
    }
    &:nth-child(3) {
      grid-row: 2/3;
      grid-column: 2/3;
    }
    &:nth-child(4),
    &:nth-child(5) {
      grid-row: 3/-1;
      grid-column: 2/3;
      overflow-y: auto;
    }
    &:nth-child(5) {
      justify-self: end;
      z-index: 2;
    }
  }
`

const FilesDocumentation: React.FC = () => {
  const isFileDetailsPartVisible = useSelector(selectFilesDetailsVisibility)
  const newVersionModalVisibility = useSelector(selectIsNewFileVersionModalVisible)
  const newEntryPopoverVisibility = useSelector(selectIsEntryModalVisible)
  const projectEntriesLoading = useSelector(selectFetchProjectEntriesLoading)
  const projectDetailsLoading = useSelector(selectFetchProjectDetailsLoading)
  return (
    <Spin spinning={projectEntriesLoading.isLoading || projectDetailsLoading.isLoading}>
      <StyledContent>
        <FilesDocumentationHeading />
        <FilesDocumentationNavigator />
        <FilesDocumentationTools />
        <div>
          <FilesTable />
        </div>
        {isFileDetailsPartVisible && <FilesDetails />}
        {newVersionModalVisibility && <AddNewFileVersionModal />}
        {newEntryPopoverVisibility && <AddEntryModal />}
      </StyledContent>
    </Spin>
  )
}

export default FilesDocumentation
