import { useState } from 'react'
import { Alert, Row } from 'antd'
import styled from 'styled-components'
import { CloseOutlined } from '@ant-design/icons'
import Text from 'components/atoms/text'
import useSteps from 'hooks/steps'
import StepData from 'models/components'
import StepsElement from 'components/molecules/steps'
import Icon from 'components/atoms/icon'
import { HttpBackendError } from 'models/errors'
import { handleApiErrors } from 'helpers/HttpError.helpers'
import ModalFooter from 'components/molecules/modal/ModalFooter'
import Modal, { ModalLeftSide, ModalRightSide, SpinModalLayout } from 'components/molecules/modal/Modal'
import { useSelector } from 'react-redux'
import { selectIsAddingNextFile } from 'redux/files/files-new-entry/FilesNewEntry.selectors'
import { MessageDescriptor } from '@lingui/core'
import { defineMessage, t } from '@lingui/macro'
import trans from 'helpers/i18n.helpers'
import { noText, yesText } from 'data/messages/misc'
import { errorText } from 'data/messages/controls'

interface AddFileModalProps {
  headerText: string
  headerToolTip?: React.ReactNode
  onCancel: () => void
  stepsData: StepData[]
  onComplete: () => Promise<void>
  isTouched: boolean
  isLoading: boolean
  error?: HttpBackendError
  errorMessageDict?: Record<string, MessageDescriptor | string>
}

const StyledModal = styled(Modal)`
  .ant-modal-content {
    min-height: 570px;
    width: 1050px;
    @media (max-height: 750px) {
      top: -50px;
    }
  }

  .ant-modal-body {
    .ant-spin-nested-loading {
      .ant-spin-container {
        > * {
          &:first-child {
            margin-top: 0;
          }
        }

        grid-template-rows: auto 1fr;
      }
    }
  }
`

export const addFileErrorMessagesDict = {
  'file.max_filename_length_exceeded': defineMessage({
    id: 'project.files.add_file_modal.errors.filename_length_exceeded',
    message: 'Maximum filename length was exceeded (254 characters).',
  }),
  'extension.invalid_characters': defineMessage({
    id: 'project.files.add_file_modal.errors.extension_invalid_characters',
    message: 'The extension contains invalid characters (allowed: alphanumeric and underscore).',
  }),
  'extension.too_long': defineMessage({
    id: 'project.files.add_file_modal.errors.extension_too_long',
    message: 'Maximum extension length was exceeded (25 characters).',
  }),
}

const AddFileModal: React.FC<AddFileModalProps> = ({
  headerText,
  onCancel,
  stepsData,
  onComplete,
  headerToolTip,
  isTouched,
  isLoading,
  error,
  errorMessageDict = addFileErrorMessagesDict,
}) => {
  const { currentStep, handleNextStep, handlePrevStep, isLastStep, setCurrentStep } = useSteps(stepsData.length)
  const isAddingNextFile = useSelector(selectIsAddingNextFile)
  const [isVisible, setIsVisible] = useState(true)
  const handleCancel = () => {
    if (isTouched) {
      setIsVisible(false)
      Modal.confirm({
        title: t({ id: 'project.files.add_file_modal.cancel_confirmation_header', message: 'Discard all changes?' }),
        content: t({
          id: 'project.files.add_file_modal.cancel_confirmation_text',
          message: 'Closing this window will discard all changes. File will not be added.',
        }),
        onCancel: () => {
          setIsVisible(true)
        },
        cancelText: trans(noText),
        okText: trans(yesText),
        onOk: () => {
          onCancel()
        },
      })
    } else {
      onCancel()
    }
  }
  return (
    <StyledModal
      title={
        <>
          <Text textStyle="header-H2b">{headerText}</Text>
          {headerToolTip}
        </>
      }
      closeIcon={
        <Icon>
          <CloseOutlined />
        </Icon>
      }
      maskClosable={false}
      footer={
        <ModalFooter
          handleNextStep={handleNextStep}
          currentStep={currentStep}
          handlePrevStep={handlePrevStep}
          onCancel={handleCancel}
          isLastStep={isLastStep}
          isContentValid={stepsData[currentStep].isValid}
          onComplete={async () => {
            await onComplete().then(() => {
              if (isAddingNextFile) {
                setCurrentStep(0)
              }
            })
          }}
          isLoading={isLoading}
        />
      }
      onCancel={handleCancel}
      visible={isVisible}
    >
      <SpinModalLayout spinning={isLoading} size="large">
        <div>{stepsData[currentStep].heading}</div>
        <Row wrap={false}>
          <ModalLeftSide>
            <StepsElement direction="vertical" current={currentStep} stepsData={stepsData} />
          </ModalLeftSide>
          <ModalRightSide>{stepsData[currentStep].component}</ModalRightSide>
        </Row>
        {error && (
          <Alert
            message={trans(errorText)}
            description={
              error.responseCode === 403
                ? t({
                    id: 'project.files.add_file_modal.permission_denied_text',
                    message: 'You do not have permission to perform this action',
                  })
                : handleApiErrors(errorMessageDict, error.errors)
            }
            type="error"
            showIcon
          />
        )}
      </SpinModalLayout>
    </StyledModal>
  )
}

export default AddFileModal
