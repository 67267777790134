import { ColumnsType } from 'antd/lib/table'
import Text from 'components/atoms/text'
import CdeStatusPermissions from './CdeStatusPermissions'

export interface DisciplinePermissionRowProps {
  disciplineId: number
  disciplineName: string
}

const membersPermissionsColumns: ColumnsType<DisciplinePermissionRowProps> = [
  {
    title: 'Branża',
    dataIndex: 'discipline',
    key: 'discipline',
    width: 200,
    render: (value, { disciplineName }) => <Text textStyle="regularText-small">{disciplineName}</Text>,
  },
  {
    title: 'WIP',
    dataIndex: 'IN_PROGRESS',
    key: 'IN_PROGRESS',
    width: 180,
    render: (value, { disciplineId }) => <CdeStatusPermissions disciplineId={disciplineId} cdeStatus="IN_PROGRESS" />,
  },
  {
    title: 'SHA',
    dataIndex: 'SHARED',
    key: 'SHARED',
    width: 180,
    render: (value, { disciplineId }) => <CdeStatusPermissions disciplineId={disciplineId} cdeStatus="SHARED" />,
  },
  {
    title: 'PUB',
    dataIndex: 'PUBLISHED',
    key: 'PUBLISHED',
    width: 180,
    render: (value, { disciplineId }) => <CdeStatusPermissions disciplineId={disciplineId} cdeStatus="PUBLISHED" />,
  },
  {
    title: 'ARCH',
    dataIndex: 'ARCHIVED',
    key: 'ARCHIVED',
    width: 180,
    render: (value, { disciplineId }) => <CdeStatusPermissions disciplineId={disciplineId} cdeStatus="ARCHIVED" />,
  },
]

export default membersPermissionsColumns
