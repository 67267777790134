import Text from 'components/atoms/text'
import { Input } from 'antd'
import styled from 'styled-components'
import { t, Trans } from '@lingui/macro'

interface UserDataFilterProps {
  onChange: (value: string) => void
  value: string
}

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 250px;
`

const UserDataFilter: React.FC<UserDataFilterProps> = ({ onChange, value }) => {
  return (
    <StyledDiv>
      <Text textStyle="regularText-small" color="baseBlackish">
        <Trans id="project_config.members.modal.users_filter">Users</Trans>
      </Text>
      <Input
        bordered
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder={t({ id: 'project_config.members.modal.users_filter', message: 'Users' })}
      />
    </StyledDiv>
  )
}

export default UserDataFilter
