import { t, Trans } from '@lingui/macro'
import { Col, Form, Row } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import DividerElement from 'components/atoms/divider'
import FormDatePicker from 'components/molecules/form-item/FormDatePicker'
import FormInput from 'components/molecules/form-item/FormInput'
import FormSelect from 'components/molecules/form-item/FormSelect'
import { selectText } from 'data/messages/controls'
import { required } from 'helpers/Validators.helpers'
import { formatInputDate } from 'helpers/Dates.helpers'
import trans from 'helpers/i18n.helpers'
import { FieldData } from 'models/components'
import { SettingsFields } from 'models/project/projectDetails'
import moment, { Moment } from 'moment'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setExpectedCompletionDate, setGeneralSettingsFields } from 'redux/project/project-config/general'
import {
  selectCanEnd,
  selectExpectedCompletionDate,
  selectGeneralSettingsFields,
  selectInitialSettingsFetched,
} from 'redux/project/project-config/general/ProjectGeneralConfig.selectors'
import { setFormValidity } from 'redux/project/project-config/general/ProjectGeneralConfig.slice'
import { selectProjectDetails } from 'redux/project/project-details/ProjectDetails.selectors'

const activationOptions = [
  { label: <Trans id="project_config.general.form.activation.active">Active</Trans>, value: 0 },
  { label: <Trans id="project_config.general.form.activation.finished">Finished</Trans>, value: 1 },
]

const ProjectGeneralConfigForm: React.FC = () => {
  const dispatch = useDispatch()
  const [form] = useForm()
  const onExpectedCompletionDateChange = (val: Moment | null, dateStr: string) =>
    dispatch(setExpectedCompletionDate(dateStr))

  const handleChange = async (changedFields: FieldData<string | number>[], allFields: FieldData<string | number>[]) => {
    dispatch(setGeneralSettingsFields(allFields as SettingsFields))
  }
  const generalSettingsFields = useSelector(selectGeneralSettingsFields)
  const projectDetails = useSelector(selectProjectDetails)
  const expectedCompletionDate = useSelector(selectExpectedCompletionDate)
  const canEnd = useSelector(selectCanEnd)
  const initialSettingsFetched = useSelector(selectInitialSettingsFetched)

  useEffect(() => {
    if (initialSettingsFetched) {
      form
        .validateFields()
        .then(() => dispatch(setFormValidity(true)))
        .catch(() => dispatch(setFormValidity(false)))
    }
  }, [dispatch, initialSettingsFetched, form])
  return (
    <Form layout="vertical" form={form} fields={generalSettingsFields} onFieldsChange={handleChange}>
      <Row gutter={32}>
        <Col span={10}>
          <FormInput
            name="name"
            label={t({ id: 'project_config.general.form.label.name', message: 'Name' })}
            placeholder={t({ id: 'project_config.general.form.set_name_placeholder', message: 'Enter name' })}
            rules={[required()]}
          />
          <FormInput
            label={t({ id: 'project_config.general.form.label.creation_date', message: 'Creation date' })}
            placeholder={projectDetails && formatInputDate(projectDetails.creationDate)}
            disabled
          />
        </Col>
        <Col span={10}>
          <FormInput
            label={t({ id: 'project_config.general.form.label.creator', message: 'Creator' })}
            disabled
            placeholder={projectDetails?.creator.name}
          />
          <FormSelect
            disabled={!canEnd}
            options={activationOptions}
            name="isEnded"
            label={t({ id: 'project_config.general.form.label.status', message: 'Project status' })}
            placeholder={trans(selectText)}
          />
        </Col>
      </Row>
      <DividerElement size="m" />
      <Row gutter={32}>
        <Col span={10}>
          <FormDatePicker
            onChange={onExpectedCompletionDateChange}
            value={moment(expectedCompletionDate || undefined)}
            label={t({ id: 'project_config.general.form.label.completion_date', message: 'Expected completion date' })}
            rules={[required()]}
          />
          <FormInput
            name="sector"
            label={t({ id: 'project_config.general.form.label.sector', message: 'Sector' })}
            placeholder={t({ id: 'project_config.general.form.set_sector_placeholder', message: 'Enter sector' })}
            rules={[required()]}
          />
          <FormInput
            name="location"
            label={t({ id: 'project_config.general.form.label.location', message: 'Location' })}
            placeholder={t({ id: 'project_config.general.form.set_location_placeholder', message: 'Enter location' })}
            rules={[required()]}
          />
          <FormInput
            name="contractingAuthority"
            label={t({
              id: 'project_config.general.form.label.contracting_authority',
              message: 'Contracting authority',
            })}
            placeholder={t({
              id: 'project_config.general.form.set_authority_placeholder',
              message: 'Enter authority name',
            })}
            rules={[required()]}
          />
        </Col>
      </Row>
    </Form>
  )
}

export default ProjectGeneralConfigForm
