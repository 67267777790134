import {
  selectAdministationNamingSchemeModalIsOpen,
  selectAdministrationNamingSchemesMetadataFetchingLoading,
  selectAdministrationNamingSchemes,
  selectAdministrationNamingSchemesFetchingLoading,
  selectAdministrationNamingSchemesMetadata,
} from 'redux/administration/administration-naming-scheme/AdministrationNamingScheme.selectors'
import { useAppDispatch } from 'redux/store'
import { useSelector } from 'react-redux'
import { useEffect, useMemo } from 'react'
import {
  fetchNamingSchemes,
  fetchNamingSchemeMetadata,
} from 'redux/administration/administration-naming-scheme/AdministrationNamingScheme.slice'
import { Space, Spin } from 'antd'
import styled from 'styled-components'
import { fetchAllCodeSettings } from 'redux/administration/administration-code-settings/AdministrationCodeSettings.slice'
import Table from 'components/molecules/table'
import {
  selectAdministrationCodesSettingsDict,
  selectIsFetchingCodeLengthAnyLoading,
} from 'redux/administration/administration-code-settings/AdministrationCodeSettings.selectors'
import { getSchemeWithDetails } from 'redux/administration/administration-naming-scheme/AdministrationNamingScheme.helpers'
import useAdministrationNamingSchemeColumns from './table-columns'
import EmptyNamingSchemeTableContent from './EmptyNamingSchemeTableContent'
import NamingSchemeModal from './modals/NamingSchemeModal'

const StyledTable = styled(Table)`
  width: 1125px;
  &&&& {
    td {
      &:nth-child(3) {
        text-align: center;
      }
    }
  }
`

const AdministrationNamingScheme: React.FC = () => {
  const dispatch = useAppDispatch()
  const schemes = useSelector(selectAdministrationNamingSchemes)
  const fetchingSchemesLoading = useSelector(selectAdministrationNamingSchemesFetchingLoading)
  const fetchingMetadataLoading = useSelector(selectAdministrationNamingSchemesMetadataFetchingLoading)
  const fetchingCodeSettingsLoading = useSelector(selectIsFetchingCodeLengthAnyLoading)
  const isModalVisible = useSelector(selectAdministationNamingSchemeModalIsOpen)

  const attributes = useSelector(selectAdministrationNamingSchemesMetadata)
  const settings = useSelector(selectAdministrationCodesSettingsDict)

  const schemesWithDetails = useMemo(() => schemes.map((s) => getSchemeWithDetails(s, attributes, settings)), [
    schemes,
    attributes,
    settings,
  ])

  useEffect(() => {
    dispatch(fetchNamingSchemes())
    dispatch(fetchAllCodeSettings())
    dispatch(fetchNamingSchemeMetadata())
  }, [dispatch])
  const isLoading = fetchingSchemesLoading.isLoading || fetchingMetadataLoading.isLoading || fetchingCodeSettingsLoading
  const columns = useAdministrationNamingSchemeColumns()
  return (
    <Spin spinning={isLoading}>
      {!isLoading && (
        <Space direction="vertical" size="small">
          <StyledTable
            columns={columns}
            dataSource={schemesWithDetails}
            bordered
            pagination={false}
            rowKey="id"
            locale={{ emptyText: <EmptyNamingSchemeTableContent /> }}
            scroll={{ y: 'calc(100vh - 315px)' }}
          />
        </Space>
      )}
      {isModalVisible && <NamingSchemeModal />}
    </Spin>
  )
}

export default AdministrationNamingScheme
