import { Image } from 'antd'
import { useState } from 'react'
import { EllipsisOutlined, EnvironmentOutlined, UserOutlined, WarningTwoTone } from '@ant-design/icons'
import { LinearScale } from '@material-ui/icons'
import Text from 'components/atoms/text'
import Icon from 'components/atoms/icon'
import TooltipElement from 'components/molecules/tooltip-element'
import PortfolioProject from 'models/portfolio'
import { BACKEND_SERVER_URL } from 'environment/environment'
import portfolioDefaultImage from 'assets/portfolio-default.png'
import { t } from '@lingui/macro'
import {
  StyledLi,
  StyledLink,
  StyledDescription,
  StyledDescriptionBottomContainer,
  StyledDescriptionBottomItem,
} from './PortfolioItem.styles'

const buildImageUrl: (projectId: number, token?: string) => string | undefined = (projectId, token) =>
  token ? `${BACKEND_SERVER_URL}/projects/${projectId}/portfolio-image/?token=${token}` : portfolioDefaultImage

const PortfolioItem: React.FC<PortfolioProject> = ({
  id,
  name,
  location,
  sector,
  contractingAuthority,
  isActive,
  imageAccessToken,
}) => {
  const [hover, setHover] = useState(false)
  const setHoverHandler = (isHovering: boolean) => () => setHover(isHovering)

  const bottomItems: [string, JSX.Element, number][] = [
    [location, <EnvironmentOutlined />, 1],
    [contractingAuthority, <UserOutlined />, 2],
    [sector, <LinearScale />, 3],
  ]
  const projectUrl = isActive ? `/project/${id}` : `/project-config/${id}/1`
  return (
    <StyledLi $hover={hover}>
      <StyledLink onMouseEnter={setHoverHandler(true)} onMouseLeave={setHoverHandler(false)} to={projectUrl}>
        <Image
          src={buildImageUrl(id, imageAccessToken)}
          fallback={portfolioDefaultImage}
          width="100%"
          height="200px"
          object-fit="fill"
        />
      </StyledLink>
      <StyledDescription>
        <StyledLink onMouseEnter={setHoverHandler(true)} onMouseLeave={setHoverHandler(false)} to={projectUrl}>
          <Text textStyle="header-H2b" color="grayG07">
            {name}
          </Text>
        </StyledLink>
        {!isActive && (
          <TooltipElement
            placement="top"
            title={t({
              id: 'portfolio.project_config_not_finished_message',
              message: 'Project not yet fully configured',
            })}
          >
            <Icon>
              <WarningTwoTone twoToneColor="#FF0000" />
            </Icon>
          </TooltipElement>
        )}
        <Icon>
          <EllipsisOutlined />
        </Icon>
        <StyledDescriptionBottomContainer>
          {bottomItems.map(([text, icon, key]) => (
            <StyledDescriptionBottomItem key={key}>
              <Icon color="grayG04" size="default">
                {icon}
              </Icon>
              <Text color="grayG07" textStyle="regularText-small">
                {text}
              </Text>
            </StyledDescriptionBottomItem>
          ))}
        </StyledDescriptionBottomContainer>
      </StyledDescription>
    </StyledLi>
  )
}

export default PortfolioItem
