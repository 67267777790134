import { Input } from 'antd'
import { FormItemProps } from 'antd/lib/form'
import { InputProps } from 'antd/lib/input'
import BaseFormItem from './BaseFormItem'

const FormInputPassword: React.FC<FormItemProps & InputProps> = ({
  placeholder,
  disabled = false,
  onChange,
  value,
  label,
  name,
  rules,
  size,
  prefix,
  allowClear,
}) => (
  <BaseFormItem rules={rules} label={label} name={name}>
    <Input.Password
      size={size}
      prefix={prefix}
      allowClear={allowClear}
      value={value}
      onChange={onChange}
      placeholder={placeholder && placeholder.toString()}
      disabled={disabled}
    />
  </BaseFormItem>
)

export default FormInputPassword
