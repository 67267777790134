import { CloseOutlined } from '@ant-design/icons'
import { unwrapResult } from '@reduxjs/toolkit'
import { Modal, Spin, Form, Space, Checkbox } from 'antd'
import Icon from 'components/atoms/icon'
import Text from 'components/atoms/text'
import ButtonElement from 'components/molecules/button-element'
import FormInput from 'components/molecules/form-item/FormInput'
import FormSelect from 'components/molecules/form-item/FormSelect'
import cdeStatusDict from 'data/cdeStatus'
import { required, requiredAndNotBlank } from 'helpers/Validators.helpers'
import { SuitabilityCode } from 'models/administration'
import { CdeStatus, cdeStatuses } from 'models/files'
import { useSelector } from 'react-redux'
import { selectCreatingSuitabilityCodeLoading } from 'redux/administration/administration-suitability/AdministrationSuitability.selectors'
import {
  createSuitabilityCode,
  hideSuitabilityCodeModal,
} from 'redux/administration/administration-suitability/AdministrationSuitability.slice'
import { useAppDispatch } from 'redux/store'
import { addText, cancelText, selectText } from 'data/messages/controls'
import { t, Trans } from '@lingui/macro'
import { cdeDirectoryText } from 'data/messages/misc'
import trans from 'helpers/i18n.helpers'
import { selectAdministrationCodeLengthDict } from 'redux/administration/administration-code-settings/AdministrationCodeSettings.selectors'
import CodeFormInput from 'components/molecules/code-length/CodeFormInput'
import { useState } from 'react'
import BaseFormItem from 'components/molecules/form-item/BaseFormItem'
import AddingSuitabilityCodeModalAlert from './AddingSuitabilityCodeModalAlert'

const AddingSuitabilityCodeModal: React.FC = () => {
  const [form] = Form.useForm()
  const { isLoading } = useSelector(selectCreatingSuitabilityCodeLoading)
  const currentLengthDict = useSelector(selectAdministrationCodeLengthDict)
  const dispatch = useAppDispatch()

  const handleCancel = () => dispatch(hideSuitabilityCodeModal())
  const handleSubmit = async () => {
    try {
      await form.validateFields()
      const fields: Omit<SuitabilityCode, 'isCodeValid'> = form.getFieldsValue()
      await dispatch(createSuitabilityCode(fields)).then(unwrapResult)
      handleCancel()
      // eslint-disable-next-line no-empty
    } catch (err) {}
  }
  const [isDefaultCheckboxDisabled, setIsDefaultCheckboxDisabled] = useState(true)
  const handleDirectoryChange = (val: CdeStatus) => {
    setIsDefaultCheckboxDisabled(val !== 'IN_PROGRESS')
    if (val !== 'IN_PROGRESS') {
      form.setFields([{ name: 'isDefault', value: false }])
    }
  }
  return (
    <Modal
      visible
      onCancel={handleCancel}
      closeIcon={
        <Icon>
          <CloseOutlined />
        </Icon>
      }
      maskClosable={false}
      title={
        <Text textStyle="header-H2b">
          <Trans id="administration.suitability.modal.add_code_header">Create suitability code</Trans>
        </Text>
      }
      footer={
        <>
          <ButtonElement onClick={handleCancel} type="tertiary">
            {trans(cancelText)}
          </ButtonElement>
          <ButtonElement loading={false} onClick={handleSubmit}>
            {trans(addText)}
          </ButtonElement>
        </>
      }
    >
      <Spin spinning={isLoading}>
        <Space direction="vertical" style={{ width: '100%' }}>
          <Form form={form} labelCol={{ sm: { span: 9 } }} initialValues={{ isDefault: false }}>
            <FormInput
              label={<Trans id="administration.suitability.modal.name_field_label">Name</Trans>}
              name="name"
              placeholder={t({ id: 'administration.suitability.modal.name_field_placeholder', message: 'Enter name' })}
              rules={requiredAndNotBlank()}
            />
            <CodeFormInput
              codeLength={currentLengthDict.SUITABILITY_CODE_CODE}
              label={<Trans id="administration.suitability.modal.code_field_label">Code</Trans>}
            />
            <FormSelect
              label={trans(cdeDirectoryText)}
              name="status"
              placeholder={trans(selectText)}
              options={cdeStatuses.map((status) => ({ label: trans(cdeStatusDict[status].text), value: status }))}
              rules={[required()]}
              onChange={(val) => handleDirectoryChange(val as CdeStatus)}
            />
            <BaseFormItem
              name="isDefault"
              valuePropName="checked"
              label={<Trans id="administration.suitability.modal.default_checkbox_label">Default for new files</Trans>}
            >
              <Checkbox disabled={isDefaultCheckboxDisabled} />
            </BaseFormItem>
          </Form>
          <AddingSuitabilityCodeModalAlert />
        </Space>
      </Spin>
    </Modal>
  )
}

export default AddingSuitabilityCodeModal
