import { I18nProvider } from '@lingui/react'
import { i18n } from '@lingui/core'
import { ConfigProvider } from 'antd'
import plPL from 'antd/lib/locale/pl_PL'
import { messages as messagesPl } from 'locales/pl/messages'
import { pl, PluralCategory } from 'make-plural/plurals'
import App from './App'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function loadLocale(locale: string, messages: any, plurals: (n: number | string, ord?: boolean) => PluralCategory) {
  i18n.loadLocaleData(locale, { plurals })
  i18n.load(locale, messages)
}

loadLocale('pl', messagesPl, pl)
i18n.activate('pl')
localStorage.setItem('locale', 'pl') // TODO: handle properly (IN-642)

function LocalisedApp(): JSX.Element {
  return (
    <ConfigProvider locale={plPL}>
      <I18nProvider i18n={i18n}>
        <App />
      </I18nProvider>
    </ConfigProvider>
  )
}

export default LocalisedApp
