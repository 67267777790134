import { BackendLoading } from 'redux/redux.shared'
import { RootState } from 'redux/store'
import { Discipline } from './ProjectDisciplinesConfig.types'

export const selectDisciplines: (state: RootState) => Discipline[] = (state) =>
  state.project.config.disciplines.disciplines

export const selectSelectedDisciplines: (state: RootState) => Discipline[] = (state) =>
  state.project.config.disciplines.selectedDisciplines

export const selectAvailableDisciplines: (state: RootState) => Discipline[] = (state) => {
  const allDisciplines = selectDisciplines(state)
  const selectedDisciplines = selectSelectedDisciplines(state).map(({ id }) => id)
  return allDisciplines.filter(({ id }) => !selectedDisciplines.includes(id))
}

export const selectSelectedDisciplinesIds: (state: RootState) => number[] = (state) => {
  const selectedDisciplines = selectSelectedDisciplines(state)
  return selectedDisciplines.map(({ id }) => id)
}

export const selectIsAddingNewDiscipline: (state: RootState) => boolean = (state) =>
  state.project.config.disciplines.isAddingNewDiscipline

export const selectIsDisciplinesConfigValid: (state: RootState) => boolean = (state) => {
  const selectedDisciplines = selectSelectedDisciplines(state)
  return selectedDisciplines.length !== 0
}

// loadings

export const selectFetchDisciplinesLoading: (state: RootState) => BackendLoading = (state) =>
  state.project.config.disciplines.loading.fetchDisciplines
