import {
  DetailsItem,
  EntryKey,
  FileInfo,
  MetadataDetails,
  MetadataGroupData,
  SelectedGroup,
  TreeNode,
  FileSuitabilityCode,
} from 'models/files'
import projectEntryKeyDict from 'data/attributes'
import backendAxios from 'axios/axios'
import { BACKEND_SERVER_URL } from 'environment/environment'
import { Revision } from 'redux/files/files-documentation/FilesDocumentation.dto'
import { t } from '@lingui/macro'
import { conditionIfNotUndefined, identity } from './Functions.helpers'
import { openDocumentationNotification } from './Notifications.helpers'

export const metadataDetailsToAttributes = (metadataDetails: MetadataDetails[]): DetailsItem[] =>
  metadataDetails.map(({ metadata, value }) => ({
    name: metadata.name,
    value: value.value,
  }))

export const generateAttribute = (
  key: EntryKey,
  name: MetadataGroupData | undefined,
  value: string | null
): DetailsItem | null => {
  return value
    ? {
        name: projectEntryKeyDict(name)[key],
        value,
        key,
      }
    : null
}

export const findSelectedGroupByKey = (
  key: string | number,
  treeData: TreeNode[] | undefined
): SelectedGroup | undefined => {
  const selectedGroup =
    treeData &&
    treeData
      .flatMap((data) => (data.key === key ? data.selectedGroup : findSelectedGroupByKey(key, data.children)))
      .filter(identity)
      .map((data) => data as SelectedGroup)
  return selectedGroup ? selectedGroup[0] : undefined
}

export const ifNotEmpty = (value: string | number | undefined, item: Omit<DetailsItem, 'value'>): DetailsItem[] =>
  value ? [{ ...item, value }] : []

export const ifCharacteristicNotEmpty = (
  label: string,
  value: number | string | undefined
): { label: string; value: number | string }[] => (value ? [{ label, value }] : [])

export const downloadFile = (fileId: number, originalFilename = false): Promise<void> => {
  return backendAxios
    .get(`files/${fileId}/token_for_download/`)
    .then((res) => {
      const link = document.createElement('a')
      link.href = `${BACKEND_SERVER_URL}/files/${fileId}/?token=${res.data}&original_filename=${originalFilename}`
      link.click()
    })
    .catch(() => {
      openDocumentationNotification('error', {
        message: t({ id: 'project.files.file_download_error_message', message: 'File download failed.' }),
      })
    })
}

export const getDirectoriesKeysFromPath = (path: string): string[] => {
  const groupNames = path.split('/')
  return groupNames
    .map((name, idx) => {
      let key = ''
      for (let i = 0; i <= idx; i += 1) {
        key = `${key ? `${key}/` : ''}${groupNames[i]}`
      }
      return key
    })
    .filter((key) => key !== '')
}

export const preparePath = (pathname: string, path?: string | null): string =>
  `${pathname}?sideDrawer=files&content=documentation${path ? `&path=${path}` : ''}`

export const getPathToFile = (treeData: TreeNode[] | undefined, fileInfo?: FileInfo): string | undefined =>
  treeData &&
  treeData
    .flatMap((data) =>
      data.selectedGroup?.frontendFiles?.find(({ fileId, entryId }) => {
        return (
          conditionIfNotUndefined(fileInfo?.fileId === fileId, fileInfo?.fileId) ||
          conditionIfNotUndefined(fileInfo?.entryId === entryId, fileInfo?.entryId)
        )
      })
        ? (data.key as string)
        : getPathToFile(data.children, fileInfo)
    )
    .filter(identity)[0]

export const downloadFiles = (projectId: number, fileIds: number[]): Promise<void> => {
  return backendAxios
    .post(`projects/${projectId}/files/token_for_download/`, fileIds)
    .then((res) => {
      const link = document.createElement('a')
      link.href = `${BACKEND_SERVER_URL}/projects/${projectId}/files/?token=${res.data}`
      link.click()
    })
    .catch(() => {
      openDocumentationNotification('error', {
        message: t({ id: 'project.files.zip_download_error_message', message: 'Files download failed.' }),
      })
    })
}

const zeroPad = (num?: number) => String(num).padStart(2, '0')

export function formatRevision(revision: Revision | undefined): string | undefined {
  if (revision && revision.version.minor) {
    return `${revision.cdeFolderPrefix}${zeroPad(revision.version.major)}.${zeroPad(revision.version.minor)}`
  }
  return revision ? `${revision.cdeFolderPrefix}${zeroPad(revision.version.major)}` : undefined
}

export function mapStringToRevision(version: string): Revision {
  // expected format `{cdeFolderPrefix}{major}.{minor}`
  const versionNumber = version.slice(1).split('.')
  return {
    cdeFolderPrefix: version.charAt(0),
    version: {
      major: Number(versionNumber[0]),
      minor: versionNumber.length > 1 ? Number(versionNumber[1]) : undefined,
    },
  }
}

export function formatSuitabilityCode(code: FileSuitabilityCode | null): string | undefined {
  if (!code) return undefined
  return `${code.code} - ${code.name}`
}
