import { ColumnsType } from 'antd/lib/table'
import SuitabilityCodeStatusDisplay from 'components/atoms/administration/suitability-status-display'
import WithValidityInfo from 'components/atoms/with-validity-info'
import { useSelector } from 'react-redux'
import { selectAdministrationCodeLengthDict } from 'redux/administration/administration-code-settings/AdministrationCodeSettings.selectors'
import { SuitabilityCodeWithDetails } from 'models/administration'
import useTableColumnFiltering, { onFilterStringColumn } from 'components/molecules/table/useTableColumnFiltering'
import { cdeDirectoryText } from 'data/messages/misc'
import trans from 'helpers/i18n.helpers'
import { t, Trans } from '@lingui/macro'
import CodeColumnTableHeader from 'components/molecules/table/CodeColumnTableHeader'
import { cdeStatusFilters } from 'helpers/Models.helpers'
import WithDefaultMarker from 'components/atoms/with-default-marker'

const useAdministrationSuitabilityColumns = (): ColumnsType<SuitabilityCodeWithDetails> => {
  const codeLengthDict = useSelector(selectAdministrationCodeLengthDict)
  return [
    {
      title: <Trans id="administration.suitability.table.column_name_label">Name</Trans>,
      dataIndex: 'name',
      key: 'name',
      width: 500,
      render: (value, { name, isDefault }) => (
        <WithDefaultMarker
          isDefault={!!isDefault}
          tooltipText={t({ id: 'administration.suitability.default_code_tooltip', message: 'Default code' })}
        >
          {name}
        </WithDefaultMarker>
      ),
      ...useTableColumnFiltering(
        t({ id: 'administration.suitability.table.search_name_placeholder', message: 'Search for name' })
      ),
      onFilter: (value: string | number | boolean, record: SuitabilityCodeWithDetails) =>
        onFilterStringColumn(value, record.name),
    },
    {
      title: (
        <CodeColumnTableHeader>
          <Trans id="administration.suitability.table.column_code_label">Code</Trans>
        </CodeColumnTableHeader>
      ),
      dataIndex: 'code',
      key: 'code',
      width: 100,
      render: (value, { code }) => (
        <WithValidityInfo
          isValid={code.code.length === codeLengthDict.SUITABILITY_CODE_CODE}
          invalidMessage={t({ id: 'administration.suitability.table.invalid_code_error', message: 'Invalid code' })}
        >
          {code.code}
        </WithValidityInfo>
      ),
      ...useTableColumnFiltering(
        t({ id: 'administration.suitability.table.search_code_placeholder', message: 'Search for code' })
      ),
      onFilter: (value: string | number | boolean, record: SuitabilityCodeWithDetails) =>
        onFilterStringColumn(value, record.code.code),
    },
    {
      title: trans(cdeDirectoryText),
      dataIndex: 'status',
      key: 'status',
      width: 150,
      render: (value, { status }) => <SuitabilityCodeStatusDisplay status={status} />,
      filters: cdeStatusFilters(),
      onFilter: (value, record) => record.status === value,
    },
  ]
}

export default useAdministrationSuitabilityColumns
