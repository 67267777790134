import { ColumnsType } from 'antd/lib/table'
import useTableColumnFiltering, { onFilterStringColumn } from 'components/molecules/table/useTableColumnFiltering'
import WithValidityInfo from 'components/atoms/with-validity-info'
import { Discipline } from 'models/administration'
import { useSelector } from 'react-redux'
import { selectAdministrationCodeLengthDict } from 'redux/administration/administration-code-settings/AdministrationCodeSettings.selectors'
import { actionsTableColumnText } from 'data/messages/controls'
import { t, Trans } from '@lingui/macro'
import trans from 'helpers/i18n.helpers'
import CodeColumnTableHeader from 'components/molecules/table/CodeColumnTableHeader'
import DisciplineActions from './DisciplineActions'

const useAdministrationDisciplinesColumns = (): ColumnsType<Discipline> => {
  const codeLengthDict = useSelector(selectAdministrationCodeLengthDict)
  return [
    {
      title: t({ id: 'administration.disciplines.table.column_name_header', message: 'Name' }),
      dataIndex: 'discipline',
      key: 'discipline',
      width: 500,
      render: (value, { name }) => name,
      ...useTableColumnFiltering(
        t({ id: 'administration.disciplines.table.search_name_placeholder', message: 'Search for name' })
      ),
      onFilter: (value: string | number | boolean, record: Discipline) => onFilterStringColumn(value, record.name),
    },
    {
      title: (
        <CodeColumnTableHeader>
          <Trans id="administration.disciplines.table.column_code_header">Code</Trans>
        </CodeColumnTableHeader>
      ),
      dataIndex: 'code',
      key: 'code',
      width: 100,
      render: (value, { code }) => (
        <WithValidityInfo
          isValid={code.code.length === codeLengthDict.DISCIPLINE_CODE}
          invalidMessage={t({ id: 'administration.disciplines.table.invalid_code_error', message: 'Invalid code' })}
        >
          {code.code}
        </WithValidityInfo>
      ),
      ...useTableColumnFiltering(
        t({ id: 'administration.disciplines.table.search_code_placeholder', message: 'Search for code' })
      ),
      onFilter: (value: string | number | boolean, record: Discipline) => onFilterStringColumn(value, record.code.code),
    },
    {
      title: trans(actionsTableColumnText),
      dataIndex: 'actions',
      key: 'actions',
      width: 100,
      render: (value, { id, isUsed, name }) => <DisciplineActions id={id} canDelete={!isUsed} name={name} />,
    },
  ]
}

export default useAdministrationDisciplinesColumns
